import React from "react";
import { Profile } from "../../../types/data";
import Card from "../../../shared/components/Card/Card";
import Box from "@material-ui/core/Box";

export interface FlightStreamProps {
  flightCreatorProfile: Profile;
}

export default function FlightStream({
  flightCreatorProfile,
}: FlightStreamProps) {
  const [rerenderKey, setRerenderKey] = React.useState(0);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setRerenderKey((prev) => prev + 1);
    });
  }, []);

  let iframe: any;
  if (flightCreatorProfile.mixer) {
    iframe = (
      <iframe
        key={rerenderKey}
        title={`${flightCreatorProfile.displayName}'s player frame`}
        i18n-title="channel#ShareDialog:playerEmbedFrame|Embed player Frame copied from share dialog"
        // @ts-ignore
        allowfullscreen="true"
        src={`https://mixer.com/embed/player/${flightCreatorProfile.mixer}?disableLowLatency=1`}
        width="100%"
        height="100%"
      >
        {" "}
      </iframe>
    );
  }

  if (flightCreatorProfile.twitch) {
    iframe = (
      <iframe
        key={rerenderKey}
        title={`${flightCreatorProfile.displayName}'s player`}
        src={`https://player.twitch.tv/?channel=${flightCreatorProfile.twitch}&parent=${window.location.hostname}`}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    );
  }

  if (!iframe) return null;

  return (
    <Card variant="yellow">
      <Box
        mb={2}
        textAlign="center"
        fontWeight="fontWeightBold"
        fontSize={16}
        color="text.primary"
      >
        Live Stream
      </Box>
      <Box
        style={{
          width: "100%",
          height: 0,
          paddingBottom: "56.25%",
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          {iframe}
        </Box>
      </Box>
      <Box mt={2} width="100%" fontSize={10} textAlign="left">
        * This flight is hosted by one of our partnered streamer.
      </Box>
      <Box width="100%" fontSize={10} textAlign="left">
        * Please contact us if there is any inapproporiate content in the live
        stream.
      </Box>
    </Card>
  );
}
