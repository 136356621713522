import React from "react";
import firebase from "../shared/firebase";

export function useLongId(shortId: string | undefined) {
  const [state, setState] = React.useState<string | null | undefined>(
    undefined,
  );
  React.useEffect(() => {
    if (!shortId) return;

    firebase
      .firestore()
      .collection("users")
      .where("id", "==", shortId)
      .onSnapshot((userSnapshot) => {
        if (userSnapshot.empty) {
          setState(null);
          return;
        }

        const firstMatch = userSnapshot.docs[0];

        setState(firstMatch.id);
      });
  }, [shortId]);

  return state;
}
