import React from "react";

import { Passenger, QueueType } from "../../../types/all";

export interface FlightQueues {
  standard: Passenger[] | undefined;
  firstClass: Passenger[] | undefined;
}

export function useQueues(queue: Passenger[] | undefined) {
  return React.useMemo<FlightQueues>(() => {
    if (queue === undefined) {
      return {
        standard: undefined,
        firstClass: undefined,
      };
    }

    const standard: Passenger[] = [];
    const firstClass: Passenger[] = [];

    queue.forEach((passenger) => {
      if (passenger.priority === QueueType.STANDARD) {
        standard.push(passenger);
        return;
      }

      if (passenger.priority === QueueType.FIRST_CLASS) {
        firstClass.push(passenger);
        return;
      }
    });

    return {
      standard,
      firstClass,
    };
  }, [queue]);
}
