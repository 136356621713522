import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";

export interface FAQProps {
  open: boolean;
  close: () => void;
}

export default function FAQ({ open, close }: FAQProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">FAQ</DialogTitle>
      <DialogContent>
        <Box component="h4">Q: What is this?</Box>
        <Box component="p">
          A: This is the Airport where you can find out open Flights in
          different Terminals (Turnips, Special Visitors, Flea Market, General).
          You can join a Flight hosted by a kind islander and do Turnips
          Selling/Buying, Item Exchanging, and more!
        </Box>
        <Box component="h4">Q: How do I get on an island?</Box>
        <Box component="p">
          A: Please select the flight you are interested in, click "Join Queue"
          and wait until your turn. Use the Dodo code to visit the island when
          it's shown.
        </Box>
        <Box component="h4">Q: How long do I need to wait?</Box>
        <Box component="p">
          A: Depending on how long the queue is. You can have a rough estimation
          by looking at the joined time of other visitors in the queue.
        </Box>
        <Box component="h4">
          Q: Why does it take so long? Why is the queue not moving?
        </Box>
        <Box component="p">
          A: As we know, the animation for visiting and leaving an island takes
          some time. And visitors on the island will have to do their business
          one by one with Timmy or Daisy. So it might sometimes take more time.
        </Box>
        <Box component="h4">
          Q: "Wuh-oh looks like we're getting interference..."
        </Box>
        <Box component="p">
          A: It's totally normal. Keep trying reconnecting! It is just other
          visitors are joining or leaving or having conversations with Timmy and
          others.
        </Box>
        <Box component="h4">Q: Can I talk to the Hosts?</Box>
        <Box component="p">
          A: Yes, you can. There is a Chat box at the bottom of every flight.
          Hosts will be highlighted in Blue color with a "(Host)" suffix to
          their names.
        </Box>
        <Box component="h4">Q: Tell me about Verification.</Box>
        <Box component="p">
          A: To prevent scams and to build a better community where everyone is
          proactive in helping and cooperating with each other, we encourage you
          to verify your island by sending a screenshot of your in-game passport
          to (1) @TurnipsExchange on Twitter, or (2) hi@turnips.exchange via
          email. You'll get a Verified Badge and other islanders and Hosts will
          trust you more when you are interacting with them!
        </Box>
        <Box component="h4">Q: What is the "End Time" I see on flights?</Box>
        <Box component="p">
          A: It is the scheduled end time for the flight. Every flight has an
          end because prices can only hold for a few hours and most importantly
          every host have their Real-Life-Business to attend to. The system will
          automatically end the flight at the time and remove all queuing
          visitors. Please be aware of the end time before joining a queue.
        </Box>
        <Box component="h4">Q: "The flight is ended"</Box>
        <Box component="p">
          A: Sorry for the inconvenience! Every host is doing their best to help
          as many as they can, but the hosts could only stay for so long as they
          have other business to attend to. Please check{" "}
          <Link to="/airport">Airport</Link> to find out live flights!
        </Box>
        <Box component="h4">Q: SCAM!!!</Box>
        <Box component="p">
          A: We have zero tolerance to Scams. Please report any suspicious
          Flights on the flight page by clicking the Report button on top right
          of the info section. Our admins will handle the reports ASAP.
        </Box>
        <Box component="h4">Q: Why am I banned?</Box>
        <Box component="p">
          A: We reserve the right to ban any account for any reason we believe
          valid. General reasons being: (1) you've been part of a SCAM; (2)
          you've been publishing fake informations; (3) you've used
          inapproporiate languages against other visitors in Chat.
        </Box>
        <Box component="h4">Q: I have some feedback!</Box>
        <Box component="p">
          A: Thank you so much for trusting Turnips Exchange! All feedbacks are
          welcome and appreciated! Please DM me on Twitter{" "}
          <a
            href="https://twitter.com/TurnipsExchange"
            target="_blank"
            rel="noopener noreferrer"
          >
            @TurnipsExchange
          </a>
          , I'll come back to you as soon as possible!
        </Box>
        <Box component="h4">Q: I love the site!!!</Box>
        <Box component="p">
          A: We are so glad to hear that! We surely will keep doing our best to
          make Turnips Exchange the most trusting community for you to enjoy the
          game! If you are interested, please also consider support us on{" "}
          <a
            href="http://patreon.com/turnips"
            target="_blank"
            rel="noopener noreferrer"
          >
            Patreon
          </a>
          .
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" autoFocus>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
