import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useTranslation } from "react-i18next";
import {
  CatalogItem,
  CatalogItemDatabaseState,
  CatalogItemDatabaseExtended,
} from "../../../types/data";
import { CatalogApi } from "../../../api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  selected: {
    borderRadius: 8,
    backgroundColor: "rgba(107, 92, 67, 0.2)",
  },
}));

export enum CatalogGridListMode {
  View,
  Add,
  Remove,
}

export interface GridListProps {
  uid?: string;
  data: CatalogItem[];
  catalog?: CatalogItemDatabaseExtended[];
  showName?: boolean;
  mode?: CatalogGridListMode;
}

export default function GridList({
  uid,
  data,
  catalog,
  showName = false,
  mode = CatalogGridListMode.View,
}: GridListProps) {
  const { t } = useTranslation("CatalogGridList");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string | undefined>(undefined);
  const [submitting, setSubmitting] = React.useState(false);
  const onClickTile = React.useCallback(
    (id: string) => {
      setSelected((prev) => (prev === id ? undefined : id));
    },
    [setSelected],
  );
  const foundInCatalog = catalog
    ? catalog.find((item) => item.id === selected)
    : null;
  const isSaved = !!foundInCatalog;
  const isCatalogued =
    foundInCatalog?.state === CatalogItemDatabaseState.CATALOGUED;
  const isWishListed =
    foundInCatalog?.state === CatalogItemDatabaseState.WISH_LISTED;

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
        flex={1}
        maxHeight={300}
        width="100%"
        style={{ overflowX: "auto" }}
      >
        {data.map((tile) => (
          <Box
            key={tile.id}
            p={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={
              mode !== CatalogGridListMode.View
                ? () => onClickTile(tile.id)
                : undefined
            }
            style={
              mode !== CatalogGridListMode.View
                ? { cursor: "pointer" }
                : undefined
            }
            className={tile.id === selected ? classes.selected : undefined}
          >
            <img
              src={tile.imgUrl}
              alt={tile.name}
              style={{ objectFit: "contain", width: 48, height: 48 }}
            />
            <Box
              color="text.primary"
              maxWidth={48}
              fontSize={10}
              style={{ overflowY: "auto", whiteSpace: "nowrap" }}
            >
              {tile.name}
            </Box>
          </Box>
        ))}
      </Box>
      {mode === CatalogGridListMode.Add && data.length !== 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          width="100%"
        >
          <Box mr={2}>{t(isSaved ? "ctaSaved" : "ctaSave")}</Box>
          <ButtonGroup variant="contained" color="primary">
            <MuiButton
              disabled={selected === undefined || isCatalogued || submitting}
              onClick={() => {
                if (!selected || !uid) return;
                setSubmitting(true);
                CatalogApi.setCatalogued(uid, selected)
                  .then(() => {
                    enqueueSnackbar(t("success.general", { ns: "shared" }), {
                      variant: "success",
                    });
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    enqueueSnackbar(
                      t("error.general", {
                        message: error.message,
                        ns: "shared",
                      }),
                      {
                        variant: "error",
                      },
                    );
                  });
              }}
              style={{ fontSize: 12 }}
            >
              {t("catalog")}
            </MuiButton>
            <MuiButton
              disabled={selected === undefined || isWishListed || submitting}
              onClick={() => {
                if (!selected || !uid) return;
                setSubmitting(true);
                CatalogApi.setWishListed(uid, selected)
                  .then(() => {
                    enqueueSnackbar(t("success.general", { ns: "shared" }), {
                      variant: "success",
                    });
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    enqueueSnackbar(
                      t("error.general", {
                        message: error.message,
                        ns: "shared",
                      }),
                      {
                        variant: "error",
                      },
                    );
                  });
              }}
              style={{ fontSize: 12 }}
            >
              {t("wishList")}
            </MuiButton>
          </ButtonGroup>
        </Box>
      )}
      {mode === CatalogGridListMode.Remove && data.length !== 0 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          width="100%"
        >
          <MuiButton
            variant="contained"
            color="primary"
            disabled={selected === undefined || isCatalogued || submitting}
            onClick={() => {
              if (!selected || !uid) return;
              setSubmitting(true);
              CatalogApi.remove(uid, selected)
                .then(() => {
                  enqueueSnackbar(t("success.general", { ns: "shared" }), {
                    variant: "success",
                  });
                  setSubmitting(false);
                })
                .catch((error) => {
                  setSubmitting(false);
                  enqueueSnackbar(
                    t("error.general", {
                      message: error.message,
                      ns: "shared",
                    }),
                    {
                      variant: "error",
                    },
                  );
                });
            }}
            style={{ fontSize: 12 }}
          >
            {t("remove")}
          </MuiButton>
        </Box>
      )}
    </React.Fragment>
  );
}
