import { makeStyles } from "@material-ui/core";
import { CircularProgressProps } from "./CircularProgress.types";

const COLOR: { [k in CircularProgressProps["variant"]]: string } = {
  blue: "#2d6895",
  cyan: "rgba(0, 125, 117, 1)",
  mint: "#007d75",
  yellow: "#f1e26f",
};

export const useStyles = makeStyles((theme) => ({
  colorPrimary: ({ variant }: CircularProgressProps) => {
    return {
      color: COLOR[variant],
    };
  },
}));
