import React from "react";
import Box from "@material-ui/core/Box";
import CatalogItemComponent from "./CatalogItem";
import { CatalogItem as CatalogItemType } from "../../../types/data";

export interface HorizontalListProps {
  data: CatalogItemType[];
  showName?: boolean;
}

export default function HorizontalList({
  data,
  showName = false,
}: HorizontalListProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      flex={1}
      style={{ overflowY: "auto" }}
    >
      {data.map((item) => (
        <CatalogItemComponent key={item.id} data={item} showName={showName} />
      ))}
    </Box>
  );
}
