import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "./CircularProgress/CircularProgress";

export default function Loader() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      my={3}
    >
      <CircularProgress variant="blue" />
    </Box>
  );
}
