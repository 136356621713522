import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as dateFns from "date-fns/fp";

import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";

import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import Section from "../../shared/components/Section/Section";
import Select from "../../shared/components/Select/Select";
import TextField from "../../shared/components/TextField/TextField";
import DateTimePicker from "../../shared/components/DateTimePicker/DateTimePicker";
import {
  TERMINALS,
  SPECIAL_CHARACTERS_TO_CHOOSE,
} from "../../shared/constants";
import SpecialCharacterLabel from "../../shared/components/SpecialCharacterLabel";
import {
  FlightForm,
  FlightFormError,
  FlightFormHelptext,
  FlightFormUpdateField,
  FlightFormSubmit,
  FlightFormField,
} from "./useFlightForm";
import {
  Terminal,
  TurnipsFlightType,
  Region,
  FlightPassengerType,
  RequirePasswordOption,
} from "../../types/all";
import { ProfileForm } from "../../types/data";
import { getDateOrUndefined } from "../../shared/utils";
import HostRules from "../Flight/components/HostRulesDialog";
import { useDialog } from "../../hooks/useDialog";

export interface FlightUpdateForm {
  profileVerified: boolean;
  profile: ProfileForm;
  onChangeDisplayName: (e: any) => void;
  onChangeIslandName: (e: any) => void;
  onChangeRegion: (e: any) => void;
  profileError: {
    displayName?: string;
    islandName?: string;
  };

  form: FlightForm;
  error: FlightFormError;
  helptext: FlightFormHelptext;
  updateField: FlightFormUpdateField;
  submit: FlightFormSubmit;
  submitting: boolean;
  submitDisabled: boolean;
  isFlightCreated: boolean;
  isFlightOpen: boolean;
}

function getCurrentMinEndTime(currentPlanToCloseAt?: Date) {
  return new Date();
}

function getCurrentMaxEndTime(min: Date) {
  return dateFns.subSeconds(1)(dateFns.addHours(12)(new Date()));
}

function getTKey(sec: string, key: string, isFlightCreated: boolean) {
  return `${sec}.${isFlightCreated ? "update" : "create"}.${key}`;
}

function pageTKey(key: string, isFlightCreated: boolean) {
  return getTKey("page", key, isFlightCreated);
}

function sec1TKey(key: string, isFlightCreated: boolean) {
  return getTKey("sec1", key, isFlightCreated);
}

function sec2TKey(key: string, isFlightCreated: boolean) {
  return getTKey("sec2", key, isFlightCreated);
}

export default function FlightUpdateForm({
  profileVerified,

  profile,
  onChangeDisplayName,
  onChangeIslandName,
  onChangeRegion,
  profileError,

  form,
  error,
  helptext,
  updateField,
  submit,
  submitting,
  submitDisabled,
  isFlightCreated,
  isFlightOpen,
}: FlightUpdateForm) {
  const { t } = useTranslation("FlightUpdateForm");
  const planToCloseAtDate = getDateOrUndefined(form.PlanToCloseAt);
  const minDate = getCurrentMinEndTime(planToCloseAtDate);
  const maxDate = getCurrentMaxEndTime(minDate);
  const hostRuleDialog = useDialog();
  const [showFullLetter, setShowFullLetter] = React.useState(false);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t(pageTKey("title", isFlightCreated))}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t(sec1TKey("title", isFlightCreated))}
        </Box>
      </Section>
      <Box my={2} px={4}>
        <Card variant="yellow">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="Logo of Link - The ACNH Community"
              src="https://acnh.link/apple-icon-180x180.png"
              style={{
                width: 60,
                height: 60,
                borderRadius: 8,
                marginRight: 8,
                alignSelf: "flex-start",
              }}
            />
          </Box>
          <Box component="h3" style={{ marginBottom: 8 }}>
            Link - The ACNH Community
          </Box>
          <Box style={{ fontSize: "0.8em" }}>
            Available on <strong>App Store</strong> {"& "}
            <strong>Google Play</strong> {"& "}
            <strong>Web</strong>
          </Box>
          <Box
            component="p"
            textAlign="left"
            fontWeight="fontWeightBold"
            width="100%"
            style={{ marginBottom: 0 }}
          >
            Dear {profile.displayName || "Host"},
          </Box>
          <Box
            component="p"
            textAlign="left"
            fontWeight="fontWeightMedium"
            style={{
              maxHeight: showFullLetter ? undefined : 80,
              overflowY: showFullLetter ? "visible" : "auto",
            }}
          >
            We would like to invite you to join our Early Release Programme and
            start hosting your island with the Link App.
            <br />
            <br />
            The Link App enables Hosts to add more detailed flight information
            and helps visitors to get on board on time with real-time
            notification. It has all the best functionalities here on TE but
            with even better experience.
            <br />
            <br />
            We've designed this Early Release Programme for you to try and
            feedback and help us improve. Here is how it works:
            <br />
            <br />
            1. Create a Flight with Link App.
            <br />
            2. Tell it to your followers or everyone in <a href="https://discord.gg/hy29EBb" target="_blank" rel="noopener noreferrer">TE Discord
            Server</a>.
            <br />
            3. Feedback directly to us if you or your visitors experienced any
            issue or have any suggestions.
            <br />
            <br />
            We will be rewarding Hosts with prizes such as Most Visitors, Best
            Communication, and more. Also, upon every app update, if your
            feedback has been accepted and shipped in the update, we have
            another special gift for you!
            <br />
            <br />
            There will also be a <strong>Mega Hosting Event</strong> soon in which we will
            sponsor you with <em>real-life gifts</em> and <em>feature your island</em>. If you are
            interested in being one of the featured Hosts in the Mega Hosting
            Event, please tell us your hosting plan by emailing to hi@acnh.link,
            we will reach back to you!
            <br />
            <br />
            With your help, we will be able to finish the public testing of the Link App and upgrade Turnips Exchange as soon as possible.
            <br />
            <br />
            Thanks again for the time and effort you've spent on Turnips
            Exchange to help others. You've added so much more fun to the game!
            Let's work together and make it even better 😎😎😎
            <br />
            <br />
            <br />
            Yours,
            <br />
            Turnips Exchange Team
          </Box>
          <MuiButton
            variant="text"
            size="small"
            startIcon={
              <Icon
                className={
                  showFullLetter ? "fas fa-chevron-up" : "fas fa-chevron-down"
                }
              />
            }
            style={{ marginBottom: 8 }}
            onClick={() => setShowFullLetter(!showFullLetter)}
          >
            {showFullLetter ? "Fold letter" : "Read full letter"}
          </MuiButton>
          <Button
            variant="brown"
            icon={
              <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
            }
            onClick={() => {
              window.open("https://acnh.link/airport", "_blank");
            }}
          >
            Sure, I'm IN!!!
          </Button>
        </Card>
      </Box>
      <Section variant="blue">
        <Card variant="blue">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box style={{ color: "rgba(104,161,215,0.6)" }}>
              <Icon className="fas fa-plane" fontSize="large" />
            </Box>
            <Box component="h2" color="info.main">
              {t(sec2TKey("title", isFlightCreated))}
            </Box>
            <Box textAlign="center">
              {t(sec2TKey("desc1", isFlightCreated))}
              <br />
              {t(sec2TKey("desc2", isFlightCreated))}
            </Box>
          </Box>
          <Box
            mt={4}
            color="info.main"
            component="h3"
            textAlign="left"
            style={{ width: "100%", maxWidth: 300 }}
          >
            What's this flight for:
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Select
              color="blue"
              // @ts-ignore
              variant="outlined"
              style={{ width: "100%", maxWidth: 300 }}
              label={t("terminalLabel", { ns: "shared" })}
              placeholder={t("placeholder.terminal")}
              defaultValue={form.Terminal}
              value={form.Terminal}
              onChange={(e: any) => {
                updateField(FlightFormField.Terminal, e.target.value, form);
              }}
              error={!!error[FlightFormField.Terminal]}
              helperText={helptext[FlightFormField.Terminal]}
            >
              {Object.values(Terminal).map((val) => (
                <MenuItem value={val} key={val}>
                  {t(TERMINALS[val].displayName, { ns: "shared" })}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {form.Terminal === Terminal.TURNIPS && (
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Select
                color="blue"
                // @ts-ignore
                variant="outlined"
                style={{ width: "100%", maxWidth: 300 }}
                label={t("turnipsFlightTypeLabel", { ns: "shared" })}
                defaultValue={form.TurnipsFlightType}
                value={form.TurnipsFlightType}
                onChange={(e: any) => {
                  updateField(
                    FlightFormField.TurnipsFlightType,
                    e.target.value,
                    form,
                  );
                }}
                error={!!error[FlightFormField.TurnipsFlightType]}
                helperText={helptext[FlightFormField.TurnipsFlightType]}
              >
                <MenuItem
                  value={TurnipsFlightType.SELLING}
                  key={TurnipsFlightType.SELLING}
                >
                  {t("turnipsFlightTypeSelling", { ns: "shared" })}
                </MenuItem>
                <MenuItem
                  value={TurnipsFlightType.BUYING}
                  key={TurnipsFlightType.BUYING}
                >
                  {t("turnipsFlightTypeBuying", { ns: "shared" })}
                </MenuItem>
              </Select>
            </Box>
          )}
          {form.Terminal === Terminal.TURNIPS && (
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <TextField
                color="blue"
                // @ts-ignore
                variant="outlined"
                label={t("turnipPriceLabel", { ns: "shared" })}
                placeholder={t("placeholder.price")}
                style={{ width: "100%", maxWidth: 300 }}
                type="number"
                defaultValue={form.Price}
                value={form.Price}
                onChange={(e: any) => {
                  updateField(FlightFormField.Price, e.target.value, form);
                }}
                InputLabelProps={{ shrink: true }}
                error={!!error[FlightFormField.Price]}
                helperText={helptext[FlightFormField.Price]}
              />
            </Box>
          )}
          {form.Terminal === Terminal.SPECIAL_VISITORS && (
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Select
                color="blue"
                // @ts-ignore
                variant="outlined"
                style={{ width: "100%", maxWidth: 300 }}
                label={t("specialCharacterLabel", { ns: "shared" })}
                defaultValue={form.SpecialCharacter}
                value={form.SpecialCharacter}
                onChange={(e: any) => {
                  updateField(
                    FlightFormField.SpecialCharacter,
                    e.target.value,
                    form,
                  );
                }}
                error={!!error[FlightFormField.SpecialCharacter]}
                helperText={helptext[FlightFormField.SpecialCharacter]}
              >
                {SPECIAL_CHARACTERS_TO_CHOOSE.map((val) => (
                  <MenuItem value={val} key={val}>
                    <SpecialCharacterLabel id={val} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {form.Terminal === Terminal.FLEA_MARKET && (
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Box
                component={Link}
                // @ts-ignore
                to="/catalog"
                color="info.main"
                target="external"
              >
                {t("goToCatalogPage")}
              </Box>
            </Box>
          )}
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="blue"
              // @ts-ignore
              variant="outlined"
              multiline
              rows={3}
              rowsMax={5}
              label={t("messageLabel", { ns: "shared" })}
              placeholder={t("placeholder.message")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={form.Message}
              value={form.Message}
              onChange={(e: any) => {
                updateField(FlightFormField.Message, e.target.value, form);
              }}
              InputLabelProps={{ shrink: true }}
              error={!!error[FlightFormField.Message]}
              helperText={helptext[FlightFormField.Message]}
            />
          </Box>
          <Box
            mt={4}
            color="info.main"
            component="h3"
            textAlign="left"
            style={{ width: "100%", maxWidth: 300 }}
          >
            Some more details:
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="blue"
              // @ts-ignore
              variant="outlined"
              label={t("islandNameLabel", { ns: "shared" })}
              placeholder={t("islandNamePlaceholder", { ns: "shared" })}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={profile.islandName}
              onChange={onChangeIslandName}
              InputLabelProps={{ shrink: true }}
              disabled={profileVerified}
              error={!!profileError.islandName}
              helperText={
                profileError.islandName ||
                (profileVerified
                  ? t("sec2.islandNameVerified", { ns: "PassportForm" })
                  : undefined)
              }
            />
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="blue"
              // @ts-ignore
              variant="outlined"
              label={t("hostNameLabel", { ns: "shared" })}
              placeholder={t("hostNamePlaceholder", { ns: "shared" })}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={profile.displayName}
              onChange={onChangeDisplayName}
              InputLabelProps={{ shrink: true }}
              disabled={profileVerified}
              error={!!profileError.displayName}
              helperText={
                profileError.displayName ||
                (profileVerified
                  ? t("sec2.displayNameVerified", { ns: "PassportForm" })
                  : undefined)
              }
            />
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Select
              color="blue"
              // @ts-ignore
              variant="outlined"
              style={{ width: "100%", maxWidth: 300 }}
              label={t("regionLabel", { ns: "shared" })}
              placeholder={t("regionPlaceholder", { ns: "shared" })}
              defaultValue={profile.region}
              onChange={onChangeRegion}
            >
              {Object.values(Region).map((value) => (
                <MenuItem value={value} key={value}>
                  {t(value, { ns: "shared" })}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Select
              color="blue"
              // @ts-ignore
              variant="outlined"
              style={{ width: "100%", maxWidth: 300 }}
              label={t("maxAllowedLabel", { ns: "shared" })}
              defaultValue={form.MaxAllowed}
              value={form.MaxAllowed}
              onChange={(e: any) => {
                updateField(FlightFormField.MaxAllowed, e.target.value, form);
              }}
              error={!!error[FlightFormField.MaxAllowed]}
              helperText={helptext[FlightFormField.MaxAllowed]}
            >
              {[1, 2, 3, 4].map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <DateTimePicker
              color="blue"
              // @ts-ignore
              label={t("planToCloseAtLabel", { ns: "shared" })}
              placeholder={t("planToCloseAtPlaceholder", { ns: "shared" })}
              defaultValue={planToCloseAtDate}
              value={planToCloseAtDate}
              onChange={(newDate: Date) => {
                updateField(
                  FlightFormField.PlanToCloseAt,
                  newDate.toUTCString(),
                  form,
                );
              }}
              error={!!error[FlightFormField.PlanToCloseAt]}
              helperText={helptext[FlightFormField.PlanToCloseAt]}
              format="yyyy-MM-dd HH:mm"
              InputProps={{
                disableUnderline: true,
              }}
              style={{ width: "100%", maxWidth: 300 }}
              inputVariant="outlined"
              minDate={minDate}
              maxDate={maxDate}
              minutesStep={5}
              strictCompareDates={true}
              allowKeyboardControl={false}
              showTodayButton={true}
            />
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Select
              color="blue"
              // @ts-ignore
              variant="outlined"
              style={{ width: "100%", maxWidth: 300 }}
              label={t("maxStayTimeLabel", { ns: "shared" })}
              defaultValue={form.MaxStayTime}
              value={form.MaxStayTime}
              onChange={(e: any) => {
                updateField(FlightFormField.MaxStayTime, e.target.value, form);
              }}
              error={!!error[FlightFormField.MaxStayTime]}
              helperText={helptext[FlightFormField.MaxStayTime]}
            >
              <MenuItem value={0} key={0}>
                {t("maxStayTimeUnlimited")}
              </MenuItem>
              {[10, 15, 20].map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Select
              color="blue"
              // @ts-ignore
              variant="outlined"
              style={{ width: "100%", maxWidth: 300 }}
              label={t("maxQueueSizeLabel", { ns: "shared" })}
              defaultValue={form.MaxQueueSize}
              value={form.MaxQueueSize}
              onChange={(e: any) => {
                updateField(FlightFormField.MaxQueueSize, e.target.value, form);
              }}
              error={!!error[FlightFormField.MaxQueueSize]}
              helperText={helptext[FlightFormField.MaxQueueSize]}
            >
              <MenuItem value={0} key={0}>
                {t("maxQueueSizeUnlimited")}
              </MenuItem>
              {[99, 50, 20].map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            mt={4}
            color="info.main"
            component="h3"
            textAlign="left"
            style={{ width: "100%", maxWidth: 300 }}
          >
            And, most importantly:
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="blue"
              // @ts-ignore
              variant="outlined"
              label={t("dodoCodeLabel", { ns: "shared" })}
              placeholder={t("placeholder.code")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={form.Code}
              value={form.Code}
              onChange={(e: any) => {
                updateField(FlightFormField.Code, e.target.value, form);
              }}
              error={!!error[FlightFormField.Code]}
              helperText={helptext[FlightFormField.Code]}
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ filter: profileVerified ? undefined : "grayscale(1)" }}
          >
            <Box
              mt={4}
              color="info.main"
              component="h3"
              textAlign="left"
              style={{ width: "100%", maxWidth: 300 }}
            >
              Advanced Settings (verified user only):
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Select
                color="blue"
                // @ts-ignore
                variant="outlined"
                style={{ width: "100%", maxWidth: 300 }}
                label={t("flightPassengerTypeLabel", { ns: "shared" })}
                defaultValue={form.FlightPassengerType}
                value={form.FlightPassengerType}
                onChange={(e: any) => {
                  updateField(
                    FlightFormField.FlightPassengerType,
                    e.target.value,
                    form,
                  );
                }}
                error={!!error[FlightFormField.FlightPassengerType]}
                helperText={helptext[FlightFormField.FlightPassengerType]}
                disabled={!profileVerified}
              >
                <MenuItem
                  value={FlightPassengerType.ALL}
                  key={FlightPassengerType.ALL}
                >
                  {t("flightPassengerTypeAll", { ns: "shared" })}
                </MenuItem>
                <MenuItem
                  value={FlightPassengerType.TRUSTED}
                  key={FlightPassengerType.TRUSTED}
                >
                  {t("flightPassengerTypeTrusted", { ns: "shared" })}
                </MenuItem>
              </Select>
            </Box>
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Select
                color="blue"
                // @ts-ignore
                variant="outlined"
                style={{ width: "100%", maxWidth: 300 }}
                label={t("requirePasswordLabel", { ns: "shared" })}
                defaultValue={
                  form.RequirePassword
                    ? RequirePasswordOption.YES
                    : RequirePasswordOption.NO
                }
                value={
                  form.RequirePassword
                    ? RequirePasswordOption.YES
                    : RequirePasswordOption.NO
                }
                onChange={(e: any) => {
                  updateField(
                    FlightFormField.RequirePassword,
                    e.target.value,
                    form,
                  );
                }}
                error={!!error[FlightFormField.RequirePassword]}
                helperText={helptext[FlightFormField.RequirePassword]}
                disabled={!profileVerified}
              >
                <MenuItem
                  value={RequirePasswordOption.NO}
                  key={RequirePasswordOption.NO}
                >
                  {t("requirePasswordOptionNo", { ns: "shared" })}
                </MenuItem>
                <MenuItem
                  value={RequirePasswordOption.YES}
                  key={RequirePasswordOption.YES}
                >
                  {t("requirePasswordOptionYes", { ns: "shared" })}
                </MenuItem>
              </Select>
            </Box>
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="blue"
              disabled={submitDisabled}
              onClick={() => {
                if (isFlightCreated) {
                  submit();
                } else {
                  hostRuleDialog.open();
                }
              }}
            >
              {submitting
                ? t(sec2TKey("submitting", isFlightCreated))
                : t(sec2TKey("submit", isFlightCreated))}
            </Button>
          </Box>
          {isFlightCreated && (
            <Box
              mt={4}
              color={isFlightOpen ? "info.main" : "error.main"}
              fontWeight="fontWeightBold"
              fontSize={16}
            >
              {t(
                isFlightOpen
                  ? "sec2.common.flightIsOpen"
                  : "sec2.common.flightIsClosed",
              )}
            </Box>
          )}
          {isFlightCreated && (
            <Box
              mt={4}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component={Link}
                // @ts-ignore
                to={`/flight/${profile.id}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
                color="info.main"
                borderColor="text.primary"
                borderBottom="2px solid"
                fontWeight="700"
                fontSize={16}
                style={{ textDecoration: "none" }}
              >
                {t("sec2.common.viewFlight")}
              </Box>
            </Box>
          )}
        </Card>
      </Section>
      <HostRules
        isOpen={hostRuleDialog.isOpen}
        close={hostRuleDialog.close}
        onConfirm={() => {
          submit();
        }}
      />
    </Box>
  );
}
