import { makeStyles } from "@material-ui/core";
import { CardProps } from "./Card.types";

const BACKGROUND_COLOR: { [k in CardProps["variant"]]: string } = {
  blue: "rgba(104,161,215,0.6)",
  cyan: "rgba(0, 125, 117, 0.3)",
  mint: "rgba(0,129,96,0.3)",
  yellow: "rgba(241,226,111,0.8)",
};

export const useStyles = makeStyles((theme) => ({
  container: ({ variant }: CardProps) => {
    return {
      backgroundColor: BACKGROUND_COLOR[variant],
      padding: 11,
      borderRadius: "var(--base-radius-large)",
    };
  },
  innerContainer: {
    backgroundColor: "#f8f8f0",
    padding: "27px 27px 39px",
    borderRadius: "var(--base-radius)",
    boxShadow: "var(--base-shadow)",
  },
}));
