import { FlightApi } from "./../api";
import React from "react";
import { User } from "../types/data";
import get from "lodash.get";

const audio = new Audio(
  "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2Fdodo_airlines.mp3?alt=media&token=12e93965-47a2-481b-9eea-d319cfb09707",
);

// undefined: not this user's turn yet
export default function useFlightCode(
  user: User,
  flightCreatorId: string,
  shouldShowCode: boolean,
  lastChangedAt: number,
) {
  const [state, setState] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (!user || !shouldShowCode) return;

    FlightApi.getCode(flightCreatorId)
      .then((res) => {
        const newCode = get(res, "data.code", "");
        setState(newCode);
        if (!!newCode && audio.paused) {
          audio.play().catch(console.error);
        }
      })
      .catch(() => {
        setState(undefined);
      });
  }, [user, flightCreatorId, shouldShowCode, lastChangedAt]);

  return state;
}
