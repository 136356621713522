import { FlightPassengerType } from "./all";
import firebase from "firebase";

export enum Language {
  ENGLISH = "EN",
  CHINESE = "中文",
  JAPANESE = "日本語",
}

export enum Region {
  JAPAN = "Japan",
  THE_AMERICAS = "The Americas",
  EUROPE = "Europe",
  AUSTRALIA_NEW_ZEALAND = "Australia/New Zealand",
  HONG_KONG_TAIWAN_SOUTH_KOREA = "Hong Kong/Taiwan/South Korea",
}

export enum Period {
  MORNING = "Morning",
  AFTERNOON = "Afternoon",
}

export interface PriceRecord {
  createdAt: Date; // Datetime

  region?: Region;
  island?: string;
  date: Date; // Date
  period: Period;
  price: number;
}

export interface PriceRecordFirebase {
  createdAt: firebase.firestore.Timestamp;

  date: firebase.firestore.Timestamp;
  price: number;
  uid: string;
}

export enum PatreonTier {
  SUPPORTER = "SUPPORTER",
  RESIDENT = "RESIDENT",
  RESIDENT_PLUS = "RESIDENT_PLUS",
  CITIZEN = "CITIZEN",
  COUNCILOR = "COUNCILOR",
  INVESTOR = "INVESTOR",
  INVESTOR_PLUS = "INVESTOR_PLUS",
  STREAMER = "STREAMER",
  STREAMER_PLUS = "STREAMER_PLUS",
}

export interface Profile {
  id?: string;
  displayName: string;
  profilePhoto: string;
  islandName: string;
  nsId: string;
  region: string;
  message: string;

  patreon?: string;
  verificationLevel?: string;

  mixer?: string;
  twitch?: string;

  dreamAddress?: string;
}

export interface ProfileForm {
  id?: string;
  displayName?: string;
  profilePhoto?: string;
  islandName?: string;
  nsId?: string;
  region?: Region;
  message?: string;

  patreon?: PatreonTier;
  verificationLevel?: string;

  mixer?: string;
  twitch?: string;

  dreamAddress?: string;
}

export enum PassengerState {
  WAITING = "Waiting",
  BOARDING = "Boarding",
  LANDED = "LANDED",
}

export interface Passenger {
  uid: string; // guest uid
  codeShownAt?: Date;

  joinedAt: Date;
  joinedAtRaw: number;
}

export interface Chat {
  id: string;
  uid: string;
  content: string;
  createdAt: Date;
}

export enum TerminalType {
  TURNIPS = "TURNIPS",
  SPECIAL_VISITORS = "SPECIAL_VISITORS",
  FLEA_MARKET = "FLEA_MARKET",
  GENERAL = "GENERAL",
}

export interface ItemWithCount {
  id: string;
  count: number;
}

export interface Terminal {
  displayName: string;
  description: string;
  image?: string;
}

export enum TurnipsFlightType {
  SELLING = "SELLING", // High price for selling
  BUYING = "BUYING", // Daisy Mae is on island
}

export enum SpecialCharacter {
  CELESTE = "CELESTE",
  CJ = "CJ",
  DAISY_MAE = "DAISY_MAE",
  FLICK = "FLICK",
  KICKS = "KICKS",
  LEIF = "LEIF",
  REDD = "REDD",
  SAHARAH = "SAHARAH",
  TIMMY_AND_TOMMY = "TIMMY_AND_TOMMY",
}

export interface Flight {
  // flight metadata
  id: string;
  status: "open" | "closed";
  terminal: TerminalType;

  // flight details
  code: string;
  maxAllowed: number; // <= 4
  maxStayTime?: number;
  maxQueueSize?: number;
  hostId: string; // host uid
  region: Region;
  islandName: string;
  hostName: string;
  planToCloseAt?: Date; // TODO: fix date picker, make sure users can only submit valid values
  message?: string; // message from the host

  // turnips related
  price?: number;
  turnipsFlightType?: TurnipsFlightType;

  // special character
  specialCharacter?: SpecialCharacter;

  // queue related
  queueLocked: boolean;
  hostStatus: string; // "online" | "offline"

  flightPassengerType?: FlightPassengerType;
  requirePassword?: boolean;

  lastChangedAt: number;
}

export type User = firebase.User | null | undefined;

export enum LEAVE_QUEUE_RESON {
  LEAVE = "LEAVE",
  TRIP_FINISHED = "TRIP_FNISHED",
  OFFLINE = "OFFLINE",
  REMOVED_BY_HSOT = "REMOVED_BY_HOST",
  FLIGHT_CLOSED = "FLIGHT_CLOSED",
}

export interface CatalogItem {
  id: string;
  name: string;
  imgUrl: string;
  cat: string;
}

export enum CatalogItemDatabaseState {
  UNKNOWN = "UNKNWON",
  CATALOGUED = "CATALOGUED",
  WISH_LISTED = "WISH_LISTED",
}
export interface CatalogItemDatabase {
  state: CatalogItemDatabaseState;
}

export interface CatalogItemDatabaseExtended extends CatalogItemDatabase {
  id: string;
}

export interface Report {
  uid: string;
  reason?: string;
  createdAt: Date;
}
