import React from "react";

import firebase from "../shared/firebase";
import { get24HoursAgo } from "../shared/utils";
import {
  Period,
  PriceRecord,
  PriceRecordFirebase,
  Region,
} from "../types/data";
import { isBefore, getHours } from "date-fns";
import * as R from "ramda";

export interface UsePriceListForPast24HoursProps {
  user: firebase.User | undefined | null;
  region?: Region;
}

// TODO: this isn't really data for past 24 hours, refactor later
export default function usePriceListForPast24Hours({
  region,
}: UsePriceListForPast24HoursProps) {
  const [list, setList] = React.useState<PriceRecord[] | null | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const now = new Date();
    const startDate = get24HoursAgo(now);

    firebase
      .firestore()
      .collection("prices")
      .where("date", ">=", startDate)
      .orderBy("date", "desc")
      .limit(32)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setList(null);
          return;
        }
        const mappedDocs = snapshot.docs.map<PriceRecord>((doc) => {
          const data = doc.data() as PriceRecordFirebase;
          const { date, price, createdAt, uid } = data;
          return {
            price,
            date: date.toDate(),
            period:
              getHours(date.toDate()) >= 12 ? Period.AFTERNOON : Period.MORNING,
            createdAt: createdAt.toDate(),
            island: uid,
          };
        });

        const uniqByUid = R.uniqBy<PriceRecord, string>(
          (i) => i.island as string,
          mappedDocs,
        ).sort((a, b) => (isBefore(a.price, b.price) ? 1 : -1));
        uniqByUid.forEach(async (item) => {
          const profile = await firebase
            .firestore()
            .collection("users")
            .doc(item.island)
            .get();
          item.region = profile.get("region")
            ? (profile.get("region") as Region)
            : undefined;
        });
        setList(uniqByUid);
      })
      .catch((error) => {
        console.error(error);
        setList(null);
      });
  }, []);

  if (!list) {
    return list;
  }

  if (region) {
    return list.filter((item) => region === item.region);
  }

  return list;
}
