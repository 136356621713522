import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { LOCAL_STORAGE_LANGUAGE_KEY } from "./hooks/useLanguage";
import { Language } from "./types/data";
import { enUS, ja, zhCN } from "date-fns/locale";
import "./shared/i18n";

function initDateFnLanguage() {
  const language =
    (window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) as Language) ||
    Language.ENGLISH;

  switch (language) {
    case Language.JAPANESE:
      window.__locale = ja;
      return;
    case Language.CHINESE:
      window.__locale = zhCN;
      return;
    default:
      window.__locale = enUS;
      return;
  }
}

initDateFnLanguage();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
