import React from "react";
import Box from "@material-ui/core/Box";
import format from "date-fns/format";
import { Chat } from "../../../../types/data";
import { getDateOrUndefined } from "../../../../shared/utils";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import DisplayNameLabel from "../../../../shared/components/DisplayNameLabel/DisplayNameLabel";

export interface SystemMessageProps {
  chat: Chat;
}

export enum FlightEvent {
  FlightCreated = "FlightCreated",
  FlightEnded = "FlightEnded",
  FlightOpened = "FlightOpened",
  QueueLocked = "QueueLocked",
  QueueUnlocked = "QueueUnlocked",
  TerminalChanged = "TerminalChanged",
  MaxAllowedChanged = "MaxAllowedChanged",
  EndTimeChanged = "EndTimeChanged",
  MessageChanged = "MessageChanged",
  PriceChanged = "PriceChanged",
  TurnipsFlightTypeChanged = "TurnipsFlightTypeChanged",
  SpecialCharacterChanged = "SpecialCharacterChanged",
  CodeChanged = "CodeChanged",
  UserKickedByHost = "UserKickedByHost",
}

export interface SystemMessageData {
  type: FlightEvent;
  before: any;
  after: any;
}

function formatMessage(data: SystemMessageData, t: TFunction) {
  switch (data.type) {
    case FlightEvent.FlightCreated:
      return "This is a new flight!";
    case FlightEvent.FlightEnded:
      return "Flight ended.";
    case FlightEvent.FlightOpened:
      return "Flight re-opened!";
    case FlightEvent.QueueLocked:
      return "Queue temporarily locked.";
    case FlightEvent.QueueUnlocked:
      return "Queue unlocked!";
    case FlightEvent.TerminalChanged:
      return `Terminal changed to ${data.after}`;
    case FlightEvent.MaxAllowedChanged:
      return `Max allowed changed to ${data.after}`;
    case FlightEvent.EndTimeChanged: {
      const planToCloseAt = getDateOrUndefined(data.after);
      return `End time changed to ${
        planToCloseAt
          ? format(planToCloseAt, "hh:mm a (eee, yyyy-MM-dd)")
          : t("planToCloseAtUnknown", { ns: "shared" })
      }`;
    }
    case FlightEvent.MessageChanged:
      return [
        <Box key="title">Host message updated:</Box>,
        <Box key="content">{data.after}</Box>,
      ];
    case FlightEvent.PriceChanged:
      return `Price changed to ${data.after}.`;
    case FlightEvent.CodeChanged:
      return `Code updated!`;
    case FlightEvent.UserKickedByHost:
      return (
        <Box>
          Host removed <DisplayNameLabel uid={data.after} enableLink /> from
          queue.
        </Box>
      );
    default:
      return undefined;
  }
}

export default function SystemMessage({ chat }: SystemMessageProps) {
  const { t } = useTranslation("Chat");
  const details = React.useMemo(() => {
    try {
      return JSON.parse(chat.content) as SystemMessageData;
    } catch (error) {
      return undefined;
    }
  }, [chat]);

  if (details === undefined) {
    return null;
  }

  const message = formatMessage(details, t);

  if (message === undefined) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      fontSize={10}
      color="text.primary"
      px={1}
      py={0.5}
      whiteSpace="normal"
      style={{ opacity: 0.8 }}
    >
      {message}
    </Box>
  );
}
