import React from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ThemeProvider } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";

import useLanguage from "../hooks/useLanguage";
import Home from "../pages/Home/Home";
import SignIn from "../pages/SignIn/SignIn";
import SubmitPrice from "../pages/SubmitPrice/SubmitPrice";
import Square from "../pages/Square/Square";
import PassportForm from "../pages/PassportForm/PassportForm";
import Passport from "../pages/Passport/Passport";
import Airport from "../pages/Airport/Airport";
import Flight from "../pages/Flight/Flight";
import FlightUpdateForm from "../pages/FlightUpdateForm";
import firebase from "../shared/firebase";
import UserContext from "../shared/UserContext";
import { Language, User } from "../types/data";
import { useStyles } from "./App.styles";
import { theme } from "./theme";
import UnderConstruction from "../pages/UnderConstruction/UnderConstruction";
import Tracking from "../pages/Tracking/Tracking";
import useUser from "../hooks/useUser";
import useUserPresenceSync from "../hooks/useUserPresenceSync";
import Promotion from "../shared/components/Promotion";
import useProfileWatch from "../hooks/useProfileWatch";
import { UserApi } from "../api";
import Catalog from "../pages/Catalog/Catalog";
import FAQ from "../shared/components/FAQ";
import { useDialog } from "../hooks/useDialog";

function useScrollToTopWhenPathnameChange() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
}

function useAutoInitShortId(user: User) {
  const profile = useProfileWatch(user ? user.uid : undefined);
  React.useEffect(() => {
    if (!profile) return;

    if (!profile.id) {
      UserApi.initMyShortId();
    }
  }, [profile]);
}

function App() {
  const classes = useStyles();
  const { t } = useTranslation("App");

  const user = useUser();

  useUserPresenceSync(user);

  useAutoInitShortId(user);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [language, setLanguage] = useLanguage();
  const history = useHistory();
  const FAQDialog = useDialog();

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      setIsDrawerOpen(false);
    });

    return () => {
      unlisten();
    };
  }, [setIsDrawerOpen, history]);

  useScrollToTopWhenPathnameChange();

  const signOut = React.useCallback(() => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        history.push("/");
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [history]);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="application-name" content={t("name")}></meta>
        <meta name="apple-mobile-web-app-title" content={t("name")} />
      </Helmet>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            autoHideDuration={3600}
          >
            <UserContext.Provider value={user}>
              <Box
                className={classes.root}
                display="flex"
                flexDirection="column"
              >
                <Box className={classes.bg}></Box>
                <AppBar position="sticky">
                  <Toolbar
                    style={{
                      color: "#6b5c43",
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box textAlign="center" position="relative" zIndex={2}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                          setIsDrawerOpen(true);
                        }}
                      >
                        <Icon className="fas fa-bars" />
                      </IconButton>
                    </Box>
                    <Box position="relative" zIndex={2}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e.target.value as Language);
                        }}
                        disableUnderline
                        IconComponent={() => null}
                        classes={{ select: classes.regionSelect }}
                      >
                        {Object.values(Language).map((l) => (
                          <MenuItem value={l} key={l}>
                            {l}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Box
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      zIndex={1}
                    >
                      <Box
                        component={Link}
                        // @ts-ignore
                        to="/"
                        fontSize={18}
                        fontWeight="700"
                        style={{
                          textDecoration: "none",
                          color: "rgb(107, 92, 67)",
                        }}
                      >
                        {t("name")}
                      </Box>
                    </Box>
                  </Toolbar>
                </AppBar>
                <Drawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={() => {
                    setIsDrawerOpen(false);
                  }}
                >
                  <Box
                    width={250}
                    height="100%"
                    bgcolor="#f1e26f"
                    display="flex"
                    flexDirection="column"
                  >
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        mt={4}
                      >
                        <Box
                          component="img"
                          // @ts-ignore
                          src="https://www.animal-crossing.com/new-horizons/assets/img/global/logos/logo-acnh-en-2x.png"
                          width="auto"
                          height={72}
                        ></Box>
                      </Box>
                      <Box
                        mt={2}
                        fontSize={18}
                        fontWeight="700"
                        textAlign="center"
                      >
                        {t("name")}
                      </Box>
                      <List>
                        <ListSubheader
                          style={{ color: "rgba(107, 92, 67, .5)" }}
                        >
                          {t("menu.world")}
                        </ListSubheader>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/airport"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon className="fas fa-plane" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("menu.airport")} />
                          </Box>
                        </ListItem>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/square"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon className="fas fa-store" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("menu.square")} />
                          </Box>
                        </ListItem>

                        <ListSubheader
                          style={{ color: "rgba(107, 92, 67, .5)" }}
                        >
                          {t("menu.personal")}
                        </ListSubheader>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/catalog"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon className="fas fa-book" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={t("menu.catalog")} />
                          </Box>
                        </ListItem>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/tracking"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              {
                                <Icon
                                  className="fas fa-chart-line"
                                  fontSize="small"
                                />
                              }
                            </ListItemIcon>
                            <ListItemText primary={t("menu.tracking")} />
                          </Box>
                        </ListItem>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/tracking#calculator"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon
                                className="fas fa-calculator"
                                fontSize="small"
                              />
                            </ListItemIcon>
                            <ListItemText primary={t("menu.calculator")} />
                          </Box>
                        </ListItem>
                        <ListItem
                          style={{
                            borderBottom: "1px solid rgba(200,172,61,0.33)",
                          }}
                        >
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/passport"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            color="text.primary"
                            style={{ textDecoration: "none" }}
                          >
                            <ListItemIcon>
                              <Icon
                                className="fas fa-passport"
                                fontSize="small"
                              />
                            </ListItemIcon>
                            <ListItemText primary={t("menu.passport")} />
                          </Box>
                        </ListItem>
                      </List>
                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {!user && (
                          <Box
                            component={Link}
                            // @ts-ignore
                            to="/sign-in"
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            color="text.primary"
                            borderColor="text.primary"
                            borderBottom="2px solid"
                            fontWeight="700"
                            fontSize={16}
                            style={{ textDecoration: "none" }}
                          >
                            {t("menu.signIn")}
                          </Box>
                        )}
                        {user && (
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            color="text.primary"
                            borderColor="text.primary"
                            borderBottom="2px solid"
                            fontWeight="700"
                            fontSize={16}
                            style={{ textDecoration: "none" }}
                            onClick={signOut}
                          >
                            {t("menu.signOut")}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      my={2}
                    >
                      <Box>v0.32.0</Box>

                      <Box
                        component="a"
                        // @ts-ignore
                        href="https://twitter.com/TurnipsExchange"
                        _target="blank"
                        style={{ textDecoration: "underline" }}
                      >
                        @TurnipsExchange
                      </Box>
                      <Box>Click ↑ if any issue</Box>
                    </Box>
                  </Box>
                </Drawer>
                <Box flexGrow={1} display="flex" flexDirection="column">
                  <Switch>
                    <Route path="/" exact>
                      <Home />
                    </Route>
                    <Route path="/square" exact>
                      <Square />
                    </Route>
                    <Route path="/submit-price" exact>
                      <SubmitPrice />
                    </Route>
                    <Route path="/tracking" exact>
                      <Tracking />
                    </Route>
                    <Route path="/passport/:id" exact>
                      <Passport />
                    </Route>
                    <Route path="/passport" exact>
                      <PassportForm />
                    </Route>
                    <Route path="/airport" exact>
                      <Airport openFAQDialog={FAQDialog.open} />
                    </Route>
                    <Route path="/flight/new" exact>
                      <Redirect to="/flight/manage" />
                    </Route>
                    <Route path="/flight/manage" exact>
                      <FlightUpdateForm />
                    </Route>
                    <Route path="/flight/:id" exact>
                      <Flight openFAQDialog={FAQDialog.open} />
                    </Route>
                    <Route path="/catalog" exact>
                      <Catalog />
                    </Route>
                    <Route path="/sign-in" exact>
                      <SignIn />
                    </Route>
                    <Route component={UnderConstruction} />
                  </Switch>
                </Box>
                <Box flexShrink={0} className={classes.footer}>
                  <Box mx={4} fontSize={12}>
                    {t("footer.disclaimer")}
                  </Box>
                  <Promotion />
                </Box>
              </Box>
              <FAQ open={FAQDialog.isOpen} close={FAQDialog.close} />
            </UserContext.Provider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
