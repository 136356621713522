import getWeek from "date-fns/getWeek";
import getYear from "date-fns/getYear";
import React from "react";

import firebase from "../shared/firebase";

export interface Buyin {
  price?: number;
  count?: number;
}

export default function useMyWeeklyBuyinPrice(
  user: firebase.User | null | undefined,
) {
  const [data, setData] = React.useState<Buyin | undefined>(undefined);

  React.useEffect(() => {
    if (!user) return;

    const now = new Date();
    const unsubscribe = firebase
      .firestore()
      .collection("buyin")
      .where("uid", "==", user.uid)
      .where("year", "==", getYear(now))
      .where("week", "==", getWeek(now))
      .onSnapshot((snapshot) => {
        // console.log("on: my weekly buyin");
        if (snapshot.empty) {
          return setData({});
        }

        setData({
          price: snapshot.docs[0].get("price") || undefined,
          count: snapshot.docs[0].get("count") || undefined,
        });
      });

    return () => {
      // console.log("off: my weekly buyin");
      unsubscribe();
    };
  }, [user, setData]);

  return data;
}
