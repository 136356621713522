import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import Card from "../../shared/components/Card/Card";
import Section from "../../shared/components/Section/Section";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import GridList, {
  CatalogGridListMode,
} from "../../shared/components/Catalog/GridList";
import { useCatalogSearch } from "../../hooks/useCatalogSearch";
import UserContext from "../../shared/UserContext";
import { useUserCatalog } from "../../hooks/useUserCatalog";
import { useCatalog } from "../../hooks/useCatalog";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    borderBottomColor: theme.palette.text.primary,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Catalog() {
  const { t } = useTranslation("Catalog");
  const classes = useStyles();
  const user = React.useContext(UserContext);

  const catalog = useCatalog();
  const { catalogued, wishListed, catalog: catalogUser } = useUserCatalog(
    user?.uid,
  );

  const {
    result,
    search,
    searching,
    searchPhrase,
    setSearchPhrase,
  } = useCatalogSearch();

  if (user === null) {
    return (
      <Redirect to="/sign-in?message=Please%20sign%20in%20before%20viewing%20your%20catalog%21&returnUrl=%2Fcatalog" />
    );
  }

  if (
    user === undefined ||
    catalog === undefined ||
    catalogUser === undefined
  ) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="cyan" />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
        <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
          {t("sec1.desc1")}
        </Box>
      </Section>
      <Section variant="mint">
        <Card variant="mint">
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec2.desc1")}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <InputBase
              className={classes.input}
              placeholder={t("sec2.placeholder")}
              inputProps={{ "aria-label": t("sec2.placeholder") }}
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
              disabled={searching}
              // @ts-ignore
              onKeyDown={(e: any): void => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.stopPropagation();
                  search();
                }
              }}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              disabled={searching}
              onClick={search}
            >
              <Icon className="fas fa-search" fontSize="small" />
            </IconButton>
          </Box>
          {searching && (
            <Box py={2} px={2} color="text.primary" style={{ opacity: 0.8 }}>
              Searching...
            </Box>
          )}
          <Box mt={2}>
            <GridList
              data={result}
              catalog={catalogUser}
              uid={user.uid}
              mode={CatalogGridListMode.Add}
            />
          </Box>
        </Card>
      </Section>
      <Section variant="mint">
        <Card variant="mint">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="h2" color="info.main">
              {t("sec3.title")}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec3.desc1")}
          </Box>
          <GridList
            data={catalogued}
            uid={user.uid}
            mode={CatalogGridListMode.Remove}
          />
        </Card>
      </Section>
      <Section variant="mint">
        <Card variant="mint">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="h2" color="info.main">
              {t("sec4.title")}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec4.desc1")}
          </Box>
          <GridList
            data={wishListed}
            uid={user.uid}
            mode={CatalogGridListMode.Remove}
          />
        </Card>
      </Section>
    </Box>
  );
}
