import React from "react";
import { useStyles } from "./Card.styles";
import Box from "@material-ui/core/Box";
import { CardProps } from "./Card.types";

const Card: React.FC<CardProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.container} position="relative" style={props.style}>
      <Box
        className={classes.innerContainer}
        style={props.innerContainerStyle}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default Card;
