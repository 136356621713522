import React from "react";
import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import startOfToday from "date-fns/startOfToday";
import { isAfter } from "date-fns";

export enum PROMOTION_TEXT_ID {
  TRY_NOW = "PROMOTION_TEXT_ID/TRY_NOW",
  HOST_IN_APP = "PROMOTION_TEXT_ID/HOST_IN_APP",
}

export const PROMOTION_TEXT = {
  [PROMOTION_TEXT_ID.TRY_NOW]:
    "Never miss a flight with real-time notifications about Turnips and more! Originated from Turnips Exchange.",
  [PROMOTION_TEXT_ID.HOST_IN_APP]:
    "Host your island with Link App with more advanced flight! Originated from Turnips Exchange.",
};

export function useLinkAppPromotionBanner(id: PROMOTION_TEXT_ID) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    const lastDismissed = window.localStorage.getItem(id);

    if (lastDismissed && isAfter(new Date(lastDismissed), startOfToday())) {
      return;
    }

    enqueueSnackbar(PROMOTION_TEXT[id], {
      content: (key, message) => (
        <Box
          bgcolor="rgb(248, 248, 240)"
          py={2}
          px={2}
          display="flex"
          flexDirection="column"
          borderRadius={4}
          style={{ boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)" }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <img
                alt="Logo of Link - The ACNH Community"
                src="https://acnh.link/apple-icon-180x180.png"
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 8,
                  alignSelf: "flex-start",
                }}
              />
              <Box>
                <Box style={{ fontWeight: 800 }} mb={0.5} color="text.primary">
                  Start using the Link App!
                </Box>
                <Box fontSize={10} mb={0.5}>
                  Available on <strong>App Store</strong> {"& "}
                  <strong>Google Play</strong> {"& "}
                  <strong>Web</strong>
                </Box>
                <Box fontSize={13}>{message}</Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            <MuiButton
              variant="text"
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                window.localStorage.setItem(id, new Date().toUTCString());
                closeSnackbar(key);
              }}
            >
              later
            </MuiButton>
            <MuiButton
              variant="contained"
              size="small"
              color="primary"
              style={{ fontWeight: 800 }}
              target="_blank"
              href="https://acnhlink.page.link/app"
            >
              I wanna try!
            </MuiButton>
          </Box>
        </Box>
      ),
      key: id,
      persist: true,
    });

    return () => {
      closeSnackbar(id);
    };
  }, [enqueueSnackbar, closeSnackbar, id]);
}
