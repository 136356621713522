import React from "react";
import firebase from "../shared/firebase";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export function useCatalogSearch() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("Catalog");

  const [result, setResult] = React.useState([]);
  const [searchPhrase, setSearchPhrase] = React.useState("");
  const [searching, setSearching] = React.useState(false);

  const search = React.useCallback(() => {
    if (searchPhrase === "") return;

    setSearching(true);
    firebase
      .functions()
      .httpsCallable("search")({
        search: searchPhrase,
      })
      .then(({ data }) => {
        setSearching(false);
        setResult(data);
      })
      .catch((error) => {
        setSearching(false);
        enqueueSnackbar(
          t("error.general", { error: error.message, ns: "shared" }),
          {
            variant: "error",
          },
        );
      });
  }, [enqueueSnackbar, searchPhrase, t]);

  return {
    result,
    search,
    searching,
    searchPhrase,
    setSearchPhrase,
  };
}
