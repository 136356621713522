import { useSnackbar } from "notistack";
import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import Section from "../../shared/components/Section/Section";
import TextField from "../../shared/components/TextField/TextField";
import firebase from "../../shared/firebase";
import UserContext from "../../shared/UserContext";
import { useTranslation, Trans } from "react-i18next";
import { User } from "../../types/data";
import Typography from "@material-ui/core/Typography";
import SignInWithEmail from "./components/SignInWithEmail";
import SignUpWithEmail from "./components/SignUpWithEmail";

function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// TODO: refactor, use mui input validation, there must be something like this
function useEmailField() {
  const { t } = useTranslation("SignIn");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<string | null | undefined>(null);
  const onChangeEmail = React.useCallback(
    (e) => {
      const newValue = e.target.value;

      setEmail(newValue);

      if (newValue === undefined) {
        return;
      }
      if (validateEmail(newValue)) {
        setError(null);
      } else {
        setError(t("emailError"));
      }
    },
    [t],
  );

  return {
    validated: email && error === null,
    error,
    email,
    onChangeEmail,
  };
}

function useSignInWithMagicLink(user: User) {
  const { t } = useTranslation("SignIn");
  const [submitting, setSubmitting] = React.useState(false);
  const [sent, setSent] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | null | undefined>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const signInWithMagicLink = React.useCallback(
    (email: string) => {
      setError(undefined);
      setSubmitting(true);
      const verificationUrl = new URL("/sign-in", window.location.origin);
      verificationUrl.searchParams.set("message", t("verifyingMagicLink"));
      firebase
        .auth()
        .sendSignInLinkToEmail(email, {
          url: verificationUrl.toString(),
          handleCodeInApp: true,
        })
        .then(function () {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
          setSent(email);
          setError(null);
          setSubmitting(false);
        })
        .catch(function (error) {
          console.error(error);
          setSent(undefined);
          setError(error.message);
          setSubmitting(false);
          // Some error occurred, you can inspect the code: error.code
        });
    },
    [t],
  );

  React.useEffect(() => {
    if (user === undefined) return;

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      setError(undefined);
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt(t("confirmEmail"));
      }

      setSubmitting(true);
      firebase
        .auth()
        .signInWithEmailLink(email!, window.location.href)
        .then(function (result) {
          setError(null);
          window.localStorage.removeItem("emailForSignIn");
          enqueueSnackbar(t("successMsg"), {
            variant: "success",
          });
          setSubmitting(false);
          // TODO: should go back to original url
          history.push("/");
        })
        .catch(function (error) {
          console.error(error);
          setSent(undefined);
          setSubmitting(false);
          setError(error.message);
        });
    }
  }, [setSent, setError, enqueueSnackbar, history, t, user]);

  return {
    sent,
    error,
    signInWithMagicLink,
    submitting,
  };
}

function useHandleQueryMessage() {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const message = currentUrl.searchParams.get("message");
    if (message) {
      enqueueSnackbar(message, {
        variant: "info",
      });
    }
  }, [enqueueSnackbar]);
}

// Note: returnUrl should be relative
function getRedirectUrl() {
  const currentUrl = new URL(window.location.href);
  const returnUrl = currentUrl.searchParams.get("returnUrl");

  return returnUrl || "/passport";
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SignIn() {
  const { t } = useTranslation("SignIn");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useHandleQueryMessage();

  const user = React.useContext(UserContext);
  const { email, error, onChangeEmail, validated } = useEmailField();

  const {
    sent,
    error: magicLinkError,
    signInWithMagicLink,
    submitting,
  } = useSignInWithMagicLink(user);

  const [activeTab, setActiveTab] = React.useState(0);

  const handleChangeTab = (event: any, newValue: number) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (sent) {
      // TODO: refactor
      onChangeEmail({ target: { value: undefined } });
    }
  }, [sent, onChangeEmail]);

  if (user === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="blue" />
      </Box>
    );
  }

  if (user && user.emailVerified) {
    return <Redirect to={getRedirectUrl()} />;
  }

  const currentUser = firebase.auth().currentUser;

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
      </Section>
      {currentUser && !currentUser.emailVerified && (
        <Box my={2} display="flex" flexDirection="column" alignItems="center">
          <Box component="p" textAlign="center">
            {t("verifyEmailDesc", { email: currentUser.email })}
          </Box>
          <Button
            variant="mint"
            icon={
              <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
            }
            // @ts-ignore
            onClick={() => {
              if (!currentUser) return;
              currentUser
                .sendEmailVerification()
                .then(function () {
                  enqueueSnackbar(t("verificationEmailSent"), {
                    variant: "success",
                  });
                })
                .catch(function (error) {
                  enqueueSnackbar(
                    t("verificationEmailError", { error: error.message }),
                    {
                      variant: "error",
                    },
                  );
                });
            }}
          >
            {t("verifyEmail")}
          </Button>
          <Box
            mt={2}
            onClick={() => {
              const snackbarKey = enqueueSnackbar(t("signingOut"), {
                variant: "info",
              });
              firebase
                .auth()
                .signOut()
                .then(() => {
                  window.location.reload();
                  closeSnackbar(snackbarKey);
                })
                .catch(function (error) {
                  closeSnackbar(snackbarKey);
                  console.error(error);
                });
            }}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              display: "inline-flex",
            }}
          >
            {t("signOut")}
          </Box>

          <Box fontSize="0.8em">{t("signOutDesc")}</Box>
        </Box>
      )}
      {!currentUser && (
        <React.Fragment>
          <Section variant="cyan">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box style={{ color: "rgba(0, 125, 117, 1)" }}>
                <Icon className="fas fa-hat-wizard" fontSize="large" />
              </Box>
              <Box component="h2" style={{ color: "rgba(0, 125, 117, 1)" }}>
                {t("sec2.title")}
              </Box>
            </Box>
            <Box component="p" textAlign="center">
              <Trans ns="SignIn" i18nKey="sec1.desc">
                No more passwords!
                <br /> We are gonna use the <strong>Magic Link</strong> which
                will be sent to your email to do the authentication!
              </Trans>
            </Box>
            <Card variant="cyan">
              <TextField
                color="mint"
                // @ts-ignore
                variant="outlined"
                label={t("sec2.emailLabel")}
                placeholder={t("sec2.emailPlaceholder")}
                style={{ width: "100%" }}
                value={email}
                error={!!error}
                helperText={error}
                onChange={onChangeEmail}
                disabled={submitting}
                InputLabelProps={{ shrink: true }}
              />
              <Box mt={2}>
                <Button
                  variant="mint"
                  icon={
                    <Icon
                      className="fas fa-chevron-right"
                      style={{ fontSize: 14 }}
                    />
                  }
                  // @ts-ignore
                  disabled={!validated || submitting}
                  onClick={() => {
                    signInWithMagicLink(email);
                  }}
                >
                  {t("sec2.cta")}
                </Button>
              </Box>
              {sent && (
                <Box mt={2} color="success.main">
                  {t("sec2.success", { email: sent })}
                </Box>
              )}
              {magicLinkError && (
                <Box mt={2} color="error.main">
                  {t("sec2.error", { error: magicLinkError })}
                </Box>
              )}
              <Box mt={1} fontSize="0.8em">
                {t("sec2.useBelowToSetPassword")}
              </Box>
            </Card>
          </Section>
          <Section variant="cyan">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box style={{ color: "rgba(0, 125, 117, 1)" }}>
                <Icon className="fas fa-lock" fontSize="large" />
              </Box>
              <Box component="h2" style={{ color: "rgba(0, 125, 117, 1)" }}>
                {t("sec3.title")}
              </Box>
            </Box>
            <Box component="p" textAlign="center">
              {t("orUseEmail")}
            </Box>
            <Card variant="cyan">
              <Tabs
                value={activeTab}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleChangeTab}
                aria-label="disabled tabs example"
              >
                <Tab label="Sign In" />
                <Tab label="Sign Up" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <SignInWithEmail />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <SignUpWithEmail />
              </TabPanel>
            </Card>
          </Section>
        </React.Fragment>
      )}
    </Box>
  );
}
