import { Passenger, User } from "./../../types/data";
import { Flight } from "../../types/data";
import { FlightQueues } from "./hooks/useQueues";
import { QueueType } from "../../types/all";

export function isHost(flight: Flight, uid: string | undefined) {
  return flight.hostId === uid;
}

// returns position of current user in queue
// used for determine whether user is in queue and position
//  0: not in queue
//  else: position
export function getQueuePosition(
  flightQueue: Passenger[] | undefined,
  uid: string | undefined,
) {
  if (flightQueue === undefined || uid === undefined) return 0;
  return flightQueue.findIndex((p) => p.uid === uid) + 1;
}

export function shouldShowCode(
  flight: Flight,
  positionStandard: number,
  positionFirstClass: number,
  isFirstClassEmpty: boolean,
) {
  if (positionFirstClass === 1) {
    return true;
  }

  if (isFirstClassEmpty) {
    return positionStandard === 0
      ? false
      : positionStandard <= flight.maxAllowed;
  }

  return positionStandard === 0 ? false : positionStandard < flight.maxAllowed;
}

export interface CurrentUserFlightInfo {
  user: User;
  isHost: boolean;
  queueType: QueueType | undefined;
  isInQueue: boolean;
  queuePosition: number | undefined;
  queueLength: number | undefined;
  shouldShowCode: boolean;
}

export function getCurrentUserFlightInfo(
  flight: Flight,
  queues: FlightQueues,
  user: User,
): CurrentUserFlightInfo {
  const { standard, firstClass } = queues;

  const queuePositionStandard = getQueuePosition(
    standard,
    user ? user.uid : undefined,
  );
  const queuePositionFirstClass = getQueuePosition(
    firstClass,
    user ? user.uid : undefined,
  );

  const isInQueueStandard =
    queuePositionStandard !== undefined && queuePositionStandard > 0;
  const isInQueueFirstClass =
    queuePositionFirstClass !== undefined && queuePositionFirstClass > 0;

  return {
    user,
    isHost: isHost(flight, user ? user.uid : undefined),
    queueType: isInQueueStandard
      ? QueueType.STANDARD
      : isInQueueFirstClass
      ? QueueType.FIRST_CLASS
      : undefined,
    isInQueue: isInQueueStandard || isInQueueFirstClass,
    queuePosition: queuePositionStandard || queuePositionFirstClass,
    queueLength: isInQueueStandard ? standard?.length : firstClass?.length,
    shouldShowCode: shouldShowCode(
      flight,
      queuePositionStandard,
      queuePositionFirstClass,
      !!(firstClass && firstClass.length === 0),
    ),
  };
}
