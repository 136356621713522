import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import Section from "../../shared/components/Section/Section";
import FeaturedStreamers from "./FeaturedStreamers";

export default function Home() {
  let history = useHistory();
  const { t } = useTranslation("Home");

  const goToSquare = () => {
    history.push("/square");
  };

  const goToTracking = () => {
    history.push("/tracking");
  };

  const goToAirport = () => {
    history.push("/airport");
  };

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Box
        component="img"
        // @ts-ignore
        src="https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcover.jpg?alt=media&token=df2269ee-e036-49ee-ae05-31c6b9e33d53"
        width="100%"
        mb={-3}
      ></Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        top={16}
        left={0}
        right={0}
        px={2}
      >
        <Box
          component="img"
          // @ts-ignore
          src="https://www.animal-crossing.com/new-horizons/assets/img/global/logos/logo-acnh-en-2x.png"
          width="24%"
          height="auto"
        ></Box>
        <Box
          component="img"
          // @ts-ignore
          src="https://www.animal-crossing.com/new-horizons/assets/img/global/logos/logo-nintendo-switch.svg"
          width="16%"
          height="auto"
        ></Box>
      </Box>

      <Section variant="brown" spacing={false}>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
        <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
          <Trans ns="Home" i18nKey="sec1.desc">
            This is your Turnips{" "}
            <span style={{ textDecoration: "line-through" }}>Stock Market</span>{" "}
            Exchange Square.
            <br />
            Make friends <small>(and money ; ) )</small>!
          </Trans>
        </Box>
      </Section>
      <Box mt={6} mb={2}>
        <FeaturedStreamers />
      </Box>
      <Section variant="blue">
        <Card variant="blue">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box color="info.light">
              <Icon className="fas fa-plane" fontSize="large" />
            </Box>
            <Box component="h2" color="info.main">
              {t("sec2.airport")}
            </Box>
            <Box textAlign="center">{t("sec2.airportDesc")}</Box>
          </Box>
          <Button
            variant="blue"
            icon={
              <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
            }
            onClick={goToAirport}
          >
            {t("sec2.goToAirport")}
          </Button>
        </Card>
        <Box mt={4}>
          <Card variant="blue">
            <Box
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box color="info.light">
                <Icon className="fas fa-store" fontSize="large" />
              </Box>
              <Box component="h2" color="info.main">
                {t("sec2.title")}
              </Box>
              <Box textAlign="center">{t("sec2.desc")}</Box>
            </Box>
            <Button
              variant="blue"
              icon={
                <Icon
                  className="fas fa-chevron-right"
                  style={{ fontSize: 14 }}
                />
              }
              onClick={goToSquare}
            >
              {t("sec2.cta")}
            </Button>
          </Card>
        </Box>
      </Section>
      <Section variant="mint">
        <Card variant="mint">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box color="success.light">
              <Icon className="fas fa-chart-line" fontSize="large" />
            </Box>
            <Box component="h2" color="success.main">
              {t("sec3.title")}
            </Box>
            <Box textAlign="center">{t("sec3.desc")}</Box>
          </Box>
          <Button
            variant="mint"
            icon={
              <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
            }
            onClick={goToTracking}
          >
            {t("sec3.cta")}
          </Button>
        </Card>
      </Section>
    </Box>
  );
}
