import { FlightPassengerType } from "./../types/all";
import React from "react";

import firebase from "../shared/firebase";
import { Flight, TerminalType, TurnipsFlightType } from "../types/data";
import { useLongId } from "./useLongId";
import { getDateOrUndefined } from "../shared/utils";

export function mapFlight(key: string, data: any): Flight | null {
  try {
    const {
      code,
      status = "closed",
      terminal = TerminalType.TURNIPS,

      id,
      hostName,
      hostStatus = "online",
      islandName,
      maxAllowed,
      maxStayTime,
      maxQueueSize,
      message,
      planToCloseAt,
      region,

      price,
      turnipsFlightType = TurnipsFlightType.SELLING,

      specialCharacter,

      queueLocked = false,

      flightPassengerType = FlightPassengerType.ALL,
      requirePassword = false,

      lastChangedAt,
    } = data;
    return {
      code,
      hostId: key!,
      hostName,
      hostStatus,
      id,
      islandName,
      maxAllowed,
      maxStayTime,
      maxQueueSize,
      message,
      planToCloseAt: getDateOrUndefined(planToCloseAt),
      price,
      region,
      status,
      specialCharacter,
      terminal,
      turnipsFlightType,
      queueLocked,
      flightPassengerType,
      requirePassword,
      lastChangedAt,
    };
  } catch (e) {
    return null;
  }
}

export default function useFlight(id: string | undefined) {
  const [data, setData] = React.useState<Flight | null | undefined>(undefined);

  const longId = useLongId(id);

  React.useEffect(() => {
    if (longId === undefined) return;

    if (longId === null) {
      setData(null);
      return;
    }

    const ref = firebase.database().ref(`flights`).child(longId);

    const callback = ref.on("value", (snapshot) => {
      // console.log("on: flight value");
      if (!snapshot.exists()) {
        setData(null);
        return;
      }
      const val = snapshot.val();
      setData(mapFlight(longId, val));
    });

    return () => {
      // console.log("off: flight value");
      ref.off("value", callback);
    };
  }, [longId]);
  return data;
}
