import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import SwipeableViews from "react-swipeable-views";

import Card from "../../../../shared/components/Card/Card";
import Alert from "../../../../shared/components/Alert";
import { User, FlightPassengerType, PatreonTier } from "../../../../types/all";
import FlightQueueFaq from "../FlightQueueFaq";
import RequireSignInDialog from "../RequireSignInDialog";
import { useDialog } from "../../../../hooks/useDialog";
import { Tabs } from "../../../../shared/components/Tabs";
import { Tab } from "../../../../shared/components/Tab";
import { TabPanel } from "../../../../shared/components/TabPanel";
import { QueueType } from "../../../../types/all";
import EconomyTab from "./EconomyTab";
import FirstClassTab from "./FirstClassTab";
import { FlightQueues } from "../../hooks/useQueues";
import FirstClassRuleDialog from "./FirstClassRuleDialog";
import useProfile from "../../../../hooks/useProfile";
import useFlightPassword from "../../../../hooks/useFlightPassword";
import { FlightApi } from "../../../../apis/flight";
import { useSnackbar } from "notistack";

export interface FlightQueueCardProps {
  flightCreatorId: string;
  flightMaxAllowed: number;
  flightQueues: FlightQueues;
  flightQueueLocked: boolean;
  flightClosed: boolean;
  flightPassengerType: FlightPassengerType;
  flightRequirePassword: boolean;
  user: User;
  isHost: boolean;
  isInQueue: boolean;
  shouldShowCode: boolean;

  openFAQDialog: () => void;
}

export default function FlightQueueCard({
  flightQueues,
  flightCreatorId,
  flightMaxAllowed,
  flightQueueLocked,
  flightClosed,
  flightPassengerType,
  flightRequirePassword,
  isHost,
  isInQueue,
  shouldShowCode,

  openFAQDialog,

  user,
}: FlightQueueCardProps) {
  const { t } = useTranslation("FlightQueueCard");
  const { enqueueSnackbar } = useSnackbar();

  const flightPassword = useFlightPassword(flightCreatorId, isHost);

  const currentUserProfile = useProfile(user ? user.uid : undefined);
  const currentUserTrusted =
    currentUserProfile?.verificationLevel !== undefined ||
    Object.values(PatreonTier)
      .map((i) => String(i))
      .includes(currentUserProfile?.patreon || "");

  const nonSignedInUserDialog = useDialog();
  const firstClassRuleDialog = useDialog();

  const [tab, setTab] = React.useState<QueueType>(QueueType.STANDARD);

  const { standard, firstClass } = flightQueues;
  const queueLengthTotal =
    standard && firstClass ? standard.length + firstClass.length : undefined;

  return (
    <Card variant="yellow">
      <FlightQueueFaq openFAQDialog={openFAQDialog} />
      <Box
        mb={2}
        fontWeight="fontWeightBold"
        color="text.primary"
        fontSize={16}
      >
        {flightQueueLocked && (
          <Icon
            className="fas fa-lock"
            style={{
              color: "inherit",
              marginRight: 4,
              fontSize: 12,
            }}
          />
        )}
        {flightRequirePassword && (
          <Icon
            className="fas fa-user-lock"
            style={{
              color: "inherit",
              marginRight: 4,
              fontSize: 12,
            }}
          />
        )}
        {t("queueTitleLabel", {
          length:
            queueLengthTotal !== undefined ? String(queueLengthTotal) : "-",
        })}
      </Box>
      {isHost && flightRequirePassword && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-end"
          mb={2}
        >
          <Box mr={1} color="text.primary" fontSize={10}>
            {t("flightPasswordDisplayLabel")}
          </Box>
          <Box fontSize={24} fontWeight="fontWeightBold" color="info.main">
            {flightPassword}
          </Box>
          <Box
            ml={1}
            onClick={() => {
              FlightApi.resetPassword(flightCreatorId)
                .then(() => {
                  enqueueSnackbar(t("success.general", { ns: "shared" }), {
                    variant: "success",
                  });
                })
                .catch((error: Error) => {
                  enqueueSnackbar(
                    t("error.general", { ns: "shared", error: error.message }),
                    {
                      variant: "error",
                    },
                  );
                });
            }}
            fontSize={10}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {t("resetPassword")}
          </Box>
        </Box>
      )}
      {isHost && flightRequirePassword && (
        <Alert style={{ marginBottom: 16 }}>
          {t("privateFlightAlertHost")}
        </Alert>
      )}
      {!isHost && flightRequirePassword && (
        <Alert style={{ marginBottom: 16 }}>{t("privateFlightAlert")}</Alert>
      )}
      {flightPassengerType === FlightPassengerType.TRUSTED &&
        !currentUserTrusted && (
          <Alert style={{ marginBottom: 16 }}>
            {t("trustedOnlyFlightAlert")}
          </Alert>
        )}
      {firstClass && firstClass.length > 0 && (
        <Alert style={{ marginBottom: 16 }}>
          {t("firstClassIsActive")}{" "}
          <Box
            onClick={firstClassRuleDialog.open}
            style={{
              textDecoration: "underline",
              fontSize: "0.8em",
              cursor: "pointer",
              display: "inline-flex",
            }}
          >
            {t("explainIt")}
          </Box>
        </Alert>
      )}
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e: any, newValue: QueueType) => {
          setTab(newValue);
        }}
        style={{ marginBottom: 16, width: "100%" }}
      >
        <Tab
          // @ts-ignore
          label={t("queueTitleLabelStandard", {
            length: standard ? String(standard.length) : "-",
          })}
          value={QueueType.STANDARD}
        />
        <Tab
          // @ts-ignore
          label={t("queueTitleLabelFirstClass", {
            length: firstClass ? String(firstClass.length) : "-",
          })}
          value={QueueType.FIRST_CLASS}
        />
      </Tabs>

      <SwipeableViews
        index={tab === QueueType.STANDARD ? 0 : 1}
        onChangeIndex={(index) => {
          setTab(index === 0 ? QueueType.STANDARD : QueueType.FIRST_CLASS);
        }}
        style={{ width: "100%" }}
      >
        <TabPanel value={tab} index={QueueType.STANDARD}>
          <EconomyTab
            queue={standard}
            flightClosed={flightClosed}
            flightCreatorId={flightCreatorId}
            flightMaxAllowed={
              !firstClass || firstClass.length === 0
                ? flightMaxAllowed
                : flightMaxAllowed - 1
            }
            flightQueueLocked={flightQueueLocked}
            flightPassengerType={flightPassengerType}
            flightRequirePassword={flightRequirePassword}
            isInQueue={isInQueue}
            shouldShowCode={shouldShowCode}
            isHost={isHost}
            user={user}
            currentUserTrusted={currentUserTrusted}
            openNonSignedInUserDialog={nonSignedInUserDialog.open}
          />
        </TabPanel>
        <TabPanel value={tab} index={QueueType.FIRST_CLASS}>
          <FirstClassTab
            queue={firstClass}
            flightClosed={flightClosed}
            flightCreatorId={flightCreatorId}
            flightMaxAllowed={flightMaxAllowed}
            flightQueueLocked={flightQueueLocked}
            flightRequirePassword={flightRequirePassword}
            isInQueue={isInQueue}
            shouldShowCode={shouldShowCode}
            isHost={isHost}
            user={user}
            openNonSignedInUserDialog={nonSignedInUserDialog.open}
            openFirstClassRuleDialog={firstClassRuleDialog.open}
          />
        </TabPanel>
      </SwipeableViews>
      <RequireSignInDialog
        close={nonSignedInUserDialog.close}
        isOpen={nonSignedInUserDialog.isOpen}
        // disabled={submitting}
      />
      <FirstClassRuleDialog
        close={firstClassRuleDialog.close}
        isOpen={firstClassRuleDialog.isOpen}
      />
    </Card>
  );
}
