import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { useTranslation } from "react-i18next";
import Button from "../../../shared/components/Button/Button";
import { useDialog } from "../../../hooks/useDialog";
import UserContext from "../../../shared/UserContext";
import { ReportApi } from "../../../api";
import { useSnackbar } from "notistack";
import useReport from "../../../hooks/useReport";
import TextField from "../../../shared/components/TextField/TextField";

function ReportForm({ flightCreatorId }: { flightCreatorId: string }) {
  const { t } = useTranslation("Flight");
  const { enqueueSnackbar } = useSnackbar();
  const [reportReason, setReportReason] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const validated = reportReason.length >= 20;

  return (
    <Box
      mt={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <TextField
        color="blue"
        // @ts-ignore
        variant="outlined"
        multiline
        rows={3}
        rowsMax={5}
        label={t("reportDialog.reportReasonLabel")}
        placeholder={t("reportDialog.reportReasonPlaceholder")}
        style={{ width: "100%", maxWidth: 300 }}
        value={reportReason}
        onChange={(e: any) => {
          setReportReason(e.target.value);
        }}
        InputLabelProps={{ shrink: true }}
        helperText={t("reportDialog.reportReasonHelpText")}
        error={!validated}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        mt={2}
      >
        <Button
          variant="blue"
          onClick={() => {
            setSubmitting(true);
            ReportApi.report(flightCreatorId, reportReason)
              .then(() => {
                enqueueSnackbar(
                  t("success.general", {
                    ns: "shared",
                  }),
                  {
                    variant: "success",
                  },
                );
                setSubmitting(false);
              })
              .catch((error: Error) => {
                setSubmitting(false);
                enqueueSnackbar(
                  t("error.general", { message: error.message, ns: "shared" }),
                  {
                    variant: "error",
                  },
                );
              });
          }}
          disabled={submitting || !validated}
        >
          {submitting ? t("reportDialog.reporting") : t("reportDialog.submit")}
        </Button>
      </Box>
    </Box>
  );
}

function Reported() {
  const { t } = useTranslation("Flight");

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box>{t("reportDialog.reported")}</Box>
    </Box>
  );
}

function Rules({ count }: { count: number }) {
  const { t } = useTranslation("Flight");

  return (
    <React.Fragment>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<Icon className="fas fa-angle-down" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box>{t("reportDialog.desc1", { reportCount: count })}</Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box display="flex" flexDirection="column">
            <Box>
              {t("reportDialog.desc2")}
              <br />
              {t("reportDialog.desc3")}
            </Box>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<Icon className="fas fa-angle-down" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box>Report immediately if:</Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box display="flex" flexDirection="column">
            <Box>
              1. the Host has{" "}
              <Box component="span" fontWeight="fontWeightBold">
                taken your entry fee but kicked you
              </Box>{" "}
              out before you can finish your trip.
              <br />
              2. the Host has posted{" "}
              <Box component="span" fontWeight="fontWeightBold">
                abusive or offensive messages
              </Box>
              . We do not tolerate any forms of hate speech.
            </Box>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary
          expandIcon={<Icon className="fas fa-angle-down" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box>DO NOT report if:</Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box>
            1.{" "}
            <Box component="span" fontWeight="fontWeightBold">
              the Host has to end flight early because of real life reasons
            </Box>
            . We understand it can be frustrating for visitors who've waited
            long in the queue. But please be considerate for the Host as well.
            We'd love to enjoy the game, but real life is most important.
            <br />
            2.{" "}
            <Box component="span" fontWeight="fontWeightBold">
              too many network issues
            </Box>
            . It is not anyone's fault if the technoloy doesn't work from time
            to time. Please be kind and help each other. We are sure Hosts are
            as disturbed as you are and are trying their best to get things
            going.
            <br />
            3.{" "}
            <Box component="span" fontWeight="fontWeightBold">
              high entry fee
            </Box>
            . We don't encourage high entry fee but we do understand that some
            Hosts would like to get more rewards for their time and efforts
            spent on helping us. Please do respect Hosts' rules and any fees
            they require. If you would like to stop the trend of high entry fee,
            you can do that by posting a friendly message and leaving and
            joining another flight with acceptable fees.
            <br />
            <br />
            Note that accounts that have submitted malicious reports might be
            banned temporarily or permanantly.
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
}

export interface FlightReportProps {
  flightCreatorId: string;
}

export default function FlightReport({ flightCreatorId }: FlightReportProps) {
  const { t } = useTranslation("Flight");
  const dialog = useDialog();
  const user = React.useContext(UserContext);
  const { reports, reportedByCurrentUser } = useReport(user, flightCreatorId);
  const reportCount = reports.length;

  if (!user) {
    return null;
  }

  return (
    <React.Fragment>
      <Box
        position="absolute"
        top={32}
        right={32}
        onClick={dialog.open}
        color="text.primary"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: "pointer", fontSize: 12 }}
      >
        <Box style={{ textDecoration: "underline" }}>
          Report{reportCount >= 12 ? ` (${reportCount})` : ""}
        </Box>
      </Box>
      <Dialog
        open={dialog.isOpen}
        onClose={dialog.close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("reportDialog.title")}
        </DialogTitle>
        <DialogContent>
          <Rules count={reportCount} />

          <Box
            mt={4}
            mb={4}
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
          >
            {reportedByCurrentUser ? (
              <Reported />
            ) : (
              <ReportForm flightCreatorId={flightCreatorId} />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
