import { useTranslation } from "react-i18next";
import React from "react";
import * as R from "ramda";

import firebase from "../shared/firebase";
import { Region } from "../types/data";

export interface UpdateProfileArgs {
  user: firebase.User;
  displayName?: string;
  profilePhoto?: string;
  islandName?: string;
  nsId?: string;
  dreamAddress?: string;
  region?: Region;
  message?: string;
}

// TODO: SECURE ME! PURIFY TEXT! ADD PROFILE CARD IN FLIGHT!
export default function useUpdateProfile() {
  const { t } = useTranslation();
  const updateProfile = React.useCallback(
    ({
      user,
      displayName,
      profilePhoto,
      islandName,
      nsId,
      region,
      message,
      dreamAddress,
    }: UpdateProfileArgs) => {
      return new Promise((resolve, reject) => {
        if (islandName && islandName.length > 10) {
          return reject(new Error(t("error.islandNameMax", { ns: "shared" })));
        }

        if (displayName && displayName.length > 10) {
          return reject(new Error(t("error.hostNameMax", { ns: "shared" })));
        }

        const userRef = firebase.firestore().collection("users").doc(user.uid);

        // TODO: refactor, prevent potential duplicate entries
        userRef
          .get()
          .then((snapshot) => {
            const newData = R.reject(R.isNil)({
              displayName,
              profilePhoto,
              islandName,
              nsId,
              region,
              message,
              dreamAddress,
            });

            setTimeout(() => {
              firebase.analytics().logEvent("Update Profile", {
                iid: user.uid,
                data: newData,
              });
            });

            if (!snapshot.exists) {
              return userRef.set(newData).then(resolve).catch(reject);
            }
            return userRef.update(newData).then(resolve).catch(reject);
          })
          .catch(reject);
      });
    },
    [t],
  );

  return updateProfile;
}
