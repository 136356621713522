import React from "react";
import Card from "../../../../shared/components/Card/Card";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "../../../../shared/components/TextField/TextField";
import { useTranslation } from "react-i18next";
import MuiButton from "@material-ui/core/Button";
import useFlightChat from "../../../../hooks/useFlightChat";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { ChatApi } from "../../../../api";
import { User } from "../../../../types/data";
import useProfileWithDefaultValue from "../../../../hooks/useProfileWithDefaultValue";
import { Link } from "react-router-dom";
import { useDialog } from "../../../../hooks/useDialog";
import RequireSignInDialog from "../RequireSignInDialog";
import { useSnackbar } from "notistack";
import useProfile from "../../../../hooks/useProfile";
import UserMessage from "./UserMessage";
import SystemMessage from "./SystemMessage";

export interface ChatProps {
  hostId: string;
  user: User;
}

function validate(content: string) {
  return !!content.trim();
}

export default function Chat({ hostId, user }: ChatProps) {
  const { t } = useTranslation("Chat");
  const { enqueueSnackbar } = useSnackbar();
  const chatListRef = React.createRef<HTMLDivElement>();
  const chats = useFlightChat(hostId);
  const inputRef = React.useRef<HTMLInputElement>();

  const flightCreatorProfile = useProfileWithDefaultValue(hostId);
  const currentUserProfile = useProfile(user ? user.uid : undefined);

  const [submitting, setSubmitting] = React.useState(false);
  const chatTextRef = React.useRef("");
  const chatingSpeedRef = React.useRef({
    count: 0,
    from: Date.now(),
  });

  const profileVerified =
    currentUserProfile && currentUserProfile.verificationLevel !== undefined;
  const currentUserDisplayName =
    currentUserProfile && currentUserProfile.displayName;

  const send = React.useCallback(
    (hostId: string, content: string) => {
      if (!validate(content)) return;

      if (
        !profileVerified &&
        Date.now() - chatingSpeedRef.current.from < 30000 &&
        chatingSpeedRef.current.count >= 1
      ) {
        enqueueSnackbar(t("error.tooFrequent"), {
          variant: "error",
        });
        return;
      }

      if (chatingSpeedRef.current.count >= 1) {
        chatingSpeedRef.current.count = 0;
        chatingSpeedRef.current.from = Date.now();
      } else {
        chatingSpeedRef.current.count += 1;
      }

      setSubmitting(true);
      ChatApi.send(hostId, content)
        .then(() => {
          setSubmitting(false);
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          chatTextRef.current = "";
        })
        .catch((error) => {
          enqueueSnackbar(
            t("error.general", { ns: "shared", error: error.message }),
            {
              variant: "error",
            },
          );
          setSubmitting(false);
        });
    },
    [enqueueSnackbar, t, profileVerified],
  );
  const nonSignedInUserDialog = useDialog();

  const currentUserId = user ? user.uid : undefined;
  const profile = useProfileWithDefaultValue(currentUserId);

  React.useEffect(() => {
    if (chatListRef.current !== null) {
      const height = parseInt(
        window.getComputedStyle(chatListRef.current).height.split("px")[0],
        10,
      );
      if (
        chatListRef.current.scrollTop >=
        chatListRef.current.scrollHeight - height - 60
      ) {
        chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
      }

      if (chatListRef.current.scrollTop === 0) {
        chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
      }
    }
  }, [chatListRef, chats]);

  return (
    <Card variant="yellow">
      <Box
        mb={2}
        textAlign="center"
        fontWeight="fontWeightBold"
        fontSize={16}
        color="text.primary"
      >
        Chat
      </Box>
      {chats === undefined ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          my={3}
        >
          <CircularProgress variant="blue" />
        </Box>
      ) : (
        <React.Fragment>
          <Box
            // @ts-ignore
            ref={chatListRef}
            width="100%"
            height={300}
            mb={2}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              whiteSpace: "break-spaces",
            }}
          >
            {chats.length === 0 && (
              <Box
                width="100%"
                textAlign="center"
                color="text.primary"
                style={{ opacity: 0.6 }}
              >
                {t("emptyList")}
              </Box>
            )}
            {chats.map((chat) => {
              if (chat.uid === "SYSTEM") {
                return <SystemMessage key={chat.id} chat={chat} />;
              }

              return (
                <UserMessage
                  key={chat.id}
                  chat={chat}
                  hostProfile={flightCreatorProfile}
                  hostId={hostId}
                  user={user}
                  currentUserDisplayName={currentUserDisplayName}
                />
              );
            })}
          </Box>
          {user && profile && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
              fontSize={10}
              color="text.primary"
              py={1}
            >
              <Box mr={2}>
                {t("yourDisplayNameIs", { displayName: profile.displayName })}
              </Box>
              <Box
                component={Link}
                // @ts-ignore
                to="/passport"
                color="text.primary"
              >
                {t("updateDisplayName")}
              </Box>
            </Box>
          )}
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              color="brown"
              // @ts-ignore
              variant="outlined"
              multiline
              rowsMax={2}
              placeholder={t("messagePlaceholder")}
              style={{ width: "100%", flex: 1 }}
              defaultValue={chatTextRef.current}
              onChange={(e: any) => {
                chatTextRef.current = e.target.value;
              }}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ref: (node: any) => {
                  inputRef.current = node;
                },
              }}
            />
            <MuiButton
              disabled={submitting}
              onClick={() => {
                if (!currentUserId) {
                  nonSignedInUserDialog.open();
                  return;
                }
                send(hostId, chatTextRef.current);
              }}
            >
              {submitting ? t("submitting") : t("submit")}
            </MuiButton>
          </Box>
          {!profileVerified && (
            <Box
              width="100%"
              mt={2}
              mb={1}
              fontSize={10}
              textAlign="left"
              color="error.main"
              fontWeight="fontWeightBold"
            >
              {t("chatTipVerification")}
            </Box>
          )}
          <Box
            width="100%"
            mt={profileVerified ? 2 : 0}
            fontSize={10}
            textAlign="left"
          >
            {t("chatTip1")}
          </Box>
          <Box width="100%" fontSize={10} textAlign="left">
            {t("chatTip2")}
          </Box>
          <Box mb={2} width="100%" fontSize={10} textAlign="left">
            {t("chatTip3")}
          </Box>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<Icon className="fas fa-angle-down" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box>Community Standards:</Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box display="flex" flexDirection="column">
                <Box>
                  1. Please keep the conversation relevant to your trips.
                  <br />
                  2. Different opinions are understandable, but personal attacks
                  (against authors or other users), persistent trolling and
                  mindless abuse will not be tolerated.
                  <br />
                  3. We will not tolerate racism, sexism, homophobia or other
                  forms of{" "}
                  <Box component="span" fontWeight="fontWeightBold">
                    hate-speech
                  </Box>
                  , or contributions that could be interpreted as such.
                  <br />
                  4. We will remove any account for violating the above zero
                  tolerance standards and for any other reasons we believe
                  valid. If you want to report an user, please copy the user's
                  passport link and DM @TurnipsExchange on Twitter.
                </Box>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </React.Fragment>
      )}
      <RequireSignInDialog
        close={nonSignedInUserDialog.close}
        isOpen={nonSignedInUserDialog.isOpen}
        disabled={submitting}
      />
    </Card>
  );
}
//TODO !
