import React from "react";
import Box from "@material-ui/core/Box";
import { CatalogItem as CatalogItemType } from "../../../types/data";
import Tooltip from "@material-ui/core/Tooltip";

export interface CatalogItemProps {
  data: CatalogItemType;
  showName?: boolean;
}

export default function CatalogItem({
  data,
  showName = false,
}: CatalogItemProps) {
  return (
    <Tooltip
      title={data.name}
      aria-label={data.name}
      disableFocusListener
      disableTouchListener
    >
      <Box
        px={0.5}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          width={24}
          height={24}
          // @ts-ignore
          src={data.imgUrl}
          style={{ objectFit: "contain" }}
        ></Box>
        {showName && <Box>{data.name}</Box>}
      </Box>
    </Tooltip>
  );
}
