import { PriceRecord, PriceRecordFirebase, Period } from "./../types/data";
import React from "react";
import firebase from "../shared/firebase";
import getHours from "date-fns/fp/getHours";
import isBefore from "date-fns/fp/isBefore";
import { getNWeeksAgo } from "../shared/utils";

export default function useMyTracking(user: firebase.User | undefined | null) {
  const [list, setList] = React.useState<PriceRecord[] | undefined>(undefined);

  React.useEffect(() => {
    if (!user) return;

    firebase
      .firestore()
      .collection("prices")
      .where("uid", "==", user.uid)
      .where("date", ">=", getNWeeksAgo(2))
      .orderBy("date", "desc")
      .get()
      .then((snapshot) => {
        setList(
          snapshot.docs
            .map<PriceRecord>((doc) => {
              const data = doc.data() as PriceRecordFirebase;
              const { date, price, createdAt, uid } = data;
              return {
                price,
                date: date.toDate(),
                period:
                  getHours(date.toDate()) >= 12
                    ? Period.AFTERNOON
                    : Period.MORNING,
                createdAt: createdAt.toDate(),
                island: uid,
              };
            })
            .sort((a, b) => (isBefore(b.date)(a.date) ? 1 : -1)),
        );
      })
      .catch(console.error);
  }, [user]);

  return list;
}
