import getWeek from "date-fns/getWeek";
import getYear from "date-fns/getYear";
import React from "react";

import firebase from "../shared/firebase";

export interface UpdateBuyinArgs {
  user: firebase.User;
  price?: number;
  count?: number;
  year?: number;
  week?: number;
}

export default function useUpdateBuyin() {
  const updateBuyin = React.useCallback(
    ({ user, price, count, year, week }: UpdateBuyinArgs) => {
      if (!price && !count) {
        console.error("Missing arguments.");
        return;
      }

      const now = new Date();
      year = week === undefined ? getYear(now) : year;
      week = week === undefined ? getWeek(now) : week;

      const buyinRef = firebase.firestore().collection("buyin");

      // TODO: refactor, prevent potential duplicate entries
      buyinRef
        .where("uid", "==", user.uid)
        .where("year", "==", year)
        .where("week", "==", week)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            const data: any = {
              year,
              week,
              uid: user.uid,
            };

            if (price !== undefined) {
              data.price = price;
            }

            if (count !== undefined) {
              data.count = count;
            }

            buyinRef.add(data);
            return;
          }

          const data: any = {};

          if (price !== undefined) {
            data.price = price;
          }

          if (count !== undefined) {
            data.count = count;
          }

          buyinRef.doc(snapshot.docs[0].id).update(data);

          setTimeout(() => {
            firebase.analytics().logEvent("Update Buyin", {
              id: snapshot.docs[0].id,
              data,
            });
          });
        });
    },
    [],
  );

  return updateBuyin;
}
