import { Flight } from "../types/data";
import React from "react";
import firebase from "../shared/firebase";
import { mapFlight } from "./useFlight";

export default function useOpenFlights() {
  const [list, setList] = React.useState<Flight[] | undefined>(undefined);

  React.useEffect(() => {
    const ref = firebase
      .database()
      .ref("flights")
      .orderByChild("status")
      .equalTo("open");
    const callback = ref.on(
      "value",
      (snapshot) => {
        // console.log("on: open flights");
        if (!snapshot.exists()) {
          setList([]);
          return;
        }

        const flightsData = snapshot.val();

        // console.log(flightsData);

        const newList: Flight[] = [];

        Object.keys(flightsData).forEach((flightId) => {
          const mappedFlight = mapFlight(flightId, flightsData[flightId]);
          if (mappedFlight) {
            newList.push(mappedFlight);
          }
        });

        setList(
          newList.sort((a, b) => {
            if (!a.price) {
              return 1;
            }

            if (!b.price) {
              return -1;
            }

            return a.price < b.price ? 1 : -1;
          }),
        );
      },
      console.error,
    );

    return () => {
      // console.log("off: open flights");
      ref.off("value", callback);
    };
  }, []);

  // console.log("[open flights]", list);

  return list;
}
