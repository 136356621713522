import React from "react";

export function useToggle(
  defaultState: boolean = false,
): [boolean, () => void] {
  const [state, setState] = React.useState(defaultState);

  const toggle = React.useCallback(() => {
    setState((old) => !old);
  }, []);

  return [state, toggle];
}
