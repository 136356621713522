import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";

import Box from "@material-ui/core/Box";

import useFlight from "../../hooks/useFlight";
import useFlightQueue from "../../hooks/useFlightQueue";
import Loader from "../../shared/components/Loader";
import Section from "../../shared/components/Section/Section";
import UserContext from "../../shared/UserContext";
import Chat from "./components/Chat/Chat";
import FlightActions from "./components/FlightActions";
import FlightAlerts from "./components/FlightAlerts";
import FlightInfoCard from "./components/FlightInfoCard";
import FlightInQueueInfo from "./components/FlightInQueueInfo";
import FlightNotFound from "./components/FlightNotFound";
import FlightQueueCard from "./components/FlightQueueCard/FlightQueueCard";
import FlightShare from "./components/FlightShare";
import * as utils from "./utils";
import FlightPatreon from "./components/FlightPatreon";
import useProfileWithDefaultValue from "../../hooks/useProfileWithDefaultValue";
import FlightStream from "./components/FlightStream";
import { useQueues } from "./hooks/useQueues";
import { FlightPassengerType } from "../../types/all";
import { useQueueAutoLock } from "../../hooks/useQueueAutoLock";

export interface FlightProps {
  openFAQDialog: () => void;
}

export default function FlightProps({ openFAQDialog }: FlightProps) {
  const { t } = useTranslation("Flight");
  const { id: flightId } = useParams<{ id: string }>();
  const user = React.useContext(UserContext);

  const flight = useFlight(flightId);
  const flightQueue = useFlightQueue(flight?.hostId);
  const flightCreatorProfile = useProfileWithDefaultValue(flight?.hostId);

  const queues = useQueues(flightQueue);

  useQueueAutoLock(user, flight, flightQueue);

  if (!flightId) {
    return <Redirect to="/airport" />;
  }

  if (flight === undefined || user === undefined) {
    return <Loader />;
  }

  if (flight === null) {
    return <FlightNotFound />;
  }

  const currentUserInfo = utils.getCurrentUserFlightInfo(flight, queues, user);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      style={{ filter: flight.status === "closed" ? "grayscale(1)" : "unset" }}
    >
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
      </Section>
      <FlightShare
        flight={flight}
        flightCreatorProfile={flightCreatorProfile}
      />
      <Section variant="blue">
        <FlightInfoCard
          flight={flight}
          flightCreatorProfile={flightCreatorProfile}
        />
        {flight.status === "closed" ? (
          <FlightActions
            isHost={currentUserInfo.isHost}
            isFlightOpen={false}
            planToCloseAt={flight.planToCloseAt}
          />
        ) : (
          <React.Fragment>
            {flightCreatorProfile && (
              <Box mt={4}>
                <FlightStream flightCreatorProfile={flightCreatorProfile} />
              </Box>
            )}
            <FlightActions
              isHost={currentUserInfo.isHost}
              isFlightOpen={true}
              planToCloseAt={flight.planToCloseAt}
            />
            <Box mt={2}>
              <FlightAlerts
                flightHostStatus={flight.hostStatus}
                isInQueue={currentUserInfo.isInQueue}
                isHost={currentUserInfo.isHost}
              />
            </Box>
            <Box mt={2}>
              <FlightInQueueInfo
                flightCreatorId={flight.hostId}
                lastChangedAt={flight.lastChangedAt}
                user={currentUserInfo.user}
                isInQueue={currentUserInfo.isInQueue}
                shouldShowCode={currentUserInfo.shouldShowCode}
                queuePosition={currentUserInfo.queuePosition}
                queueLength={currentUserInfo.queueLength}
                queueType={currentUserInfo.queueType}
              />
            </Box>
          </React.Fragment>
        )}
        <Box mt={2}>
          <FlightQueueCard
            flightCreatorId={flight.hostId}
            flightQueues={queues}
            flightQueueLocked={flight.queueLocked}
            flightClosed={flight.status === "closed"}
            flightPassengerType={
              flight.flightPassengerType || FlightPassengerType.ALL
            }
            flightRequirePassword={!!flight.requirePassword}
            user={currentUserInfo.user}
            isHost={currentUserInfo.isHost}
            isInQueue={currentUserInfo.isInQueue}
            shouldShowCode={currentUserInfo.shouldShowCode}
            flightMaxAllowed={flight.maxAllowed}
            openFAQDialog={openFAQDialog}
          />
        </Box>
        {flight.status !== "closed" && (
          <Box mt={2}>
            <FlightInQueueInfo
              flightCreatorId={flight.hostId}
              lastChangedAt={flight.lastChangedAt}
              user={currentUserInfo.user}
              isInQueue={currentUserInfo.isInQueue}
              shouldShowCode={currentUserInfo.shouldShowCode}
              queuePosition={currentUserInfo.queuePosition}
              queueLength={currentUserInfo.queueLength}
              queueType={currentUserInfo.queueType}
            />
          </Box>
        )}
        <Box mt={2}>
          <Chat hostId={flight.hostId} user={currentUserInfo.user} />
        </Box>
        <FlightPatreon />
      </Section>
    </Box>
  );
}
