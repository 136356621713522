import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiTab from "@material-ui/core/Tab";

export const Tab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.54)",
    minHeight: "unset !important",
    padding: "4px 0",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.54)",
      opacity: 1,
    },
    "&$selected": {
      color: "#6b5c43",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  selected: {},
}))((props) => <MuiTab {...props} />);
