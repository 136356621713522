import React from "react";
import firebase from "../shared/firebase";
import { CatalogItem } from "../types/data";

export function useCatalog() {
  const [state, setState] = React.useState<
    { [k: string]: Omit<CatalogItem, "id"> } | undefined
  >(undefined);

  React.useEffect(() => {
    firebase
      .storage()
      .ref("dataMap.json")
      .getDownloadURL()
      .then(function (url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            Object.keys(data).forEach((k) => {
              data[k].name = data[k].n;
              data[k].imgUrl = `https://acnhcdn.com/latest/${data[k].i}.png`;
              delete data[k].n;
              delete data[k].i;
            });
            setState(data);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return state;
}
