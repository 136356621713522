import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useFlightCode from "../../../hooks/useFlightCode";
import { User } from "../../../types/data";
import Helmet from "react-helmet";
import { QueueType } from "../../../types/all";

export interface FlightInQUeueInfoProps {
  flightCreatorId: string;
  lastChangedAt: number;
  isInQueue: boolean;
  shouldShowCode: boolean;
  queuePosition: number | string | undefined;
  queueLength: number | string | undefined;
  queueType: QueueType | undefined;

  user: User;
}

export default function FlightInQUeueInfo({
  flightCreatorId,
  lastChangedAt,
  isInQueue,
  shouldShowCode,
  queuePosition = "-",
  queueLength = "-",
  queueType,
  user,
}: FlightInQUeueInfoProps) {
  const { t } = useTranslation("Flight");

  const code = useFlightCode(
    user,
    flightCreatorId,
    shouldShowCode,
    lastChangedAt,
  );

  if (!isInQueue) {
    return null;
  }

  if (shouldShowCode && code) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        color="info.main"
      >
        <Helmet>
          <title>{t("pageTitleGotCode")}</title>
        </Helmet>
        <Box mr={2} component="span" fontSize={20} fontWeight="fontWeightBold">
          {t("dodoCode")}
        </Box>
        <Box component="span" fontSize={30} fontWeight="fontWeightBold">
          {code.toUpperCase()}
        </Box>
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="info.main"
    >
      <Helmet>
        <title>
          {t("pageTitleInQueue", {
            total: queueLength,
            position: queuePosition,
            queueType: t(queueType!),
          })}
        </title>
      </Helmet>
      <Box component="span" fontSize={20} fontWeight="fontWeightBold">
        {t("placeInQueue", {
          total: queueLength,
          position: queuePosition,
          queueType: t(queueType!),
        })}
      </Box>
      <Box component="span" fontSize={16} fontWeight="fontWeightBold">
        {t("dodoCodeWillShowHere")}
      </Box>
    </Box>
  );
}
