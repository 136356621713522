import { ChatApi } from "./../api";
import { Chat } from "./../types/data";
import React from "react";
import isBefore from "date-fns/isBefore";
import takeRight from "lodash.takeright";
import subHours from "date-fns/subHours";

export interface ChatRaw {
  id: string;
  uid: string;
  content: string;
  createdAt: string | number;
}

function mapChat(id: string, raw: ChatRaw): Chat {
  const { uid, content, createdAt } = raw;
  return {
    id,
    uid,
    content,
    createdAt: new Date(createdAt),
  };
}

export default function useFlightChat(flightId: string) {
  const [state, setState] = React.useState<Chat[] | undefined>(undefined);

  React.useEffect(() => {
    if (!flightId) {
      return;
    }

    let cleanup: () => void;

    ChatApi.ref(flightId)
      .orderByChild("createdAt")
      .startAt(subHours(new Date(), 1).getTime()) // Hide chats before yesterday
      .limitToLast(24)
      .once("value", (snapshot) => {
        let lastMessage: undefined | ChatRaw;
        if (!snapshot.exists()) {
          setState([]);
        } else {
          const val = snapshot.val();
          const chatKeys = Object.keys(val);
          const list = chatKeys
            .map((key) => mapChat(key, val[key]))
            .sort((a, b) => (isBefore(a.createdAt, b.createdAt) ? -1 : 1));
          setState(list);
          lastMessage = {
            id: list[list.length - 1].id,
            ...val[list[list.length - 1].id],
          };
        }

        const startAt = lastMessage
          ? new Date(lastMessage.createdAt).getTime() + 1000
          : Date.now();

        const ref = ChatApi.ref(flightId)
          .orderByChild("createdAt")
          .startAt(startAt);
        const callback = ref.on("child_added", (snapshot) => {
          // console.log("on: chat child_added");
          if (lastMessage && snapshot.key === lastMessage.id) {
            return;
          }
          const newMsg = mapChat(snapshot.key!, snapshot.val());
          setState((old) => {
            if (old) {
              return takeRight([...old, newMsg], 100);
            }
            return [newMsg];
          });
        });

        cleanup = () => {
          // console.log("off: chat child_added");
          ref.off("child_added", callback);
        };
        return;
      });

    return () => {
      if (cleanup) {
        cleanup();
      }
    };
  }, [flightId]);

  return state;
}
