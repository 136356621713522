import { useTranslation } from "react-i18next";
import React from "react";

import firebase from "../shared/firebase";
import { Region, ProfileForm, PatreonTier } from "../types/data";

export default function useProfileWatch(uid: string | undefined) {
  const [data, setData] = React.useState<ProfileForm | undefined>(undefined);
  const { t } = useTranslation("shared");

  React.useEffect(() => {
    if (!uid) return;

    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .onSnapshot((snapshot) => {
        setData({
          id: snapshot.get("id") || undefined,
          displayName:
            snapshot.get("displayNameVerified") ||
            snapshot.get("displayName") ||
            undefined,
          profilePhoto: snapshot.get("profilePhoto") || undefined,
          islandName:
            snapshot.get("islandNameVerified") ||
            snapshot.get("islandName") ||
            undefined,
          nsId: snapshot.get("nsId") || undefined,
          region: Object.values(Region).includes(snapshot.get("region"))
            ? (snapshot.get("region") as Region)
            : undefined,
          message: snapshot.get("message") || undefined,
          patreon: Object.values(PatreonTier).includes(snapshot.get("patreon"))
            ? (snapshot.get("patreon") as PatreonTier)
            : undefined,
          verificationLevel: snapshot.get("verificationLevel") || undefined,
          mixer: snapshot.get("mixer") || undefined,
          twitch: snapshot.get("twitch") || undefined,
        });
      });

    return () => {
      unsubscribe();
    };
  }, [uid, setData, t]);

  return data;
}
