import shortid from "shortid";
import React from "react";
import firebase from "../shared/firebase";
import { User } from "../types/data";

export default function useUserPresenseSync(user: User) {
  const uuidRef = React.useRef<string>(shortid.generate());
  React.useEffect(() => {
    if (!user) {
      return;
    }

    // Fetch the current user's ID from Firebase Authentication.
    const uid = user.uid;

    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
    var userStatusDatabaseRef = firebase.database().ref("/status/" + uid);
    const userStatusLocationRef = userStatusDatabaseRef
      .child("location")
      .child(uuidRef.current);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
    var isOfflineForDatabase = {
      lastChanged: firebase.database.ServerValue.TIMESTAMP,
    };

    var isOnlineForDatabase = {
      lastChanged: firebase.database.ServerValue.TIMESTAMP,
    };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
    firebase
      .database()
      .ref(".info/connected")
      .on("value", function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) {
          return;
        }

        // If we are currently connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once this
        // client has disconnected by closing the app,
        // losing internet, or any other means.
        userStatusDatabaseRef
          .onDisconnect()
          .update(isOfflineForDatabase)
          .then(() => {
            // nothing
          });
        userStatusLocationRef
          .onDisconnect()
          .remove()
          .then(function () {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.update(isOnlineForDatabase);
            userStatusLocationRef.set(true);
          });
      });
  }, [user]);
}
