import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { PERIOD_LABEL } from "../../../../shared/constants";
import { PriceRecord } from "../../../../types/data";
import IslandLink from "../../../../shared/components/IslandLink/IslandLink";
import RegionLabel from "../../../../shared/components/RegionLabel/RegionLabel";

export interface PriceListProps {
  data: PriceRecord[];
}

const PriceList: React.FC<PriceListProps> = ({ data }) => {
  const { t } = useTranslation("PriceList");

  return (
    <Box
      component={List}
      style={{
        width: "100%",
        maxWidth: 520,
        maxHeight: 520,
        overflowY: "auto",
      }}
    >
      <Box
        component={ListItem}
        style={{
          justifyContent: "space-between",
          width: "100%",
          fontWeight: 600,
        }}
      >
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.region")}
        </Box>
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.period")}
        </Box>
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.price")}
        </Box>
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.island")}
        </Box>
      </Box>
      {data.length === 0 && (
        <Box
          mt={2}
          width="100%"
          textAlign="center"
          color="text.primary"
          style={{ opacity: 0.6 }}
        >
          {t("emptyList")}
        </Box>
      )}
      {data.map(({ price, region, period, island }, index) => (
        <Box
          key={index}
          component={ListItem}
          style={{
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: index % 2 ? undefined : "rgba(104,161,215,0.2)",
          }}
        >
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            <RegionLabel uid={island} />
          </Box>
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            {PERIOD_LABEL[period]}
          </Box>
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            {price}
          </Box>
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            <IslandLink uid={island} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PriceList;
