import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import Section from "../../shared/components/Section/Section";
import { useTranslation } from "react-i18next";

export default function UnderConstruction() {
  const { t } = useTranslation("UnderConstruction");
  let history = useHistory();

  const goToHome = () => {
    history.push("/");
  };

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown">
        <Card variant="yellow">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box color="rgba(241, 226, 111, 0.8)">
              <Icon className="fas fa-tools" fontSize="large" />
            </Box>
            <Box component="h2" color="text.primary">
              {t("sec1.title")}
            </Box>
            <Box textAlign="center" color="text.primary">
              {t("sec1.desc")}
            </Box>
          </Box>
          <Button
            variant="brown"
            icon={
              <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
            }
            onClick={goToHome}
          >
            {t("sec1.cta")}
          </Button>
        </Card>
      </Section>
    </Box>
  );
}
