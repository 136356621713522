import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { Flight } from "../../../types/data";
import DepartureListGeneralItem from "./DepartureListGeneralItem";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

export interface DepartureListGeneralProps {
  data: Flight[] | undefined;
}

const DepartureListGeneral: React.FC<DepartureListGeneralProps> = ({
  data: flights,
}) => {
  const { t } = useTranslation("DepartureListGeneral");

  if (flights === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="blue" />
      </Box>
    );
  }

  return (
    <Box
      component={List}
      style={{
        width: "100%",
        maxWidth: 520,
        maxHeight: 520,
        overflowY: "auto",
      }}
    >
      <Box
        component={ListItem}
        style={{
          justifyContent: "space-between",
          width: "100%",
          fontWeight: 600,
        }}
      >
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.island")}
        </Box>
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.hostName")}
        </Box>
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.queue")}
        </Box>
        <Box flexGrow={1} flexShrink={0} flexBasis="25%" textAlign="center">
          {t("header.action")}
        </Box>
      </Box>
      {flights.length === 0 && (
        <Box
          mt={2}
          width="100%"
          textAlign="center"
          color="text.primary"
          style={{ opacity: 0.6 }}
        >
          {t("emptyList")}
        </Box>
      )}
      {flights.map((item, index) => (
        <DepartureListGeneralItem key={item.hostId} data={item} index={index} />
      ))}
    </Box>
  );
};

export default DepartureListGeneral;
