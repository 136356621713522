import { FlightPassengerType } from "./../types/all";
import React from "react";
import firebase from "../shared/firebase";
import {
  Terminal,
  TurnipsFlightType,
  FlightPublic,
  Flight,
} from "../types/all";

export interface FlightWithId extends Flight {
  id: string;
}

// Note: only use this for flight host
export default function useUserFlight(flightCreatorId: string | undefined) {
  const [flight, setFlight] = React.useState<FlightWithId | null | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (!flightCreatorId) {
      setFlight(undefined);
      return;
    }
    const publicRef = firebase.database().ref(`/flights/${flightCreatorId}`);
    const publicCallback = publicRef.on(
      "value",
      (snapshot) => {
        if (!snapshot.exists()) {
          setFlight(null);
          return;
        }

        const {
          maxAllowed,
          message,
          planToCloseAt,
          maxStayTime,
          maxQueueSize,

          terminal = Terminal.TURNIPS,
          price,
          turnipsFlightType = TurnipsFlightType.SELLING,
          specialCharacter,

          flightPassengerType = FlightPassengerType.ALL,
          requirePassword = false,

          status = "closed",
          lastChangedAt,
        } = snapshot.val();

        const flightPublic: FlightPublic = {
          maxAllowed,
          message,
          planToCloseAt,
          maxStayTime,
          maxQueueSize,
          terminal,
          price,
          turnipsFlightType,
          specialCharacter,
          flightPassengerType,
          requirePassword,
          status,
          lastChangedAt,
        };

        setFlight((prev) => {
          if (!prev) {
            return {
              ...flightPublic,
              id: snapshot.key!,
            };
          }

          return {
            ...prev,
            ...flightPublic,
            id: snapshot.key!,
          };
        });
      },
      (error: any) => {
        console.error(error);
        setFlight(null);
      },
    );

    const privateRef = firebase
      .database()
      .ref(`/flightPrivate/${flightCreatorId}`);
    const privateCallback = privateRef.on(
      "value",
      (snapshot) => {
        if (!snapshot.exists()) {
          return;
        }

        const { code = "", password } = snapshot.val();

        setFlight((prev) => {
          if (prev === null) {
            return null;
          }

          if (prev === undefined) {
            return {
              id: snapshot.key!,
              status: "closed",
              code,
              password,
            };
          }

          return {
            ...prev,
            code,
            password,
          };
        });
      },
      (error: any) => {
        console.error(error);
      },
    );

    return () => {
      publicRef.off("value", publicCallback);
      privateRef.off("value", privateCallback);
    };
  }, [flightCreatorId]);

  return flight;
}
