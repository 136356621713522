import { makeStyles } from "@material-ui/core";
import { TextFieldProps } from "./TextField.types";

const COLOR: { [k in TextFieldProps["color"]]: string | undefined } = {
  blue: "rgba(104,161,215,1) !important",
  cyan: "rgba(0, 125, 117, 1) !important",
  mint: "rgba(0,129,96,1) !important",
  yellow: "rgba(241,226,111,1) !important",
  brown: "rgb(107, 92, 67) !important",
};

export const useStyles = makeStyles((theme) => ({
  root: ({ color }: TextFieldProps) => ({
    "& .MuiFormLabel-root:not(.Mui-error)": {
      color: COLOR[color],
      "& ~ .MuiFormHelperText-root": {
        color: COLOR[color],
      },
    },
    "& .MuiInputBase-root:not(.Mui-error)": {
      "& .MuiInputBase-input": {
        color: COLOR[color],
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: COLOR[color],
      },
    },
  }),
}));
