import React from "react";
import useProfile from "../../../hooks/useProfile";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { REGION_LABEL } from "../../constants";

export interface RegionLabelProps {
  uid: string | undefined;
}

export default function RegionLabel({ uid }: RegionLabelProps) {
  const profile = useProfile(uid);
  const { t } = useTranslation("RegionLabel");

  return (
    <Box display="inline-block">
      {profile?.region ? REGION_LABEL[profile?.region] : t("default")}
    </Box>
  );
}
