import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Card from "../../shared/components/Card/Card";
import Box from "@material-ui/core/Box";
import Button from "../../shared/components/Button/Button";
import Icon from "@material-ui/core/Icon";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface FeaturedSreamer {
  id: string;
  image: string;
  desc: string;
  width: number;
  top: number;
  textOffsetLeft: number;
}

export const FEATURED_STREAMERS: FeaturedSreamer[] = [
  {
    id: "https://www.twitch.tv/zur2gaming",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2FZurdou.png?alt=media&token=7fba2832-ca43-41bd-b468-d03ffdaaf187",
    desc:
      "Zurdou is a dedicated ACNH streamer and has been constantly offering high Turnip prices and Bells giveaways. DO NOT miss them!",
    width: 100,
    top: 8,
    textOffsetLeft: 36,
  },
  {
    id: "https://www.twitch.tv/LaLadyPanda",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2FLLP.png?alt=media&token=6b2bde37-2680-4262-94cd-ffe5322eded6",
    desc:
      "LaLadyPanda is one of our best community members and a variety streamer with an obsession for Animal Crossing!",
    width: 80,
    top: 8,
    textOffsetLeft: 16,
  },
  {
    id: "https://www.twitch.tv/mybadac",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2FMyBad.png?alt=media&token=40ae1611-52ee-4ac5-9291-1d9ca2e2ec48",
    desc:
      "MyBad is a French and English bilingual streamer who loves to visit your island and build you moon items for free. Come chat with him now!",
    width: 80,
    top: 0,
    textOffsetLeft: 16,
  },
];

export default function FeaturedStreamers() {
  const [activeItem, setActiveItem] = React.useState(0);
  const handleItemChange = (item: number) => {
    setActiveItem(item);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        mb={1.5}
        color="text.primary"
        fontSize={16}
        fontWeight="fontWeightBold"
      >
        Featured Streamer
      </Box>
      <AutoPlaySwipeableViews
        axis="x"
        index={activeItem}
        onChangeIndex={handleItemChange}
        enableMouseEvents
        style={{ maxWidth: "100%" }}
      >
        {FEATURED_STREAMERS.map((item, index) => (
          <Box px={4} key={item.id}>
            {Math.abs(activeItem - index) <= 2 ? (
              <Card
                variant="yellow"
                innerContainerStyle={{ paddingBottom: 16 }}
              >
                <Box
                  component="img"
                  // @ts-ignore
                  src={item.image}
                  width={item.width}
                  position="absolute"
                  left={-32}
                  top={item.top}
                ></Box>
                <Box
                  color="text.primary"
                  fontSize={14}
                  pl={`${item.textOffsetLeft}px`}
                  mb={1}
                >
                  {item.desc}
                </Box>
                <Button
                  variant="brown"
                  icon={
                    <Icon
                      className="fas fa-chevron-right"
                      style={{ fontSize: 14 }}
                    />
                  }
                  onClick={() => {
                    window.open(item.id, "_blank");
                  }}
                >
                  Have a look!
                </Button>
              </Card>
            ) : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}
