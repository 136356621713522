import React from "react";

import MuiTextField from "@material-ui/core/TextField";

import { TextFieldProps } from "./TextField.types";
import { useStyles } from "./TextField.styles";

const TextField: React.FC<TextFieldProps> = ({ color, ...rest }: any) => {
  const classes = useStyles({ color });

  return <MuiTextField {...rest} classes={{ root: classes.root }} />;
};

export default TextField;
