import React from "react";

import MuiSelect from "@material-ui/core/Select";

import { SelectProps } from "./Select.types";
import { useStyles } from "./Select.styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const Select: React.FC<SelectProps> = ({
  color,
  style,
  helperText,
  error,
  ...rest
}: any) => {
  const classes = useStyles({ color });

  return (
    <FormControl variant="outlined" style={style}>
      <InputLabel
        id={rest.id}
        shrink
        {...{
          ...rest.InputLabelProps,
          classes: {
            root: error ? classes.labelError : classes.label,
            focused: error ? classes.labelError : classes.label,
          },
        }}
      >
        {rest.label}
      </InputLabel>
      <MuiSelect
        {...rest}
        InputLabelProps={{
          ...rest.InputLabelProps,
          classes: {
            root: error ? classes.labelError : classes.label,
            focused: error ? classes.labelError : classes.label,
          },
        }}
        inputProps={{
          ...rest.InputProps,
          classes: {
            root: error ? classes.inputRootError : classes.inputRoot,
            notchedOutline: error ? classes.outlineError : classes.outline,
            input: error ? classes.inputError : classes.input,
          },
        }}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
