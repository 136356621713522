import { UserApi } from "./../api";
import React from "react";
import firebase from "../shared/firebase";
import { User } from "../types/data";

export default function useUser() {
  const [user, setUser] = React.useState<User>(undefined);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      // Save ip on server
      UserApi.ip().catch(console.error);

      if (user && user.emailVerified) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  return user;
}
