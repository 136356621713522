import { FlightApi } from "./../apis/flight";
import React from "react";
import { Flight, Passenger, User } from "../types/data";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export function useQueueAutoLock(
  user: User,
  flight: Flight | null | undefined,
  queue: Passenger[] | undefined,
) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const lockingRef = React.useRef(false);

  React.useEffect(() => {
    if (!flight || !queue || !user) return;

    if (user.uid !== flight.hostId) return;

    if (!flight.maxQueueSize) return;

    if (lockingRef.current === true) return;

    if (queue.length < flight.maxQueueSize) return;

    lockingRef.current = true;

    FlightApi.lock()
      .then(() => {
        lockingRef.current = false;
        enqueueSnackbar(t("success.autoLock", { ns: "shared" }), {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        lockingRef.current = false;
        enqueueSnackbar(
          t("error.autoLock", { error: error.message, ns: "shared" }),
          {
            variant: "error",
          },
        );
      });
  }, [enqueueSnackbar, flight, queue, t, user]);
}
