import { makeStyles } from "@material-ui/core";
import { SectionProps } from "./Section.types";

const COLOR: { [k in SectionProps["variant"]]: string | undefined } = {
  brown: "white",
  blue: undefined,
  cyan: undefined,
  mint: undefined,
};

const BACKGROUND_COLOR: {
  [k in SectionProps["variant"]]: string | undefined;
} = {
  brown: "#7b6c53",
  blue: undefined,
  cyan: undefined,
  mint: undefined,
};
const BACKGROUND_IMAGE: {
  [k in SectionProps["variant"]]: string | undefined;
} = {
  brown:
    "url(https://www.animal-crossing.com/new-horizons/assets/img/global/patterns/pattern-dots-2x.png)",
  blue:
    "url(https://www.animal-crossing.com/new-horizons/assets/img/global/patterns/pattern-characters-2x.jpg)",
  cyan:
    "url(https://www.animal-crossing.com/new-horizons/assets/img/global/patterns/pattern-leaves-turquoise-2x.jpg)",
  mint:
    "url(https://www.animal-crossing.com/new-horizons/assets/img/global/patterns/pattern-confetti-2x.jpg)",
};
const BACKGROUND_SIZE: {
  [k in SectionProps["variant"]]: string | undefined;
} = {
  brown: "10px 10px",
  blue: "500px 500px",
  cyan: "500px 500px",
  mint: "500px 500px",
};

export const useStyles = makeStyles((theme) => ({
  container: ({ variant, spacing = true, shrink = false }: SectionProps) => {
    return {
      margin: spacing ? theme.spacing(4, 0, 0, 0) : undefined,
      padding: shrink ? 24 : "50px 24px",
      color: COLOR[variant],
      backgroundImage: BACKGROUND_IMAGE[variant],
      backgroundColor: BACKGROUND_COLOR[variant],
      backgroundSize: BACKGROUND_SIZE[variant],
      backgroundRepeat: "repeat",
    };
  },
}));
