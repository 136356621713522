import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Switch from "@material-ui/core/Switch";

import usePriceListForPast24Hours from "../../hooks/usePriceListForPast24Hours";
import useTradingTimerLabel from "../../hooks/useTradingTimerLabel";
import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import Ribbon from "../../shared/components/Ribbon/Ribbon";
import Section from "../../shared/components/Section/Section";
import PriceList from "./components/PriceList/PriceList";
import { useTranslation } from "react-i18next";
import UserContext from "../../shared/UserContext";
import usePriceListMetaData from "../../hooks/usePriceListMetaData";
import { formatDistance } from "../../shared/utils";
import useProfile from "../../hooks/useProfile";

export default function Square() {
  const { t } = useTranslation("Square");
  const user = React.useContext(UserContext);

  const profile = useProfile(user ? user.uid : undefined);
  const [onlyMyRegion, setOnlyMyRegion] = React.useState(false);
  const tradingTimerLabel = useTradingTimerLabel();
  const priceList = usePriceListForPast24Hours({
    user,
    region: onlyMyRegion ? profile?.region : undefined,
  });
  const meta = usePriceListMetaData();
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
        <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
          {t("sec1.totalSubmission")} {meta ? meta.count : "-"}
          <br />
          {t("sec1.latestSubmission")}{" "}
          {meta && meta.lastSubmittedAt
            ? formatDistance(new Date())(meta.lastSubmittedAt)
            : "-"}
        </Box>
      </Section>
      <Box px={4} mb={-9}>
        <Ribbon variant="mint">{tradingTimerLabel}</Ribbon>
      </Box>
      <Section variant="blue">
        <Card variant="blue">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box color="info.main">
              <Icon className="fas fa-bullhorn" fontSize="large" />
            </Box>
            <Box component="h2" color="info.main">
              {t("sec2.title")}
            </Box>
            <Box component="p" textAlign="center">
              {t("sec2.desc1")}
              <br />
              {t("sec2.desc2BeforeAirport")}
              <Box
                component={Link}
                color="text.primary"
                // @ts-ignore
                to="/airport"
              >
                {t("sec2.airport")}
              </Box>{" "}
              {t("sec2.desc2AfterAirport")}
            </Box>
          </Box>
          {user && (
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <FormControlLabel
                control={
                  <Switch
                    color="default"
                    checked={onlyMyRegion}
                    onChange={() => {
                      setOnlyMyRegion(!onlyMyRegion);
                    }}
                  />
                }
                label={<Box fontSize={12}>{t("sec2.switchLabel")}</Box>}
                labelPlacement="start"
              />
            </Box>
          )}
          {priceList === undefined && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              my={3}
            >
              <CircularProgress variant="blue" />
            </Box>
          )}
          {priceList === null && <PriceList data={[]} />}
          {priceList && <PriceList data={priceList} />}
          <Box mt={4}>
            <Button
              variant="blue"
              icon={
                <Icon
                  className="fas fa-chevron-right"
                  style={{ fontSize: 14 }}
                />
              }
              onClick={() => {
                history.push("/submit-price");
              }}
            >
              {t("sec2.cta")}
            </Button>
          </Box>
        </Card>
      </Section>
    </Box>
  );
}
