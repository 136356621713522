import { CatalogItemDatabaseState } from "./types/data";
import firebase, { isDev } from "./shared/firebase";
import {
  TerminalType,
  TurnipsFlightType,
  SpecialCharacter,
  LEAVE_QUEUE_RESON,
} from "./types/data";
import * as R from "ramda";
import badWordFilter from "./shared/badWordFilter";
import { getInOneHour } from "./shared/utils";

export class UserApi {
  static initMyShortId() {
    return firebase.functions().httpsCallable("initMyShortId")();
  }

  static ip() {
    return firebase.functions().httpsCallable("ip")();
  }
}

export class ChatApi {
  static ref(flightId: string) {
    return firebase.database().ref(`flightChat/${flightId}`);
  }

  // static send(flightId: string, content: string) {
  //   return firebase.functions().httpsCallable("chatSend")({
  //     flightId,
  //     content,
  //   });
  // }
  static send(flightId: string, content: string) {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      if (content.length > 256) {
        reject(new Error("Message too long."));
        return;
      }
      ChatApi.ref(flightId)
        .push({
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          uid: currentUser.uid,
          content: badWordFilter.clean(content),
        })
        .then(resolve)
        .catch(reject);
    });
  }
}

export class QueueApi {
  static ref(flightId: string) {
    return firebase.database().ref(`flightQueue/${flightId}`);
  }

  // static joinQueue(flightCreatorId: string) {
  //   return firebase.functions().httpsCallable("queueJoin")({
  //     flightCreatorId,
  //   });
  // }

  static joinQueue(flightCreatorId: string) {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      QueueApi.ref(flightCreatorId)
        .child(currentUser.uid)
        .set({
          joinedAt: firebase.database.ServerValue.TIMESTAMP,
        })
        .then(resolve)
        .catch(reject);
    });
  }

  // Calling remote API to ensure history
  static leaveQueue(flightCreatorId: string) {
    return firebase.functions().httpsCallable("queueLeave")({
      flightCreatorId,
    });
  }

  // static leaveQueue(flightCreatorId: string, joinedAt?: number) {
  //   return new Promise((resolve, reject) => {
  //     const currentUser = firebase.auth().currentUser;
  //     if (!currentUser || joinedAt === undefined) {
  //       reject(new Error("Permission denied."));
  //       return;
  //     }
  //     QueueApi.ref(flightCreatorId)
  //       .child(currentUser.uid)
  //       .remove()
  //       .then(resolve)
  //       .catch((error) => {
  //         console.error(error);
  //         reject(error);
  //       });
  //     QueueHistoryApi.save(
  //       flightCreatorId,
  //       currentUser.uid,
  //       joinedAt,
  //       LEAVE_QUEUE_RESON.LEAVE,
  //     ).catch(console.error);
  //   });
  // }

  // Calling remote API to ensure history
  static tripFinished(flightCreatorId: string) {
    return firebase.functions().httpsCallable("queueTripFinished")({
      flightCreatorId,
    });
  }

  // static tripFinished(flightCreatorId: string, joinedAt?: number) {
  //   return new Promise((resolve, reject) => {
  //     const currentUser = firebase.auth().currentUser;
  //     if (!currentUser || joinedAt === undefined) {
  //       reject(new Error("Permission denied."));
  //       return;
  //     }
  //     QueueApi.ref(flightCreatorId)
  //       .child(currentUser.uid)
  //       .remove()
  //       .then(resolve)
  //       .catch((error) => {
  //         console.error(error);
  //         reject(error);
  //       });
  //     QueueHistoryApi.save(
  //       flightCreatorId,
  //       currentUser.uid,
  //       joinedAt,
  //       LEAVE_QUEUE_RESON.TRIP_FINISHED,
  //     ).catch(console.error);
  //   });
  // }

  // Calling remote API to ensure history
  static removeFromQueue(uid: string) {
    return firebase.functions().httpsCallable("queueRemoveUser")({
      uid,
    });
  }

  // static removeFromQueue(uid: string, joinedAt: number) {
  //   return new Promise((resolve, reject) => {
  //     const currentUser = firebase.auth().currentUser;
  //     if (!currentUser || joinedAt === undefined) {
  //       reject(new Error("Permission denied."));
  //       return;
  //     }
  //     const flightCreatorId = currentUser.uid;
  //     QueueApi.ref(flightCreatorId)
  //       .child(uid)
  //       .remove()
  //       .then(resolve)
  //       .catch((error) => {
  //         console.error(error);
  //         reject(error);
  //       });
  //     QueueHistoryApi.save(
  //       flightCreatorId,
  //       uid,
  //       joinedAt,
  //       LEAVE_QUEUE_RESON.REMOVED_BY_HSOT,
  //     ).catch(console.error);
  //   });
  // }
}

export class QueueHistoryApi {
  static ref(flightId: string) {
    return firebase.database().ref(`flightQueueHistory/${flightId}`);
  }

  static save(
    flightCreatorId: string,
    uid: string,
    joinedAt: number,
    reason: LEAVE_QUEUE_RESON,
  ) {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      QueueHistoryApi.ref(flightCreatorId)
        .push({
          joinedAt,
          uid,
          leftAt: firebase.database.ServerValue.TIMESTAMP,
          leftReason: reason,
        })
        .then(resolve)
        .catch(reject);
    });
  }
}

export interface FlightCreatePayload {
  terminal: TerminalType;
  code: string;
  maxAllowed: number;
  message?: string;
  planToCloseAt: Date;

  price?: number;
  turnipsFlightType?: TurnipsFlightType;
  specialCharacter?: SpecialCharacter;
}

export interface FlightUpdatePayload {
  terminal: TerminalType;
  maxAllowed: number;
  message?: string;
  code: string;
  planToCloseAt: Date;

  price?: number;
  turnipsFlightType?: TurnipsFlightType;
  specialCharacter?: SpecialCharacter;
}

export class FlightApi {
  static ref(flightId: string) {
    return firebase.database().ref(`flights/${flightId}`);
  }

  static refPrivate(flightId: string) {
    return firebase.database().ref(`flightPrivate/${flightId}`);
  }

  static refPrivateAccessedUser(flightId: string, uid: string) {
    return firebase.database().ref(`flightPrivateAccessed/${flightId}/${uid}`);
  }

  // static create(payload: FlightCreatePayload) {
  //   const mergedPayload = R.reject(R.isNil)({
  //     ...payload,
  //     planToCloseAt: payload.planToCloseAt.toUTCString(),
  //   });

  //   return new Promise((resolve, reject) => {
  //     firebase
  //       .functions()
  //       .httpsCallable("flightCreate")(mergedPayload)
  //       .then(({ data: { flightId } }) => {
  //         setTimeout(() => {
  //           firebase.analytics().logEvent("Flight Creation Succeeded", {
  //             id: flightId,
  //             payload: mergedPayload,
  //           });
  //         });
  //         resolve(flightId);
  //       })
  //       .catch((error) => {
  //         setTimeout(() => {
  //           firebase.analytics().logEvent("Flight Creation Failed", {
  //             error,
  //           });
  //         });

  //         reject(error);
  //       });
  //   });
  // }

  static create(payload: FlightCreatePayload, flightCreatorShortId: string) {
    const { code, ...publicPayload } = payload;
    const _public = R.reject(R.isNil)({
      ...publicPayload,
      message: publicPayload.message
        ? badWordFilter.clean(publicPayload.message)
        : undefined,
      planToCloseAt: publicPayload.planToCloseAt.toUTCString(),

      queueLocked: false,
      hostStatus: "online",

      status: "open",
      lastChangedAt: firebase.database.ServerValue.TIMESTAMP,
    });
    const _private = R.reject(R.isNil)({ code });

    return new Promise(async (resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      try {
        await FlightApi.ref(currentUser.uid).set(_public);
        await FlightApi.refPrivate(currentUser.uid).set(_private);
        resolve(flightCreatorShortId);
        return;
      } catch (error) {
        reject(error);
      }
    });
  }

  // static update(flightId: string, payload: FlightUpdatePayload) {
  //   const cleanedPayload = R.reject(R.isNil)({
  //     ...payload,
  //     planToCloseAt: payload.planToCloseAt.toUTCString(),
  //   });

  //   return new Promise((resolve, reject) => {
  //     firebase
  //       .functions()
  //       .httpsCallable("flightUpdate")(cleanedPayload)
  //       .then(() => {
  //         setTimeout(() => {
  //           firebase.analytics().logEvent("Flight Update Succeeded", {
  //             flightId,
  //             payload,
  //           });
  //         });

  //         resolve(flightId);
  //       })
  //       .catch((error) => {
  //         setTimeout(() => {
  //           firebase.analytics().logEvent("Flight Update Failed", {
  //             flightId,
  //             payload,
  //             error,
  //           });
  //         });

  //         reject(error);
  //       });
  //   });
  // }

  static update(payload: FlightUpdatePayload, flightCreatorShortId: string) {
    const { code, ...publicPayload } = payload;
    const planToCloseAt =
      publicPayload.planToCloseAt instanceof Date &&
      !isNaN(publicPayload.planToCloseAt.valueOf())
        ? publicPayload.planToCloseAt
        : getInOneHour();
    const _public = R.reject(R.isNil)({
      ...publicPayload,
      message: publicPayload.message
        ? badWordFilter.clean(publicPayload.message)
        : undefined,
      planToCloseAt: planToCloseAt.toUTCString(),
      lastChangedAt: firebase.database.ServerValue.TIMESTAMP,
    });
    const _private = R.reject(R.isNil)({ code });

    return new Promise(async (resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      try {
        await FlightApi.ref(currentUser.uid).update(_public);
        await FlightApi.refPrivate(currentUser.uid).update(_private);
        resolve(flightCreatorShortId);
        return;
      } catch (error) {
        reject(error);
      }
    });
  }

  static close() {
    return firebase.functions().httpsCallable("flightClose")();
  }

  static reopen() {
    return firebase.functions().httpsCallable("flightReopen")();
  }

  static lock() {
    return firebase.functions().httpsCallable("flightLock")();
  }

  static unlock() {
    return firebase.functions().httpsCallable("flightUnlock")();
  }

  static getCode(flightCreatorId: string) {
    return firebase.functions().httpsCallable("flightCodeGet")({
      flightCreatorId,
    });
  }
}

export class ReportApi {
  static ref(flightCreatorId: string) {
    return firebase.database().ref(`report/${flightCreatorId}`);
  }

  static report(flightCreatorId: string, reason: string) {
    return firebase
      .functions()
      .httpsCallable("report")({
        flightCreatorId,
        reason,
      })
      .then((res) => {
        firebase
          .auth()
          .sendSignInLinkToEmail("hi@turnips.exchange", {
            url:
              (isDev
                ? "https://turnip-exchange-x.web.app"
                : "https://x.turnips.exchange") +
              `/reports?uid=${flightCreatorId}`,
            handleCodeInApp: true,
          })
          .then(function () {
            // pass
          })
          .catch(function (error) {
            // pass
          });

        return res;
      });
  }
}

export class CatalogApi {
  static ref(uid: string) {
    return firebase.database().ref("catalog").child(uid);
  }

  static setCatalogued(uid: string, itemId: string) {
    return CatalogApi.ref(uid).child(itemId).set({
      state: CatalogItemDatabaseState.CATALOGUED,
    });
  }

  static setWishListed(uid: string, itemId: string) {
    return CatalogApi.ref(uid).child(itemId).set({
      state: CatalogItemDatabaseState.WISH_LISTED,
    });
  }

  static remove(uid: string, itemId: string) {
    return CatalogApi.ref(uid).child(itemId).remove();
  }
}
