import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ja from "./locale/ja.json";
import zh from "./locale/zh.json";
import en from "./locale/en.json";
import { LOCAL_STORAGE_LANGUAGE_KEY } from "../../hooks/useLanguage";
import { Language } from "../../types/data";

const region =
  window.localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || Language.ENGLISH;

i18n.use(initReactI18next).init({
  lng:
    region === Language.JAPANESE
      ? "ja"
      : region === Language.CHINESE
      ? "zh"
      : "en",
  fallbackLng: "en",
  debug: true,
  resources: {
    en,
    zh,
    ja,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
