import React from "react";
import { Flight } from "../../../types/data";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

export interface FlightAlertsProps {
  flightHostStatus: Flight["hostStatus"];
  isHost: boolean;
  isInQueue: boolean;
}

export default function FlightAlerts({
  flightHostStatus,
  isHost,
  isInQueue,
}: FlightAlertsProps) {
  const { t } = useTranslation("Flight");

  if (flightHostStatus === "offline") {
    return (
      <Box
        bgcolor="error.main"
        color="text.secondary"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        py={1}
        px={2}
        borderRadius={12}
      >
        {t("sec2.hostOfflineAlert")}
      </Box>
    );
  }

  if (isHost) {
    return (
      <Box
        bgcolor="error.main"
        color="text.secondary"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        py={1}
        px={2}
        borderRadius={12}
      >
        {t("sec2.dontLeaveAlertHost")}
      </Box>
    );
  }

  if (isInQueue) {
    return (
      <Box>
        <Box
          bgcolor="error.main"
          color="text.secondary"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          borderRadius={12}
        >
          {t("sec2.dontLeaveAlertPassenger")}
        </Box>
        <Box
          mt={2}
          bgcolor="warning.main"
          color="text.secondary"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={2}
          borderRadius={12}
        >
          {t("sec2.scamAlert")}
        </Box>
      </Box>
    );
  }

  return null;
}
