import { makeStyles } from "@material-ui/core";
import { RibbonProps } from "./Ribbon.types";

const BACKGROUND_COLOR: { [k in RibbonProps["variant"]]: string } = {
  mint: "#f0fff5",
};

const BACKGROUND_IMAGE: { [k in RibbonProps["variant"]]: string } = {
  mint:
    "url(https://www.animal-crossing.com/new-horizons/assets/img/global/ribbon-end-green.svg)",
};

export const useStyles = makeStyles((theme) => ({
  container: ({ variant }: RibbonProps) => {
    return {
      filter: "drop-shadow(0px 6px 5px) rgba(107,92,67,0.3))",
      position: "relative",
      textAlign: "center",
      "&::before": {
        content: '""',
        position: "absolute",
        left: -26,
        bottom: -17,
        height: 72,
        width: 68,
        backgroundImage: BACKGROUND_IMAGE[variant],
      },
      "&::after": {
        content: '""',
        position: "absolute",
        right: -17,
        bottom: -17,
        transform: "scaleX(-1)",
        height: 72,
        width: 68,
        backgroundImage: BACKGROUND_IMAGE[variant],
      },
    };
  },
  labelContainer: ({ variant }: RibbonProps) => ({
    backgroundColor: BACKGROUND_COLOR[variant],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
    minHeight: 50,
    position: "relative",
    zIndex: 1,
    fontSize: "calc(18 / var(--font-base) * 1rem)",
    color: theme.palette.text.primary,
  }),
}));
