import React from "react";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./CircularProgress.styles";
import { CircularProgressProps } from "./CircularProgress.types";

const CircularProgress: React.FC<CircularProgressProps> = (props) => {
  const classes = useStyles(props);
  return (
    <MuiCircularProgress
      classes={{
        colorPrimary: classes.colorPrimary,
      }}
    />
  );
};

export default CircularProgress;
