import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100vh",
  },
  bg: {
    zIndex: -1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
    backgroundRepeat: "repeat",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      animation: "$pattern_anim_leaves-white 80s linear 0s infinite forwards",
      backgroundImage:
        "url(https://www.animal-crossing.com/new-horizons/assets/img/global/patterns/pattern-leaves-white.jpg)",
      backgroundSize: "420px 420px",
      height: "calc(100% + 420px)",
      width: "calc(100% + 420px)",
    },
  },
  "@keyframes pattern_anim_leaves-white": {
    "0%": {
      transform: "translate3d(0px, 0px, 1px)",
    },
    "100%": {
      transform: "translate3d(-420px, -420px, 1px)",
    },
  },
  footer: {
    backgroundColor: "#f8eebc",
    backgroundImage:
      "url(https://www.animal-crossing.com/new-horizons/assets/img/global/footer-illustration.svg)",
    backgroundPosition: "50% 100.5%",
    backgroundRepeat: "repeat-x",
    backgroundSize: "auto 110px",
    filter: "drop-shadow(0px 4px 10px rgba(0,0,0,0.3))",
    marginTop: theme.spacing(4),
    padding: "70px 0 160px",
    position: "relative",
  },
  regionSelect: {
    paddingRight: "0 !important",
  },
}));
