import React from "react";
import Box from "@material-ui/core/Box";

const Alert: React.FC<{ style: any }> = ({ children, style }) => {
  return (
    <Box
      style={{
        backgroundColor: "rgb(232, 244, 253)",
        color: "rgb(13, 60, 97)",
        padding: "6px 16px",
        borderRadius: 4,
        width: "100%",
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default Alert;
