import { format, getDay, getWeek, isBefore } from "date-fns";
import * as R from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  Label,
} from "recharts";
import { Helmet } from "react-helmet";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import useMyTracking from "../../hooks/useMyTracking";
import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import Section from "../../shared/components/Section/Section";
import UserContext from "../../shared/UserContext";
import { getNoon } from "../../shared/utils";
import { PriceRecord } from "../../types/data";
import Calculator from "./Calculator/Calculator";
import useMyWeeklyBuyin from "../../hooks/useMyWeeklyBuyin";

function useChartGroupName() {
  const { t } = useTranslation("useChartGroupName");
  return {
    THIS_WEEK: t("thisWeek"),
    LAST_WEEK: t("lastWeek"),
    THE_WEEK_BEFORE_LAST: t("theWeekBeforeLast"),
  };
}

export function prepareChartData(
  chartGroupName: any,
  listOfPastThreeWeeks: PriceRecord[] | undefined,
) {
  if (listOfPastThreeWeeks === undefined) {
    return [];
  }

  const data: any = [
    {
      name: format(new Date(2020, 2, 23, 0), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 23, 12), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 24, 0), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 24, 12), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 25, 0), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 25, 12), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 26, 0), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 26, 12), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 27, 0), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 27, 12), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 28, 0), "eee a", {
        locale: window.__locale,
      }),
    },
    {
      name: format(new Date(2020, 2, 28, 12), "eee a", {
        locale: window.__locale,
      }),
    },
  ];

  const currentWeek = getWeek(new Date());

  const groups = R.groupBy<PriceRecord>((i) => {
    const week = getWeek(i.date);
    if (currentWeek - week === 2) {
      return chartGroupName.THE_WEEK_BEFORE_LAST;
    }
    if (currentWeek - week === 1) {
      return chartGroupName.LAST_WEEK;
    }

    return chartGroupName.THIS_WEEK;
  })(R.reverse(listOfPastThreeWeeks)); // reversing so that new data overwrites old ones

  Object.keys(groups).forEach((groupName) => {
    groups[groupName].forEach((r) => {
      const day = getDay(r.date);
      if (day === 0) {
        return; // skip sunday data
      }
      const noon = getNoon(r.date);
      const index = (day - 1) * 2 + (isBefore(r.date, noon) ? 0 : 1);
      data[index][groupName] = r.price;
    });
  });

  return data;
}

function useHandleAnchor(user: firebase.User | null | undefined) {
  React.useEffect(() => {
    if (!user) {
      return;
    }

    const id = window.location.hash.substring(1);
    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
    });
  }, [user]);
}

export default function Tracking() {
  const { t } = useTranslation("Tracking");
  const user = React.useContext(UserContext);
  const history = useHistory();
  const list = useMyTracking(user);
  const chartGroupName = useChartGroupName();
  const chartData = React.useMemo(
    () => prepareChartData(chartGroupName, list),
    [list, chartGroupName],
  );
  const currentBuyin = useMyWeeklyBuyin(user);
  const currentBuyinPrice = currentBuyin && currentBuyin.price;

  useHandleAnchor(user);

  if (user === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="blue" />
      </Box>
    );
  }

  if (user === null) {
    return (
      <Redirect to="/sign-in?message=Please%20sign%20in%20before%20viewing%20your%20tracking%21&returnUrl=%2Ftracking" />
    );
  }

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
      </Section>
      <Section variant="mint">
        <Card variant="mint">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box style={{ color: "rgba(0,129,96,0.3)" }}>
              <Icon className="fas fa-chart-line" fontSize="large" />
            </Box>
            <Box component="h2" style={{ color: "rgba(0,129,96,1)" }}>
              {t("sec2.title")}
            </Box>
            <Box textAlign="center">
              {t("sec2.desc1")}
              <br /> {t("sec2.desc2")}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
            style={{ overflowY: "auto" }}
          >
            <Box width="100%" maxWidth={500}>
              <LineChart
                width={500}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={{ width: "100%" }} />
                {currentBuyinPrice !== undefined && (
                  <ReferenceLine
                    y={currentBuyinPrice}
                    stroke="rgb(107, 92, 67)"
                    strokeDasharray="3 3"
                    alwaysShow
                  >
                    <Label
                      value={t("sec2.buyInPrice").toString()}
                      fill="rgb(107, 92, 67)"
                    />
                  </ReferenceLine>
                )}
                <Line
                  type="monotone"
                  dataKey={chartGroupName.THIS_WEEK}
                  stroke="rgba(104,161,215,1)"
                  activeDot={{ r: 8 }}
                  dot={{ fill: "rgba(104,161,215,1)", strokeWidth: 0 }}
                />
                <Line
                  connectNulls
                  type="monotone"
                  dataKey={chartGroupName.LAST_WEEK}
                  stroke="rgba(0, 125, 117, 1)"
                  strokeDasharray="5 5"
                  dot={{ fill: "rgba(0, 125, 117,1)", strokeWidth: 0 }}
                />
                <Line
                  connectNulls
                  type="monotone"
                  dataKey={chartGroupName.THE_WEEK_BEFORE_LAST}
                  stroke="#007d75"
                  strokeDasharray="5 5"
                  dot={{ fill: "rgba(0,129,96,1)", strokeWidth: 0 }}
                />
              </LineChart>
            </Box>
          </Box>
          <Box mt={4}>
            <Button
              variant="blue"
              icon={
                <Icon
                  className="fas fa-chevron-right"
                  style={{ fontSize: 14 }}
                />
              }
              onClick={() => {
                history.push("/submit-price");
              }}
            >
              {t("sec2.submit")}
            </Button>
          </Box>
        </Card>
      </Section>
      <div id="calculator"></div>
      <Calculator />
    </Box>
  );
}
