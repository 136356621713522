import React from "react";
import { SpecialCharacter } from "../../types/data";
import Box from "@material-ui/core/Box";
import { SPECIAL_CHARACTERS } from "../constants";
import { useTranslation } from "react-i18next";

export interface SpecialCharacterLabelProps {
  id: SpecialCharacter;
  size?: number;
  width?: number;
  height?: number;
  withoutName?: boolean;
}

export default function SpecialCharacterLabel({
  id,
  size = 24,
  height,
  width,
  withoutName = false,
}: SpecialCharacterLabelProps) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box
        height={height || size}
        width={width || size}
        mr={0.5}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          height="100%"
          width="auto"
          // @ts-ignore
          src={SPECIAL_CHARACTERS[id].image}
        ></Box>
      </Box>
      {!withoutName && (
        <Box>
          {t(SPECIAL_CHARACTERS[id].displayName, {
            ns: "shared",
          })}
        </Box>
      )}
    </Box>
  );
}
