import React from "react";
import { Link } from "react-router-dom";
import useProfile from "../../../hooks/useProfile";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";

export interface IslandLinkProps {
  uid: string | undefined;
}

export default function IslandLink({ uid }: IslandLinkProps) {
  const profile = useProfile(uid);
  const { t } = useTranslation("IslandLink");

  return (
    <Box
      component={Link}
      // @ts-ignore
      to={`/passport/${uid}`}
      style={{ color: "rgba(0, 0, 0, 0.87)" }}
    >
      {profile?.islandName || t("anIsland")}
    </Box>
  );
}
