import React from "react";
import { useStyles } from "./Section.styles";
import Box from "@material-ui/core/Box";
import { SectionProps } from "./Section.types";

const Section: React.FC<SectionProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
    >
      {props.children}
    </Box>
  );
};

export default Section;
