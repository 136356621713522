import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";

import Button from "../../../shared/components/Button/Button";
import { FlightApi } from "../../../api";
import { useSnackbar } from "notistack";
import isBefore from "date-fns/isBefore";
import { getInThirtyMinutes } from "../../../shared/utils";
import isAfter from "date-fns/isAfter";
import HostRules from "./HostRulesDialog";
import { useDialog } from "../../../hooks/useDialog";

export interface FlightActionsProps {
  isHost: boolean;
  planToCloseAt?: Date;
  isFlightOpen: boolean;
}

export default function FlightActions({
  isHost,
  planToCloseAt,
  isFlightOpen,
}: FlightActionsProps) {
  const { t } = useTranslation("Flight");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const hostRuleDialog = useDialog();

  const [submitting, setSubmitting] = React.useState(false);
  const reopenFlight = React.useCallback(() => {
    setSubmitting(true);
    FlightApi.reopen()
      .then((id) => {
        enqueueSnackbar(t("success.reopen"), {
          variant: "success",
        });
        setSubmitting(false);
      })
      .catch((error: Error) => {
        enqueueSnackbar(t("error.reopen", { msg: error.message }), {
          variant: "error",
        });
        setSubmitting(false);
      });
  }, [enqueueSnackbar, t]);

  const closeFlight = React.useCallback(() => {
    setSubmitting(true);
    FlightApi.close()
      .then(() => {
        enqueueSnackbar(t("success.close"), {
          variant: "success",
        });
        setSubmitting(false);
      })
      .catch((error: Error) => {
        enqueueSnackbar(t("error.close", { msg: error.message }), {
          variant: "error",
        });
        setSubmitting(false);
      });
  }, [enqueueSnackbar, t]);

  if (!isHost) {
    return null;
  }

  const isEndTimeValid = planToCloseAt
    ? isAfter(planToCloseAt, new Date())
    : false;

  return (
    <React.Fragment>
      <Box
        mt={4}
        mb={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Button
          variant="blue"
          onClick={() => {
            history.push(`/flight/manage`);
          }}
        >
          {t("sec2.manageFlight")}
        </Button>
      </Box>
      {planToCloseAt && isBefore(planToCloseAt, getInThirtyMinutes()) && (
        <Box
          mb={2}
          color="error.main"
          fontWeight="fontWeightBold"
          textAlign="center"
        >
          {t("sec2.endTimeAlert")}
        </Box>
      )}
      {!isFlightOpen && !isEndTimeValid && (
        <Box
          mb={2}
          color="error.main"
          fontWeight="fontWeightBold"
          textAlign="center"
        >
          {t("sec2.endTimeInvalid")}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant={isFlightOpen ? "red" : "blue"}
          onClick={() => {
            if (isFlightOpen) {
              closeFlight();
            } else {
              hostRuleDialog.open();
            }
          }}
          disabled={submitting || (!isEndTimeValid && !isFlightOpen)}
        >
          {isFlightOpen ? t("sec2.closeFlight") : t("sec2.reopenFlight")}
        </Button>
      </Box>
      <HostRules
        isOpen={hostRuleDialog.isOpen}
        close={hostRuleDialog.close}
        onConfirm={() => {
          reopenFlight();
        }}
      />
    </React.Fragment>
  );
}
