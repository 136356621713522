import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import Button from "../../../shared/components/Button/Button";
import Card from "../../../shared/components/Card/Card";
import Section from "../../../shared/components/Section/Section";

export default function FlightNotFound() {
  const { t } = useTranslation("Flight");
  const history = useHistory();

  return (
    <Section variant="brown">
      <Card variant="yellow">
        <Box mb={2} display="flex" flexDirection="column" alignItems="center">
          <Box color="rgba(241, 226, 111, 0.8)">
            <Icon className="fas fa-plane-slash" fontSize="large" />
          </Box>
          <Box component="h2" color="text.primary">
            {t("nullTitle")}
          </Box>
          <Box textAlign="center" color="text.primary">
            {t("nullDesc")}
          </Box>
        </Box>
        <Button
          variant="brown"
          icon={
            <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
          }
          onClick={() => history.push("/airport")}
        >
          {t("nullCta")}
        </Button>
      </Card>
    </Section>
  );
}
