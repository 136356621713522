import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Box } from "@material-ui/core";
import { PatreonTier } from "../../types/data";
import { useTranslation } from "react-i18next";

export interface PatreonBadgeProps {
  tier: PatreonTier;
  style?: any;
}

export default function PatreonBadge({ tier, style }: PatreonBadgeProps) {
  const { t } = useTranslation("PatreonBadge");

  return (
    <Tooltip
      title={t(tier)}
      aria-label={t(tier)}
      disableFocusListener
      disableTouchListener
    >
      <Box
        component="img"
        width={16}
        height={16}
        // @ts-ignore
        src={`/assets/${tier}.svg`}
        alt={t(tier)}
        style={style}
      />
    </Tooltip>
  );
}
