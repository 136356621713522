import { withStyles } from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";

export const Tabs = withStyles({
  root: {
    borderBottom: "1px solid transparent",
    minHeight: "unset !important",
  },
  indicator: {
    backgroundColor: "#6b5c43",
  },
})(MuiTabs);
