import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";

export interface FirstClassRuleDialogProps {
  isOpen: boolean;
  close: () => void;
}

export default function FirstClassRuleDialog({
  isOpen,
  close,
}: FirstClassRuleDialogProps) {
  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>First Class Queue</DialogTitle>
      <DialogContent>
        <Box component="h4">Q: What is First Class Queue?</Box>
        <Box component="p">
          A: This site is live now because of all our Patrons' constant generous
          support. First Class Queue is a separate queue from Standard Queue for
          certains Tiers of Patrons to reward their generosity.
        </Box>
        <Box component="h4">
          Q: Would it make experience for non-patron users not as good as
          before?
        </Box>
        <Box component="p">
          A: It will not downgrade experience for non-patron users. We have
          detailed rules for First Class Queue usage to minimize the interrupion
          to the Standard Queue. But if you have any feedback or suggestions,
          please let us know in our Discord.
        </Box>
        <Box component="h4">Q: So how privileged is this "First Class"?</Box>
        <Box component="p">
          A: Being eligible for First Class doesn't mean free from our community
          rules or Hosts' house rules. Please report to us immediately if you
          find any First Class users violating any rules. We will temporarily or
          permanantly remove their Badges once confirmed.
        </Box>
        <Box component="h4">Q: How does it work?</Box>
        <Box component="p">
          A: There will always be 1 seat reserved for First Class Queue and{" "}
          <strong>only 1</strong>. <br />
          <br />
          Let's say we have a flight with max 3 visitors allowed at a time:{" "}
          <br />- if First Class Queue is empty, top 3 of the Standard Queue
          will be shown the code; <br />- if First Class Queue is not empty, top
          2 of the Standard Queue and top 1 of the First Class Queue will be
          shown the code. <br />
          <br /> Also First Class Queue will <strong>auto remove</strong> top 1
          user <strong>after</strong> they've got the code for{" "}
          <strong>15 mins</strong>.
          <br /> Make sure First Class tab is selected before you join the
          queue.
        </Box>
        <Box component="h4">Q: Am I eligible? What's the allowance?</Box>
        <Box component="p">
          A: First Class Queue is currently available to Patron Tier Resident+
          and above. Each tier has their own limited weekly and daily allowance,
          and there is a cooldown time between multiple First Class trips.
          (Cooldown time is doubled for joining the same queue twice in a row.)
          <br />
          <br /> <strong>Councilor</strong>: 21/week & 3/day, 15 mins cooldown
          <br /> <strong>Citizen & Investor+</strong>: 15/week & 3/day, 20 mins
          cooldown
          <br /> <strong>Resident+ & Investor</strong>: 9/week & 3/day, 30 mins
          cooldown
          <br />
          <br />
          We will continue to monitor the impact of this Queue and make change
          at any time we believe right in order to get best experience for all
          users.
        </Box>
        <Box component="h4">Q: Got something else to say?</Box>
        <Box component="p">
          A: As always, Turnips Exchange tries to be the most trusting and
          friendly community. Thanks to each and every one of you, whether you
          are hosting or visiting, we are having more fun than just playing the
          game with ourselves alone. And please give your applause to{" "}
          <strong>the Developers and Admins and Patrons</strong> of this
          platform too, they are also part of the reason why we could enjoy
          being here everyday. <br /> <br />
          First Class Queue is only about appreciation and nothing else.
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" autoFocus>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
