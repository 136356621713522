import { FlightPassengerType } from "./../types/all";
import * as R from "ramda";
// @ts-ignore
import randomatic from "randomatic";

import badWordFilter from "../shared/badWordFilter";
import firebase from "../shared/firebase";
import {
  SpecialCharacter,
  Terminal,
  TurnipsFlightType,
  Flight,
} from "../types/all";

export interface FlightUpdatePayload {
  code: string;
  flightPassengerType?: FlightPassengerType;
  maxAllowed: number;
  maxStayTime: number;
  maxQueueSize: number;
  message?: string;
  planToCloseAt: Date;
  price?: number;
  requirePassword?: boolean;
  specialCharacter?: SpecialCharacter;
  terminal: Terminal;
  turnipsFlightType?: TurnipsFlightType;
}

export class FlightApi {
  static ref(flightId: string) {
    return firebase.database().ref(`flights/${flightId}`);
  }

  static refPrivate(flightId: string) {
    return firebase.database().ref(`flightPrivate/${flightId}`);
  }

  static refPrivateAccessedUser(flightId: string, uid: string) {
    return firebase.database().ref(`flightPrivateAccessed/${flightId}/${uid}`);
  }

  static update(
    payload: FlightUpdatePayload,
    currentFlight: Flight | null,
    flightCreatorShortId: string,
    flightCreatorVerified: boolean,
    isCreating: boolean = false,
  ) {
    const {
      code,
      maxStayTime,
      maxQueueSize,
      message,
      planToCloseAt,
      ...publicPayload
    } = payload;
    const _public = R.reject((v: any) => v === undefined)({
      ...publicPayload,
      maxStayTime: maxStayTime === 0 ? null : maxStayTime,
      maxQueueSize: maxQueueSize === 0 ? null : maxQueueSize,
      message: message ? badWordFilter.clean(message) : undefined,
      planToCloseAt: planToCloseAt ? planToCloseAt.getTime() : undefined,
      lastChangedAt: firebase.database.ServerValue.TIMESTAMP,
      ...(isCreating
        ? {
            queueLocked: false,
            status: "open",
          }
        : {}),
    });

    if (_public.terminal === Terminal.TURNIPS) {
      delete _public.specialCharacter;
    } else if (_public.terminal === Terminal.SPECIAL_VISITORS) {
      delete _public.price;
      delete _public.turnipsFlightType;
    } else {
      delete _public.specialCharacter;
      delete _public.price;
      delete _public.turnipsFlightType;
    }

    if (!flightCreatorVerified) {
      delete _public.flightPassengerType;
      delete _public.requirePassword;
    }

    const _private = R.reject(R.isNil)({
      code,
      password:
        payload.requirePassword && currentFlight?.requirePassword
          ? undefined
          : FlightApi.getNewPassword(),
    });

    if (!flightCreatorVerified || !payload.requirePassword) {
      _private.password = null;
    }

    if (currentFlight) {
      // remove unupdated values
      Object.keys(_public).forEach((key) => {
        if (key === "planToCloseAt") {
          if (R.equals(_public.planToCloseAt, currentFlight.planToCloseAt)) {
            delete _public.planToCloseAt;
          }
          return;
        }

        // DO NOT omit turnipsFlightType & price if chaning terminal to turnips because security rules requires both
        if (
          _public.terminal === Terminal.TURNIPS &&
          !R.equals(_public.terminal, currentFlight.terminal)
        ) {
          if (["turnipsFlightType", "price"].includes(key)) return;
        }

        // DO NOT omit specialCharacter if chaning terminal to special_visitors because security rules requires both
        if (
          _public.terminal === Terminal.SPECIAL_VISITORS &&
          !R.equals(_public.terminal, currentFlight.terminal)
        ) {
          if (["specialCharacter"].includes(key)) return;
        }

        if (R.equals(_public[key], R.path([key], currentFlight))) {
          delete _public[key];
        }
      });
      Object.keys(_private).forEach((key) => {
        if (R.equals(_private[key], R.path([key], currentFlight))) {
          delete _private[key];
        }
      });
    }

    return new Promise(async (resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      try {
        // Note: firebase doesn't accept empty value
        if (Object.keys(_public).length > 0) {
          await FlightApi.ref(currentUser.uid)[isCreating ? "set" : "update"](
            _public,
          );
        }
        // Note: firebase doesn't accept empty value
        if (Object.keys(_private).length > 0) {
          await FlightApi.refPrivate(currentUser.uid)[
            isCreating ? "set" : "update"
          ](_private);
        }
        resolve(flightCreatorShortId);
        return;
      } catch (error) {
        reject(error);
      }
    });
  }

  static close() {
    return firebase.functions().httpsCallable("flightClose")();
  }

  static reopen() {
    return firebase.functions().httpsCallable("flightReopen")();
  }

  static lock() {
    return firebase.functions().httpsCallable("flightLock")();
  }

  static unlock() {
    return firebase.functions().httpsCallable("flightUnlock")();
  }

  static getCode(flightCreatorId: string) {
    return firebase.functions().httpsCallable("flightCodeGet")({
      flightCreatorId,
    });
  }

  static resetPassword(flightCreatorId: string) {
    return FlightApi.refPrivate(flightCreatorId).update({
      password: FlightApi.getNewPassword(),
    });
  }

  static getNewPassword() {
    return randomatic("0", 4);
  }
}
