import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Highlighter from "react-highlight-words";
import { Chat, Profile, User } from "../../../../types/data";
import { format } from "../../../../shared/utils";
import DisplayNameLabel from "../../../../shared/components/DisplayNameLabel/DisplayNameLabel";
import badWordFilter from "../../../../shared/badWordFilter";

export interface UserMessageProps {
  chat: Chat;
  user: User;
  hostId: string;
  hostProfile: Profile;
  currentUserDisplayName: string | undefined;
}

export default function UserMessage({
  chat,
  user,
  hostId,
  hostProfile,
  currentUserDisplayName,
}: UserMessageProps) {
  const { t } = useTranslation("Chat");
  const { uid, createdAt, content } = chat;
  const currentUserId = user ? user.uid : undefined;

  return (
    <Box
      key={uid + createdAt}
      my={0.5}
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box fontSize={12}>
          {uid === currentUserId ? (
            <DisplayNameLabel uid={currentUserId} overwrite={t("you")} />
          ) : uid === hostId ? (
            <DisplayNameLabel
              uid={hostId}
              overwrite={t("messageUsernameHost", {
                hostName: hostProfile.displayName,
              })}
              color="info.main"
              fontWeight="fontWeightBold"
              enableLink
            />
          ) : (
            <DisplayNameLabel uid={uid} enableLink />
          )}
        </Box>
        <Box fontSize={10}>{format("hh:mm")(createdAt)}</Box>
      </Box>
      <Box>
        <Highlighter
          searchWords={
            currentUserDisplayName ? [`@${currentUserDisplayName}`] : []
          }
          autoEscape={true}
          textToHighlight={badWordFilter.clean(content)}
          highlightStyle={{
            fontWeight: 600,
            backgroundColor: "rgba(241, 226, 111, 0.8)",
            padding: "0 4px",
            margin: "0 2px",
          }}
        />
      </Box>
    </Box>
  );
}
