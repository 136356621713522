import React from "react";

import { DateTimePickerProps } from "./DateTimePicker.types";
import { useStyles } from "./DateTimePicker.styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  color,
  style,
  error,
  ...rest
}: any) => {
  const classes = useStyles({ color });

  return (
    <FormControl variant="outlined" style={style}>
      <InputLabel
        id={rest.id}
        shrink
        {...{
          ...rest.InputLabelProps,
          classes: {
            root: error ? classes.labelError : classes.label,
            focused: error ? classes.labelError : classes.label,
          },
        }}
      >
        {rest.label}
      </InputLabel>
      <KeyboardDateTimePicker
        {...rest}
        InputProps={{
          ...rest.InputProps,
          classes: {
            root: error ? classes.inputRootError : classes.inputRoot,
            notchedOutline: error ? classes.outlineError : classes.outline,
            input: error ? classes.inputError : classes.input,
          },
          disableUnderline: true,
        }}
      />
    </FormControl>
  );
};

export default DateTimePicker;
