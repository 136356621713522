import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function VerificationBadge({ verified }: { verified: boolean }) {
  const { t } = useTranslation("VerificationBadge");
  const desc = verified ? t("verified") : t("notVerified");

  return (
    <Tooltip
      title={desc}
      aria-label={desc}
      disableFocusListener
      disableTouchListener
    >
      <Box
        component="img"
        width={12}
        height={12}
        // @ts-ignore
        src={`/assets/VERIFIED_1.svg`}
        alt={desc}
        style={{ filter: verified ? undefined : "grayscale(1)" }}
      />
    </Tooltip>
  );
}
