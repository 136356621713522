import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Icon, InputAdornment } from "@material-ui/core";

import useUpdateBuyin from "../../../hooks/useUpdateBuyin";
import useMyWeeklyBuyin from "../../../hooks/useMyWeeklyBuyin";
import Card from "../../../shared/components/Card/Card";
import Section from "../../../shared/components/Section/Section";
import TextField from "../../../shared/components/TextField/TextField";
import UserContext from "../../../shared/UserContext";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import FlightPatreon from "../../Flight/components/FlightPatreon";

export interface UseCalculatorArgs {
  price?: number;
  count?: number;
}
function useCalculator({ price, count }: UseCalculatorArgs) {
  const [sellPrice, setSellPrice] = React.useState<number | undefined>(
    undefined,
  );

  const paid = React.useMemo(
    () => (price && count ? price * count : undefined),
    [price, count],
  );
  const willGet = React.useMemo(
    () => (sellPrice && count ? sellPrice * count : undefined),
    [sellPrice, count],
  );
  const diff = React.useMemo(
    () => (paid && willGet ? willGet - paid : undefined),
    [paid, willGet],
  );

  return {
    paid,
    willGet,
    diff,
    sellPrice,
    setSellPrice,
  };
}

export default function Calculator() {
  const { t } = useTranslation("Calculator");
  const user = React.useContext(UserContext);
  const currentBuyin = useMyWeeklyBuyin(user);
  const updateBuyin = useUpdateBuyin();
  const { paid, willGet, diff, sellPrice, setSellPrice } = useCalculator({
    price: currentBuyin?.price,
    count: currentBuyin?.count,
  });

  if (user === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="blue" />
      </Box>
    );
  }

  if (user === null) {
    return null;
  }

  return (
    <Section variant="blue">
      <Card variant="blue">
        <Box mb={2} display="flex" flexDirection="column" alignItems="center">
          <Box color="info.light">
            <Icon className="fas fa-calculator" fontSize="large" />
          </Box>
          <Box component="h2" color="info.main">
            {t("title")}
          </Box>
          <Box textAlign="center">
            {t("desc1")}
            <br />
            {t("desc2")}
          </Box>
        </Box>
        <Box
          mt={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <TextField
            color="mint"
            // @ts-ignore
            variant="outlined"
            label={t("priceLabel")}
            placeholder={t("pricePlaceholder")}
            type="number"
            style={{ width: "100%", maxWidth: 300 }}
            defaultValue={currentBuyin?.price}
            value={currentBuyin?.price}
            onChange={(e: any) => {
              updateBuyin({ user, price: Number(e.target.value) });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box style={{ color: "rgba(0,129,96,1)" }}>{t("bells")}</Box>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          mt={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <TextField
            color="mint"
            // @ts-ignore
            variant="outlined"
            label={t("countLabel")}
            placeholder={t("countPlaceholder")}
            type="number"
            style={{ width: "100%", maxWidth: 300 }}
            defaultValue={currentBuyin?.count}
            value={currentBuyin?.count}
            onChange={(e: any) => {
              updateBuyin({ user, count: Number(e.target.value) });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box style={{ color: "rgba(0,129,96,1)" }}>{t("unit")}</Box>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          mt={4}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <TextField
            color="blue"
            // @ts-ignore
            variant="outlined"
            label={t("sellPriceLabel")}
            placeholder={t("sellPricePlaceholder")}
            type="number"
            style={{ width: "100%", maxWidth: 300 }}
            defaultValue={sellPrice}
            value={sellPrice}
            onChange={(e: any) => {
              setSellPrice(Number(e.target.value));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box style={{ color: "rgba(104,161,215,1)" }}>
                    {t("bells")}
                  </Box>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          width="100%"
          maxWidth={300}
          color="text.primary"
          fontSize={16}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width={180}
          >
            <Box fontWeight="fontWeightMedium" width={60} mr={2} fontSize={14}>
              {t("paid")}
            </Box>
            <Box width={120} textAlign="right">
              {paid ? paid.toLocaleString() : "-"}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width={180}
          >
            <Box fontWeight="fontWeightMedium" width={60} mr={2} fontSize={14}>
              {t("willGet")}
            </Box>
            <Box width={120} textAlign="right">
              {willGet ? willGet.toLocaleString() : "-"}
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            mt={2}
            borderColor="text.primary"
            borderTop={2}
            width={180}
          >
            <Box
              fontWeight="fontWeightMedium"
              width={60}
              mr={2}
              fontSize={14}
            ></Box>
            <Box width={120} textAlign="right">
              {diff ? diff.toLocaleString() : "-"}
            </Box>
          </Box>
        </Box>
      </Card>
      <FlightPatreon />
    </Section>
  );
}
