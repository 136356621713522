export enum Region {
  JAPAN = "Japan",
  THE_AMERICAS = "The Americas",
  EUROPE = "Europe",
  AUSTRALIA_NEW_ZEALAND = "Australia/New Zealand",
  HONG_KONG_TAIWAN_SOUTH_KOREA = "Hong Kong/Taiwan/South Korea",
}

export enum SpecialCharacter {
  CELESTE = "CELESTE",
  CJ = "CJ",
  DAISY_MAE = "DAISY_MAE",
  FLICK = "FLICK",
  KICKS = "KICKS",
  LEIF = "LEIF",
  REDD = "REDD",
  SAHARAH = "SAHARAH",
  TIMMY_AND_TOMMY = "TIMMY_AND_TOMMY",
}

export enum Terminal {
  TURNIPS = "TURNIPS",
  SPECIAL_VISITORS = "SPECIAL_VISITORS",
  FLEA_MARKET = "FLEA_MARKET",
  GENERAL = "GENERAL",
}

export enum TurnipsFlightType {
  SELLING = "SELLING", // High price for selling
  BUYING = "BUYING", // Daisy Mae is on island
}

export enum QueueType {
  STANDARD = "STANDARD",
  FIRST_CLASS = "FIRST_CLASS",
}

export enum PatreonTier {
  SUPPORTER = "SUPPORTER",
  RESIDENT = "RESIDENT",
  RESIDENT_PLUS = "RESIDENT_PLUS",
  CITIZEN = "CITIZEN",
  COUNCILOR = "COUNCILOR",
  INVESTOR = "INVESTOR",
  INVESTOR_PLUS = "INVESTOR_PLUS",
  STREAMER = "STREAMER",
  STREAMER_PLUS = "STREAMER_PLUS",
}

export enum FlightPassengerType {
  ALL = "ALL",
  TRUSTED = "TRUSTED",
}

export enum RequirePasswordOption {
  YES = "YES",
  NO = "NO",
}

export interface FlightPublic {
  flightPassengerType?: FlightPassengerType;
  hostStatus?: "online" | "offline";
  lastChangedAt?: number;
  maxAllowed?: number;
  maxStayTime?: number;
  maxQueueSize?: number;
  message?: string;
  planToCloseAt?: string | number;
  price?: number;
  queueLocked?: boolean;
  requirePassword?: boolean;
  specialCharacter?: SpecialCharacter;
  status: "open" | "closed";
  terminal?: Terminal;
  turnipsFlightType?: TurnipsFlightType;
}

export interface FlightPrivate {
  code?: string;
  password?: string;
}

export type Flight = FlightPublic & FlightPrivate;

export type User = firebase.User | null | undefined;

export interface Passenger {
  uid: string;

  joinedAt: Date;
  joinedAtRaw: number;
  priority: QueueType;
}
