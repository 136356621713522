import { ReportApi } from "../api";
import React from "react";
import { User, Report } from "../types/data";
import { createSafeDate } from "../shared/utils";

function mapReport(key: string, value: any) {
  return {
    uid: key,
    reason: typeof value === "object" ? value.reason : undefined,
    createdAt: createSafeDate(
      typeof value === "object" ? value.createdAt : value,
    ),
  };
}

export default function useReport(user: User, flightCreatorId: string) {
  const [state, setState] = React.useState<Report[]>([]);

  React.useEffect(() => {
    if (!user) return;

    const callback = ReportApi.ref(flightCreatorId).on(
      "child_added",
      (snapshot) => {
        setState((prev) => [...prev, mapReport(snapshot.key!, snapshot.val())]);
      },
    );

    return () => {
      ReportApi.ref(flightCreatorId).off("child_added", callback);
    };
  }, [flightCreatorId, user]);

  const reportedByCurrentUser = React.useMemo(() => {
    return user ? state.find(({ uid }) => uid === user.uid) : undefined;
  }, [state, user]);

  return {
    reportedByCurrentUser,
    reports: state,
  };
}
