import React from "react";
import get from "lodash.get";
import { FlightApi } from "../apis/flight";

// Note: should only be called for Hosts
export default function useFlightPassword(
  flightCreatorId: string | undefined,
  shouldRun: boolean,
) {
  const [state, setState] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (!flightCreatorId || !shouldRun) return;

    const ref = FlightApi.refPrivate(flightCreatorId);
    const callback = ref.on("value", (snapshot) => {
      const password = get(snapshot.val(), "password", "-");
      setState(password);
    });

    return () => {
      ref.off("value", callback);
    };
  }, [flightCreatorId, shouldRun]);

  return state;
}
