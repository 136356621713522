import { makeStyles } from "@material-ui/core";
import { SelectProps } from "./Select.types";

const COLOR: { [k in SelectProps["color"]]: string | undefined } = {
  blue: "rgba(104,161,215,1) !important",
  cyan: "rgba(0, 125, 117, 1) !important",
  mint: "rgba(0,129,96,1) !important",
  yellow: "rgba(241,226,111,1) !important",
};

const ERROR_COLOR = "#f5222d";

export const useStyles = makeStyles((theme) => ({
  outline: ({ color }: SelectProps) => {
    return {
      borderColor: COLOR[color],
    };
  },
  input: ({ color }: SelectProps) => {
    return {
      color: COLOR[color],
    };
  },
  label: ({ color }: SelectProps) => {
    return {
      color: COLOR[color],
      "& ~ .MuiFormHelperText-root": {
        color: COLOR[color],
      },
    };
  },
  inputRoot: ({ color }: SelectProps) => {
    return {
      color: COLOR[color],
      "& ~ .MuiOutlinedInput-notchedOutline": {
        borderColor: COLOR[color],
      },
    };
  },
  outlineError: {
    borderColor: ERROR_COLOR,
  },
  inputError: {
    color: ERROR_COLOR,
  },
  labelError: {
    color: ERROR_COLOR,
    "& ~ .MuiFormHelperText-root": {
      color: ERROR_COLOR,
    },
  },
  inputRootError: {
    color: ERROR_COLOR,
    "& ~ .MuiOutlinedInput-notchedOutline": {
      borderColor: ERROR_COLOR,
    },
  },
}));
