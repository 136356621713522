import { useSnackbar } from "notistack";
import { getDay } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import Button from "../../shared/components/Button/Button";
import Card from "../../shared/components/Card/Card";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import Section from "../../shared/components/Section/Section";
import TextField from "../../shared/components/TextField/TextField";
import firebase from "../../shared/firebase";
import UserContext from "../../shared/UserContext";

// TODO: refactor, use mui input validation, there must be something like this
function usePriceField() {
  const [field, setField] = React.useState<number | undefined>();
  const [error] = React.useState<string | null | undefined>(undefined);
  const onChangeField = React.useCallback((e) => {
    const newValue = Number(e.target.value);

    setField(newValue === 0 ? undefined : newValue);
  }, []);

  return {
    validated: field && field > 0,
    error,
    field,
    onChangeField,
  };
}

// TODO: refactor
function updatePriceDataTracking() {
  const randomStuffRef = firebase.firestore().collection("random").doc("stuff");
  randomStuffRef.get().then((snapshot) => {
    if (!snapshot.exists) {
      randomStuffRef.set({
        priceCounter: 1,
        priceLastSubmittedAt: firebase.database.ServerValue.TIMESTAMP,
      });
    } else {
      randomStuffRef.update({
        priceCounter: snapshot.get("priceCounter")
          ? snapshot.get("priceCounter") + 1
          : 1,
        priceLastSubmittedAt: firebase.database.ServerValue.TIMESTAMP,
      });
    }
  });
}

function useSubmitPrice() {
  const { t } = useTranslation("useSubmitPrice");

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = React.useState<string | null | undefined>(
    undefined,
  );
  const submit = React.useCallback(
    ({
      user,
      price,
      date,
      isPublic,
    }: {
      user: firebase.User;
      price: number;
      date: Date;
      isPublic: boolean;
    }) => {
      const data = {
        uid: user.uid,
        price,
        date,

        public: isPublic,
        createdAt: new Date(),
      };
      firebase
        .firestore()
        .collection("prices")
        .add(data)
        .then(function () {
          enqueueSnackbar(t("submitted"), {
            variant: "success",
          });
          history.push("/tracking");
          updatePriceDataTracking();

          setTimeout(() => {
            firebase.analytics().logEvent("Submit Price Succeeded", {
              data,
            });
          });
        })
        .catch(function (error) {
          enqueueSnackbar(t("failed"), {
            variant: "error",
          });
          setError(error.message);

          setTimeout(() => {
            firebase.analytics().logEvent("Submit Price Failed", {
              data,
              error,
            });
          });
        });
    },
    [history, t, enqueueSnackbar],
  );

  return {
    error,
    submit,
  };
}

// TODO: refactor all forms
// TODO: show error message below form
export default function SubmitPrice() {
  const user = React.useContext(UserContext);
  const { t } = useTranslation("SubmitPrice");

  const {
    field: price,
    onChangeField: onChangePrice,
    error,
    validated: priceValidated,
  } = usePriceField();
  const [date, setDate] = React.useState(new Date());
  const { error: submitError, submit } = useSubmitPrice();

  const isDateNotSunday = getDay(date) !== 0;
  const formValidated = priceValidated && isDateNotSunday;

  if (user === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="blue" />
      </Box>
    );
  }

  if (user === null) {
    return (
      <Redirect to="/sign-in?message=Please%20sign%20in%20before%20submitting%20price%21&returnUrl=%2Fsubmit-price" />
    );
  }

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
      </Section>
      <Section variant="blue">
        <Box component="p" textAlign="center">
          {t("sec2.desc1")}
          <br /> {t("sec2.desc2")}
        </Box>
        <Card variant="blue">
          <KeyboardDateTimePicker
            value={date}
            onChange={(date: any) => {
              setDate(date);
            }}
            minDate={new Date("2020-03-20")}
            maxDate={new Date()}
            format="yyyy-MM-dd HH:mm"
            InputProps={{
              disableUnderline: true,
            }}
            style={{
              width: 160,
              alignSelf: "flex-end",
              marginBottom: 16,
            }}
          />
          <TextField
            color="blue"
            // @ts-ignore
            variant="outlined"
            label={t("sec2.priceLabel")}
            placeholder={t("sec2.pricePlaceholder")}
            type="number"
            style={{ width: "100%" }}
            value={price}
            error={error}
            helperText={error}
            onChange={onChangePrice}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box style={{ color: "rgba(104,161,215,1)" }}>
                    {t("sec2.bells")}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
          {!isDateNotSunday && (
            <Box mt={2} color="error.main">
              {t("cannotSubmitForSunday")}
            </Box>
          )}
          {submitError && (
            <Box mt={2} color="error.main">
              {submitError}
            </Box>
          )}
          <Box mt={2}>
            <Button
              variant="blue"
              icon={
                <Icon
                  className="fas fa-chevron-right"
                  style={{ fontSize: 14 }}
                />
              }
              // @ts-ignore
              disabled={!formValidated}
              onClick={() => {
                submit({
                  user,
                  date,
                  isPublic: true,
                  price: price!,
                });
              }}
            >
              {t("sec2.submit")}
            </Button>
          </Box>
        </Card>
      </Section>
    </Box>
  );
}
