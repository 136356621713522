import { useTranslation } from "react-i18next";
import React from "react";
import getDay from "date-fns/fp/getDay";
import isBefore from "date-fns/fp/isBefore";
import {
  getTomorrowEightAm,
  getNoon,
  formatDistanceWithoutSuffix,
} from "../shared/utils";

// TODO: refactor
export default function useTradingTimerLabel() {
  const { t } = useTranslation("useTradingTimerLabel");
  const [label, setLabel] = React.useState("");

  React.useEffect(() => {
    const tick = () => {
      const now = new Date();
      const dayOfWeek = getDay(now);
      const noon = getNoon(now);
      const tomorrowEightAm = getTomorrowEightAm(now);

      if (dayOfWeek === 0) {
        if (isBefore(new Date(), noon)) {
          return setLabel(
            t("timeLeftForBuying", {
              time: formatDistanceWithoutSuffix(noon)(now),
            }),
          );
        }

        return setLabel(
          t("buyingClosed", {
            time: formatDistanceWithoutSuffix(tomorrowEightAm)(now),
          }),
        );
      }

      return setLabel(
        t("timeLeftForSelling", {
          time: 7 - dayOfWeek,
        }),
      );
    };
    tick();
    const interval = setInterval(tick, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [setLabel, t]);

  return label;
}
