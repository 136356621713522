import { TerminalType } from "./../types/data";
import { Region, Period, SpecialCharacter } from "../types/data";

export const REGION_LABEL: { [k in Region]: string } = {
  [Region.JAPAN]: "🇯🇵",
  [Region.THE_AMERICAS]: "🇺🇸",
  [Region.EUROPE]: "🇪🇺",
  [Region.AUSTRALIA_NEW_ZEALAND]: "🇳🇿",
  [Region.HONG_KONG_TAIWAN_SOUTH_KOREA]: "🇭🇰",
};

export const PERIOD_LABEL: { [k in Period]: string } = {
  [Period.MORNING]: "AM",
  [Period.AFTERNOON]: "PM",
};

export const SPECIAL_CHARACTERS = {
  [SpecialCharacter.CELESTE]: {
    displayName: "specialCharacterCeleste",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FCeleste.png?alt=media&token=e63ee37f-132c-456d-9419-292f7517ed62",
    role:
      "Celeste will randomly visit the player's island. During meteor showers, she will always be on the player's island watching the stars. She tells the player how to wish on shooting stars and gives the player recipes for the star wand and zodiac furniture. When presented with a Zodiac star fragment or Zodiac furniture, she will tell the player a mythological tale about the constellation.",
  },
  [SpecialCharacter.CJ]: {
    displayName: "specialCharacterCJ",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FCJ.png?alt=media&token=b3ffdc04-9b4b-4a5c-a4d4-1ba47385515e",
    role:
      "During Fishing Tourneys or random visits, C.J. will buy fish from the player for 150% of their usual value. ",
  },
  [SpecialCharacter.DAISY_MAE]: {
    displayName: "specialCharacterDaisyMae",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FDaisy%20Mae.png?alt=media&token=4dfdb21e-a8c8-4517-bfe4-b977247005e6",
    role:
      "Daisy Mae arrives at the player's island every Sunday morning (from 5 AM to 12 PM) to sell turnips at a specific price, taking on the role from her grandmother Joan. The turnips must then be sold before the next Sunday, or they will rot and lose all value.",
  },
  [SpecialCharacter.FLICK]: {
    displayName: "specialCharacterFlick",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FFlick.png?alt=media&token=1dfe9feb-e5ef-4711-b9c4-77aba24f4a75",
    role:
      "During Bug Offs (which happen once every season i.e. spring, summer, winter etc.) or random visits, Flick will buy bugs from the player for 150% of their usual value. Flick is also an artist and the player can commission a model of a specific bug from him by bringing him three of the same bug; Flick will work on only one commission at a time, and mail it to the player the next day.",
  },
  [SpecialCharacter.KICKS]: {
    displayName: "specialCharacterKicks",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FKicks.png?alt=media&token=833f10ac-8bf6-4ea1-9c06-23c54dcf8b8b",
    role:
      "Kicks appears in the Resident Services plaza once per week as a traveling peddler after the player constructs the Able Sisters shop on their island. He sells shoes, socks, and bags, and offers his services from 5am to 10pm.",
  },
  [SpecialCharacter.LEIF]: {
    displayName: "specialCharacterLeif",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FLeif.png?alt=media&token=d72512ac-0a55-409e-8a70-1336563b17d0",
    role:
      "Leif sells a selection of shrubs and flower seeds that are not normally sold by Nook's Cranny. He also offers to buy weeds for 20 bells each, double the normal price.",
  },
  [SpecialCharacter.REDD]: {
    displayName: "specialCharacterRedd",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FRedd.png?alt=media&token=80de1cf0-9233-42ad-87b6-ddd543d284bb",
    role:
      "Redd runs his black market shop out of an old fishing boat called Jolly Redd's Treasure Trawler that anchors at the secret beach on the northern coast of the island.",
  },
  [SpecialCharacter.SAHARAH]: {
    displayName: "specialCharacterSaharah",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FSaharah.png?alt=media&token=e6dd1730-fd95-460a-8c9a-8265d0c4dd67",
    role:
      "Saharah makes her living selling rare and exotic carpets from faraway lands.",
  },
  [SpecialCharacter.TIMMY_AND_TOMMY]: {
    displayName: "specialCharacterTimmyAndTommy",
    image:
      "https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fcharacters%2FTimmy%20and%20Tommy.png?alt=media&token=770a693c-a582-4a77-8858-9db0706a8fc0",
    role:
      "Timmy and Tommy are both in the shop to buy and sell wares during their service hours of 8am to 10pm",
  },
};

export const SPECIAL_CHARACTERS_TO_CHOOSE = [
  SpecialCharacter.CELESTE,
  SpecialCharacter.KICKS,
  SpecialCharacter.LEIF,
  SpecialCharacter.REDD,
  SpecialCharacter.SAHARAH,
];

export const TERMINALS = {
  [TerminalType.TURNIPS]: {
    displayName: "terminalTurnipsName", // i18next keys
    description: "terminalTurnipsDesc",
  },
  [TerminalType.SPECIAL_VISITORS]: {
    displayName: "terminalSpecialVisitorsName",
    description: "terminalSpecialVisitorsDesc",
  },
  [TerminalType.FLEA_MARKET]: {
    displayName: "terminalFleaMarketName",
    description: "terminalFleaMarketDesc",
  },
  [TerminalType.GENERAL]: {
    displayName: "terminalGeneralName",
    description: "terminalGeneralDesc",
  },
};
