import React from "react";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

export interface FlightQueueFaqProps {
  openFAQDialog: () => void;
}
export default function FlightQueueFaq({ openFAQDialog }: FlightQueueFaqProps) {
  return (
    <React.Fragment>
      <Box
        position="absolute"
        top={32}
        right={32}
        onClick={openFAQDialog}
        color="text.primary"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: "pointer", fontSize: 12 }}
      >
        <Icon
          className="fas fa-question-circle"
          style={{
            color: "inherit",
            marginRight: 4,
            fontSize: 12,
          }}
        />
        <Box style={{ textDecoration: "underline" }}>FAQ</Box>
      </Box>
    </React.Fragment>
  );
}
