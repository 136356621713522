import { compose } from "ramda";
import setHours from "date-fns/fp/setHours";
import setMinutes from "date-fns/fp/setMinutes";
import setSeconds from "date-fns/fp/setSeconds";
import addDays from "date-fns/fp/addDays";
import addHours from "date-fns/fp/addHours";
import addMinutes from "date-fns/fp/addMinutes";
import subDays from "date-fns/fp/subDays";
import subWeeks from "date-fns/fp/subWeeks";
import startOfDay from "date-fns/fp/startOfDay";
import formatWithOptions from "date-fns/fp/formatWithOptions";
import formatDistanceWithOptions from "date-fns/fp/formatDistanceWithOptions";
import differenceInMinutes from "date-fns/fp/differenceInMinutes";
import isAfter from "date-fns/fp/isAfter";
import isBefore from "date-fns/fp/isBefore";

export function get24HoursAgo(now = new Date()) {
  return compose(subDays(1))(now);
}

export function getMorning(now = new Date()) {
  return compose(setHours(8), setMinutes(0), setSeconds(0))(now);
}

export function getNoon(now = new Date()) {
  return compose(setHours(12), setMinutes(0), setSeconds(0))(now);
}

export function getEvening(now = new Date()) {
  return compose(setHours(22), setMinutes(0), setSeconds(0))(now);
}

export function getTomorrowEightAm(now = new Date()) {
  return compose(setHours(8), setMinutes(0), setSeconds(0), addDays(1))(now);
}

export function getNWeeksAgo(n: number, now = new Date()) {
  return compose(startOfDay, subWeeks(n))(now);
}

export function getInOneHour(now = new Date()) {
  return compose(addHours(1))(now);
}

export function getInThirtyMinutes(now = new Date()) {
  return compose(addMinutes(30))(now);
}

export const format = (format: string) => {
  try {
    return formatWithOptions({
      locale: window.__locale,
    })(format);
  } catch (error) {
    console.error(error);
    return () => "unknown";
  }
};

export const formatDistance = (date: Date) => {
  try {
    return formatDistanceWithOptions({
      addSuffix: true,
      locale: window.__locale,
    })(date);
  } catch (error) {
    console.error(error);
    return () => "unknown";
  }
};

export const formatDistanceNow = (date: Date) => {
  try {
    return formatDistance(new Date())(date);
  } catch (error) {
    console.error(error);
    return "unknown";
  }
};

export const formatDistanceWithoutSuffix = (date: Date) => {
  try {
    return formatDistanceWithOptions({
      addSuffix: false,
      locale: window.__locale,
    })(date);
  } catch (error) {
    console.error(error);
    return () => "unknown";
  }
};

export const formatDistanceWithoutSuffixNow = (date: Date) => {
  try {
    return formatDistanceWithoutSuffix(new Date())(date);
  } catch (error) {
    console.error("formatDistanceWithoutSuffixNow", error);
    return "unknown";
  }
};

export const hasBeen15Mins = (date?: Date) => {
  if (!date) return false;

  try {
    return differenceInMinutes(date)(new Date()) > 15;
  } catch (error) {
    console.error(error);
    return false;
  }
};

// Naive solution to add a space to prevent Twitter auto linking
export function undoTwitterSpecials(s?: string): string {
  if (!s) return "";
  return s.replace(/([@#.])/gi, "$1 ");
}

export function isDate(d: any) {
  return d instanceof Date && !isNaN(d.valueOf());
}

export function createSafeDate(a: any) {
  const d = new Date(a);

  if (isDate(d)) {
    return d;
  }

  return new Date();
}

export function getDateOrUndefined(a: any) {
  const d = new Date(a);

  if (isDate(d)) {
    return d;
  }

  return undefined;
}

export function isWithinHours(hours: number) {
  return (d: Date) => {
    const now = new Date();
    return isAfter(now)(d) && isBefore(addHours(hours)(now))(d);
  };
}

export function isWithinMinutes(minutes: number) {
  return (d: Date) => {
    const now = new Date();
    return isAfter(now)(d) && isBefore(addMinutes(minutes)(now))(d);
  };
}
