import React from "react";
import useProfile from "../../../hooks/useProfile";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { PatreonTier, ProfileForm } from "../../../types/data";
import PatreonBadge from "../PatreonBadge";
import VerificationBadge from "../VerificationBadge";

export interface DisplayNameLabelProps {
  uid?: string;
  overwrite?: string;
  fallback?: string;
  color?: string;
  fontWeight?: string;
  enableLink?: boolean;
  showPatreon?: boolean;
}

export function getColor(tier: PatreonTier | undefined, overwrite?: string) {
  if (overwrite) {
    return overwrite;
  }
  if (tier === undefined) {
    return "#6b5c43";
  }

  switch (tier) {
    case PatreonTier.SUPPORTER:
      return "#6b5c43";
    case PatreonTier.RESIDENT:
    case PatreonTier.RESIDENT_PLUS:
    case PatreonTier.STREAMER:
      return "#4b9fd8";
    case PatreonTier.CITIZEN:
    case PatreonTier.STREAMER_PLUS:
      return "#3789c9";
    case PatreonTier.COUNCILOR:
      return "#F9B444";
    case PatreonTier.INVESTOR:
    case PatreonTier.INVESTOR_PLUS:
      return "#e95454";
  }
}

export function getFontWeight(
  tier: PatreonTier | undefined,
  overwrite?: string,
) {
  if (overwrite) {
    return overwrite;
  }

  if (tier === undefined) {
    return "fontWeightRegular";
  }

  return "fontWeightBold";
}

export function getPrefixBadge(profile: ProfileForm | undefined) {
  const isStreamer = !!profile?.mixer || !!profile?.twitch;

  if (!isStreamer) {
    return null;
  }

  const shouldShowPlusBadge = ([
    PatreonTier.CITIZEN,
    PatreonTier.COUNCILOR,
    PatreonTier.STREAMER_PLUS,
  ] as string[]).includes(profile?.patreon || "");
  return (
    <PatreonBadge
      tier={
        shouldShowPlusBadge ? PatreonTier.STREAMER_PLUS : PatreonTier.STREAMER
      }
      style={{ marginRight: 4 }}
    />
  );
}

export function getSuffixBadge(profile: ProfileForm | undefined) {
  const patreon = profile?.patreon;

  if (!patreon || !(Object.values(PatreonTier) as string[]).includes(patreon)) {
    return null;
  }

  if ([PatreonTier.STREAMER, PatreonTier.STREAMER_PLUS].includes(patreon)) {
    // will be shown as prefix badge
    return null;
  }

  return <PatreonBadge tier={profile!.patreon!} style={{ marginRight: 4 }} />;
}

export default function DisplayNameLabel({
  uid,
  overwrite,
  fallback,
  color,
  fontWeight,
  enableLink = false,
  showPatreon = true,
}: DisplayNameLabelProps) {
  const profile = useProfile(uid);
  const { t } = useTranslation("DisplayNameLabel");

  const verified = profile?.verificationLevel !== undefined;
  const prefixBadge = showPatreon && getPrefixBadge(profile);
  const suffixBadge = showPatreon && getSuffixBadge(profile);

  return (
    <Box
      display="inline-block"
      onClick={
        enableLink
          ? () => {
              if (profile?.id) {
                // WARNNING: this is hard coded url
                window.open(`https://turnips.exchange/passport/${profile!.id}`)
              }
            }
          : undefined
      }
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {prefixBadge}
        <Box
          mr={0.5}
          color={getColor(profile?.patreon, color)}
          fontWeight={getFontWeight(profile?.patreon, fontWeight)}
          style={{ filter: verified ? undefined : "grayscale(1)" }}
        >
          {overwrite ||
            profile?.displayName ||
            fallback ||
            t("displayNameDefault", { ns: "shared" })}
        </Box>
        {suffixBadge}
        {verified && <VerificationBadge verified={verified} />}
      </Box>
    </Box>
  );
}
