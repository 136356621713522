import isBefore from "date-fns/fp/isBefore";
import { QueueApi } from "./../api";
import { Passenger, QueueType } from "./../types/all";
import React from "react";

export default function useFlightQueue(flightId: string | undefined) {
  const [state, setState] = React.useState<Passenger[] | undefined>(undefined);

  React.useEffect(() => {
    if (!flightId) {
      return;
    }

    const ref = QueueApi.ref(flightId);
    const callback = ref.on("value", (snapshot) => {
      // console.log("on: flight queue");
      if (!snapshot.exists()) {
        setState([]);
        return;
      }

      const val = snapshot.val();
      const list: Passenger[] = Object.keys(val)
        .map((uid) => {
          const passengerRaw = val[uid];
          return {
            uid,
            joinedAt: new Date(passengerRaw.joinedAt),
            joinedAtRaw: passengerRaw.joinedAt,
            priority: passengerRaw.priority || QueueType.STANDARD,
          };
        })
        .sort((a, b) => (isBefore(b.joinedAt)(a.joinedAt) ? -1 : 1));

      // console.log(val, list);
      setState(list);
      return;
    });

    return () => {
      // console.log("off: flight queue");
      ref.off("value", callback);
    };
  }, [flightId]);

  return state;
}
