import React from "react";
import firebase from "../../../shared/firebase";
import TextField from "../../../shared/components/TextField/TextField";
import Box from "@material-ui/core/Box";
import Button from "../../../shared/components/Button/Button";
import Icon from "@material-ui/core/Icon";
import { useTranslation } from "react-i18next";

export function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(v: string) {
  return RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/).test(v);
}

function useForm() {
  const { t } = useTranslation("SignUpWithEmail");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordRepeat, setPasswordRepeat] = React.useState("");
  const [error, setError] = React.useState<{
    email?: string | null;
    password?: string | null;
    passwordRepeat?: string | null;
  }>({
    email: undefined,
    password: undefined,
    passwordRepeat: undefined,
  });
  const onChangeEmail = React.useCallback(
    (e) => {
      const newValue = e.target.value;

      setEmail(newValue);

      if (newValue === undefined) {
        return;
      }

      if (validateEmail(newValue)) {
        setError((prev) => ({
          ...prev,
          email: null,
        }));
      } else {
        setError((prev) => ({
          ...prev,
          email: t("emailError"),
        }));
      }
    },
    [t],
  );
  const onChangePassword = React.useCallback(
    (e) => {
      const newValue = e.target.value;

      setPassword(newValue);

      if (newValue === undefined) {
        return;
      }

      if (validatePassword(newValue)) {
        setError((prev) => ({
          ...prev,
          password: null,
        }));
      } else {
        setError((prev) => ({
          ...prev,
          password: t("passwordError"),
        }));
      }
    },
    [t],
  );
  const onChangePasswordRepeat = React.useCallback(
    (e) => {
      const newValue = e.target.value;

      setPasswordRepeat(newValue);

      if (newValue === undefined) {
        return;
      }

      if (newValue === password) {
        setError((prev) => ({
          ...prev,
          passwordRepeat: null,
        }));
      } else {
        setError((prev) => ({
          ...prev,
          passwordRepeat: t("passwordRepeatError"),
        }));
      }
    },
    [t, password],
  );

  return {
    validated: email && error === null,
    valid: Object.values(error).reduce((acc, cur) => acc && cur === null, true),
    error,
    email,
    password,
    passwordRepeat,
    onChangeEmail,
    onChangePassword,
    onChangePasswordRepeat,
  };
}

export default function SignInWithEmail() {
  const { t } = useTranslation("SignUpWithEmail");

  const {
    email,
    password,
    passwordRepeat,
    onChangeEmail,
    onChangePassword,
    onChangePasswordRepeat,
    error,
    valid,
  } = useForm();
  const [submitting, setSubmitting] = React.useState(false);
  const [signUpError, setSignUpError] = React.useState<string | undefined>(
    undefined,
  );

  const signUpWithEmailAndPassword = React.useCallback(
    (email: string, password: string) => {
      setSubmitting(true);
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          setSubmitting(false);
          setSignUpError(undefined);
          window.location.reload();
        })
        .catch(function (error) {
          setSubmitting(false);
          setSignUpError(error.message);
        });
    },
    [],
  );

  return (
    <React.Fragment>
      <TextField
        color="mint"
        // @ts-ignore
        variant="outlined"
        label={t("emailLabel")}
        placeholder={t("emailPlaceholder")}
        style={{ width: "100%" }}
        value={email}
        error={!!error.email}
        helperText={error.email}
        onChange={onChangeEmail}
        disabled={submitting}
        InputLabelProps={{ shrink: true }}
      />
      <Box mt={2} style={{ width: "100%" }}>
        <TextField
          color="mint"
          // @ts-ignore
          variant="outlined"
          label={t("passwordLabel")}
          placeholder={t("passwordPlaceholder")}
          value={password}
          type="password"
          style={{ width: "100%" }}
          error={!!error.password}
          helperText={error.password}
          onChange={onChangePassword}
          disabled={submitting}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box mt={2} style={{ width: "100%" }}>
        <TextField
          color="mint"
          // @ts-ignore
          variant="outlined"
          label={t("passwordRepeatLabel")}
          placeholder={t("passwordRepeatPlaceholder")}
          value={passwordRepeat}
          type="password"
          style={{ width: "100%" }}
          error={!!error.passwordRepeat}
          helperText={error.passwordRepeat}
          onChange={onChangePasswordRepeat}
          disabled={submitting}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
        <Button
          variant="mint"
          icon={
            <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
          }
          // @ts-ignore
          disabled={!valid || submitting}
          onClick={() => {
            if (!email || !password || !passwordRepeat || !valid) return;
            signUpWithEmailAndPassword(email, password);
          }}
        >
          {t("cta")}
        </Button>
      </Box>
      {signUpError && (
        <Box mt={2} color="error.main">
          {t("error", { error: signUpError })}
        </Box>
      )}
    </React.Fragment>
  );
}
