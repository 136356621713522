import { useTranslation } from "react-i18next";
import React from "react";

import firebase from "../shared/firebase";
import { Region, Profile, PatreonTier } from "../types/data";

export default function useProfileWithDefaultValue(uid: string | undefined) {
  const { t } = useTranslation("shared");
  const [data, setData] = React.useState<Profile>({
    displayName: t("displayNameUnknown"),
    profilePhoto: "",
    islandName: t("islandNameUnknown"),
    nsId: t("nsIdUnknown"),
    region: t("regionUnknown"),
    message: t("noMessage"),
    verificationLevel: undefined,
    patreon: undefined,
    dreamAddress: undefined,
  });

  React.useEffect(() => {
    if (!uid) return;

    firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .get()
      .then((snapshot) => {
        setData({
          id: snapshot.get("id") || undefined,
          displayName:
            snapshot.get("displayNameVerified") ||
            snapshot.get("displayName") ||
            t("displayNameUnknown"),
          profilePhoto: snapshot.get("profilePhoto") || undefined,
          islandName:
            snapshot.get("islandNameVerified") ||
            snapshot.get("islandName") ||
            t("islandNameUnknown"),
          nsId: snapshot.get("nsId") || t("nsIdUnknown"),
          region: Object.values(Region).includes(snapshot.get("region"))
            ? t(snapshot.get("region"))
            : t("regionUnknown"),
          message: snapshot.get("message") || t("noMessage"),
          patreon: Object.values(PatreonTier).includes(snapshot.get("patreon"))
            ? t(snapshot.get("patreon"))
            : undefined,
          verificationLevel: snapshot.get("verificationLevel") || undefined,
          mixer: snapshot.get("mixer") || undefined,
          twitch: snapshot.get("twitch") || undefined,
          dreamAddress: snapshot.get("dreamAddress") || undefined,
        });
      });
  }, [uid, setData, t]);

  return data;
}
