import { makeStyles } from "@material-ui/core";
import { ButtonProps } from "./Button.types";

const BACKGROUND_COLOR: { [k in ButtonProps["variant"]]: string } = {
  blue: "#2d6895",
  mint: "#007d75",
  brown: "rgb(107, 92, 67)",
  red: "#f5222d",
};

export const useStyles = makeStyles((theme) => ({
  container: ({ variant, disabled }: ButtonProps) => {
    return {
      backgroundColor: BACKGROUND_COLOR[variant],
      padding: "12px 16px",
      textAlign: "center",
      fontSize: "calc(15 / var(--font-base) * 1rem)",
      fontWeight: theme.typography.fontWeightBold,
      color: "white",
      borderRadius: 18,
      transition: "all 0.3s ease",
      opacity: disabled ? "0.5" : 1,
      cursor: disabled ? "not-allowed" : "pointer",
      "&:hover": {
        boxShadow: theme.shadows[6],
      },
    };
  },
}));
