import React from "react";
import { getDateOrUndefined } from "../shared/utils";
import { FlightApi } from "../api";

export interface FlightPrivateAccessed {
  codeShownAt: Date;
}

export function useFlightPrivateAccessed(
  flightId: string,
  uid: string,
  shouldRun: boolean = true,
) {
  const [state, setState] = React.useState<FlightPrivateAccessed | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (!shouldRun) return;

    const ref = FlightApi.refPrivateAccessedUser(flightId, uid);
    const callback = ref.on("value", (snapshot) => {
      if (!snapshot.val()) {
        return setState(undefined);
      }
      const codeShownAt = getDateOrUndefined(snapshot!.val().codeShownAt);

      if (codeShownAt === undefined) {
        return setState(undefined);
      }

      setState({
        codeShownAt,
      });
    });

    return () => {
      ref.off("value", callback);
    };
  }, [flightId, uid, shouldRun]);

  return state;
}
