import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";

export default function FlightPatreon() {
  const { t } = useTranslation();

  return (
    <Box
      mt={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      id="patreon"
    >
      <iframe
        title="patreon"
        width="100%"
        height={88}
        frameBorder="0"
        src="/patreon.html"
      />
      <Box color="text.secondary" px={4} mt={1} mb={2}>
        <Box fontSize={14} fontWeight="fontWeightBold">
          {t("supportMsgTitle", { ns: "shared" })}
        </Box>
        <Box fontSize={14}>{t("supportMsgDesc", { ns: "shared" })}</Box>
      </Box>
    </Box>
  );
}
