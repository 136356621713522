import { useSnackbar } from "notistack";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import { Flight, TerminalType, Profile } from "../../../types/data";
import { SPECIAL_CHARACTERS } from "../../../shared/constants";
import { undoTwitterSpecials } from "../../../shared/utils";

export interface FlightShareProps {
  flight: Flight;
  flightCreatorProfile: Profile;
}

export default function FlightShare({
  flight,
  flightCreatorProfile,
}: FlightShareProps) {
  const { t } = useTranslation("Flight");
  const { enqueueSnackbar } = useSnackbar();

  let shareText;

  if (
    flight.terminal === undefined ||
    flight.terminal === TerminalType.TURNIPS
  ) {
    shareText = t("share.turnips", {
      link: window.location.href,
      price: flight.price,
      islandName: undoTwitterSpecials(flightCreatorProfile.islandName),
    });
  } else if (flight.terminal === TerminalType.SPECIAL_VISITORS) {
    shareText = t("share.specialVisitors", {
      link: window.location.href,
      specialVisitor: t(
        SPECIAL_CHARACTERS[flight.specialCharacter!].displayName,
        {
          ns: "shared",
        },
      ),
      islandName: undoTwitterSpecials(flightCreatorProfile.islandName),
    });
  } else {
    shareText = t("share.general", {
      link: window.location.href,
      hostName: undoTwitterSpecials(flightCreatorProfile.displayName),
      islandName: undoTwitterSpecials(flightCreatorProfile.islandName),
    });
  }

  return (
    <Box
      mt={2}
      mb={-2}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {flight.status === "closed" ? (
        <Box fontSize={24} fontWeight="fontWeightBold">
          {t("flightEnded")}
        </Box>
      ) : (
        <CopyToClipboard
          text={shareText}
          onCopy={() => {
            enqueueSnackbar(t("copied"), {
              variant: "success",
            });
          }}
        >
          <IconButton>
            <Box
              mr={1}
              component={Icon}
              className="fas fa-share-square"
              color="info.main"
            />
            <Box fontSize={14} fontWeight="fontWeightBold">
              {t("shareCta")}
            </Box>
          </IconButton>
        </CopyToClipboard>
      )}
    </Box>
  );
}
