import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../shared/components/Button/Button";

export interface RequireSignInDialogProps {
  close: () => void;
  isOpen: boolean;
  disabled?: boolean;
}

export default function RequireSignInDialog({
  close,
  isOpen,
  disabled,
}: RequireSignInDialogProps) {
  const { t } = useTranslation("Flight");
  const history = useHistory();
  return (
    <Dialog open={isOpen} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {t("nonSignedInUserDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box component="p">{t("nonSignedInUserDialog.desc1")}</Box>
        <Box component="p">{t("nonSignedInUserDialog.desc2")}</Box>
        <Box
          mt={4}
          mb={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="blue"
              onClick={() => {
                history.push("/sign-in");
              }}
              disabled={disabled}
            >
              {t("nonSignedInUserDialog.signInWithMagicLink")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
