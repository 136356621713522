import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";

import Card from "../../shared/components/Card/Card";
import Section from "../../shared/components/Section/Section";
import useFlights from "../../hooks/useOpenFlights";
import Button from "../../shared/components/Button/Button";
import Ribbon from "../../shared/components/Ribbon/Ribbon";
import DepartureListTurnips from "./components/DepartureListTurnips";
import DepartureListSpecialVisitors from "./components/DepartureListSpecialVisitors";
import DepartureListGeneral from "./components/DepartureListGeneral";
import { Flight, TerminalType } from "../../types/data";
import DepartureListFleaMarket from "./components/DepartureListFleaMarket";
import { useLinkAppPromotionBanner, PROMOTION_TEXT_ID } from "../../hooks/useLinkAppPromotionBanner";

function filterGeneral(data: Flight[] | undefined) {
  if (data === undefined) return undefined;

  return data.filter(
    ({ terminal, requirePassword }) =>
      terminal === TerminalType.GENERAL && !requirePassword,
  );
}

function filterSpecialVisitors(data: Flight[] | undefined) {
  if (data === undefined) return undefined;

  return data.filter(
    ({ terminal, requirePassword }) =>
      terminal === TerminalType.SPECIAL_VISITORS && !requirePassword,
  );
}

function filterFleaMarket(data: Flight[] | undefined) {
  if (data === undefined) return undefined;

  return data.filter(
    ({ terminal, requirePassword }) =>
      terminal === TerminalType.FLEA_MARKET && !requirePassword,
  );
}

function filterTurnips(data: Flight[] | undefined) {
  if (data === undefined) return undefined;

  return data.filter(
    ({ terminal, requirePassword }) =>
      terminal === undefined ||
      (terminal === TerminalType.TURNIPS && !requirePassword),
  );
}

export interface AirportProps {
  openFAQDialog: () => void;
}

export default function Airport({ openFAQDialog }: AirportProps) {
  const { t } = useTranslation("Airport");
  const history = useHistory();
  const flights = useFlights();
  const turnipsFlights = React.useMemo(() => filterTurnips(flights), [flights]);
  const specialVisitorFlights = React.useMemo(
    () => filterSpecialVisitors(flights),
    [flights],
  );
  const fleaMarketFlights = React.useMemo(() => filterFleaMarket(flights), [
    flights,
  ]);
  const generalFlights = React.useMemo(() => filterGeneral(flights), [flights]);

  useLinkAppPromotionBanner(PROMOTION_TEXT_ID.TRY_NOW);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
        <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
          {t("sec1.desc1")}
        </Box>
      </Section>
      <Box my={2} px={4}>
        <Card variant="yellow">
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            Please read this to understand how Turnips Exchange works and to
            avoid violating any rules.
          </Box>
          <Button
            variant="brown"
            icon={
              <Icon className="fas fa-chevron-right" style={{ fontSize: 14 }} />
            }
            onClick={openFAQDialog}
          >
            Read Me!
          </Button>
        </Card>
      </Box>
      <Box px={4} mb={-9}>
        <Ribbon variant="mint">{t("ribbon1")}</Ribbon>
      </Box>
      <Section variant="blue">
        <Card variant="blue">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box color="info.main">
              <Icon className="fas fa-plane-departure" fontSize="large" />
            </Box>
            <Box component="h2" color="info.main">
              {t("sec2.title")}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec2.desc1")}
            <br />
            {t("sec2.desc2")}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component="a"
              // @ts-ignore
              href="#turnips"
              color="text.primary"
              mr={2}
            >
              Turnips{" "}
              {turnipsFlights?.length ? `(${turnipsFlights?.length})` : ""}
            </Box>
            <Box
              component="a"
              // @ts-ignore
              href="#special-visitors"
              color="text.primary"
              mr={2}
            >
              Special Visitors{" "}
              {specialVisitorFlights?.length
                ? `(${specialVisitorFlights?.length})`
                : ""}
            </Box>
            <Box
              component="a"
              // @ts-ignore
              href="#flea-market"
              color="text.primary"
              mr={2}
            >
              Flea Market{" "}
              {fleaMarketFlights?.length
                ? `(${fleaMarketFlights?.length})`
                : ""}
            </Box>
            <Box
              component="a"
              // @ts-ignore
              href="#general"
              color="text.primary"
            >
              General{" "}
              {generalFlights?.length ? `(${generalFlights?.length})` : ""}
            </Box>
          </Box>
        </Card>
        <Box
          my={4}
          id="turnips"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="separator"
            src="https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2Frule-confetti-white.svg?alt=media&token=8479761e-ffb6-4c68-a5aa-c126e79f1841"
            style={{ width: "60%", height: "auto" }}
          />
        </Box>
        <Card variant="blue">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="h2" color="info.main">
              {t("sec3.title")}{" "}
              {turnipsFlights?.length ? `(${turnipsFlights?.length})` : ""}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec3.desc1")}
            <br />
            <small style={{ opacity: 0.8 }}>{t("sec3.desc2")}</small>
          </Box>
          <DepartureListTurnips data={turnipsFlights} />
        </Card>
        <Box
          my={4}
          id="special-visitors"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="separator"
            src="https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2Frule-confetti-white.svg?alt=media&token=8479761e-ffb6-4c68-a5aa-c126e79f1841"
            style={{ width: "60%", height: "auto" }}
          />
        </Box>
        <Card variant="blue">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="h2" color="info.main">
              {t("sec4.title")}{" "}
              {specialVisitorFlights?.length
                ? `(${specialVisitorFlights?.length})`
                : ""}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec4.desc1")}
            <br />
            <small style={{ opacity: 0.8 }}>{t("sec4.desc2")}</small>
          </Box>
          <DepartureListSpecialVisitors data={specialVisitorFlights} />
        </Card>
        <Box
          my={4}
          id="flea-market"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="separator"
            src="https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2Frule-confetti-white.svg?alt=media&token=8479761e-ffb6-4c68-a5aa-c126e79f1841"
            style={{ width: "60%", height: "auto" }}
          />
        </Box>
        <Card variant="blue">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="h2" color="info.main">
              {t("sec5.title")}{" "}
              {fleaMarketFlights?.length
                ? `(${fleaMarketFlights?.length})`
                : ""}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec5.desc1")}
            <br />
            <small style={{ opacity: 0.8 }}>{t("sec5.desc2")}</small>
          </Box>
          <DepartureListFleaMarket data={fleaMarketFlights} />
        </Card>
        <Box
          my={4}
          id="general"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="separator"
            src="https://firebasestorage.googleapis.com/v0/b/turnips-exchange.appspot.com/o/assets%2Fgeneral%2Frule-confetti-white.svg?alt=media&token=8479761e-ffb6-4c68-a5aa-c126e79f1841"
            style={{ width: "60%", height: "auto" }}
          />
        </Box>
        <Card variant="blue">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="h2" color="info.main">
              {t("sec6.title")}{" "}
              {generalFlights?.length ? `(${generalFlights?.length})` : ""}
            </Box>
          </Box>
          <Box component="p" textAlign="center" fontWeight="fontWeightMedium">
            {t("sec6.desc1")}
            <br />
            <small style={{ opacity: 0.8 }}>{t("sec6.desc2")}</small>
          </Box>
          <DepartureListGeneral data={generalFlights} />
        </Card>
      </Section>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box mr={2}>{t("iWantTo")}</Box>
        <Button variant="blue" onClick={() => history.push("/flight/new")}>
          {t("hostBtn")}
        </Button>
      </Box>
    </Box>
  );
}
