import React from "react";
import { useStyles } from "./Button.styles";
import Box from "@material-ui/core/Box";
import { ButtonProps } from "./Button.types";

const Button: React.FC<ButtonProps> = (props) => {
  const classes = useStyles(props);

  const { disabled, onClick } = props;

  return (
    <Box
      {...props}
      className={classes.container}
      component="a"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      onClick={disabled ? undefined : onClick}
    >
      <Box>{props.children}</Box>

      {props.icon && (
        <Box ml={1} lineHeight={0}>
          {props.icon}
        </Box>
      )}
    </Box>
  );
};

export default Button;
