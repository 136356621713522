import firebase from "../shared/firebase";

export class QueueApi {
  static ref(flightId: string) {
    return firebase.database().ref(`flightQueue/${flightId}`);
  }

  static joinQueue(flightCreatorId: string) {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        reject("Permission denied.");
        return;
      }
      QueueApi.ref(flightCreatorId)
        .child(currentUser.uid)
        .set({
          joinedAt: firebase.database.ServerValue.TIMESTAMP,
        })
        .then(resolve)
        .catch(reject);
    });
  }

  static joinQueueFirstClass(flightCreatorId: string) {
    return firebase.functions().httpsCallable("queueJoinFirstClass")({
      flightCreatorId,
    });
  }

  static joinQueuePrivate(flightCreatorId: string, password: string) {
    return firebase.functions().httpsCallable("queueJoinPrivate")({
      flightCreatorId,
      password,
    });
  }

  // Calling remote API to ensure history
  static leaveQueue(flightCreatorId: string) {
    return firebase.functions().httpsCallable("queueLeave")({
      flightCreatorId,
    });
  }

  // Calling remote API to ensure history
  static tripFinished(flightCreatorId: string) {
    return firebase.functions().httpsCallable("queueTripFinished")({
      flightCreatorId,
    });
  }

  // Calling remote API to ensure history
  static removeFromQueue(uid: string) {
    return firebase.functions().httpsCallable("queueRemoveUser")({
      uid,
    });
  }
}
