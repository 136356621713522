import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";

import Box from "@material-ui/core/Box";

import { FlightApi } from "../../../../apis/flight";
import { QueueApi } from "../../../../apis/queue";
import Button from "../../../../shared/components/Button/Button";
import Loader from "../../../../shared/components/Loader";
import { User, FlightPassengerType } from "../../../../types/all";
import { Passenger } from "../../../../types/data";
import QueueListItem from "./QueueListItem";
import TextField from "../../../../shared/components/TextField/TextField";

export interface EconomyTabProps {
  queue: Passenger[] | undefined;
  flightClosed: boolean;
  flightCreatorId: string;
  flightMaxAllowed: number;
  flightQueueLocked: boolean;
  flightPassengerType: FlightPassengerType;
  flightRequirePassword: boolean;
  isInQueue: boolean;
  shouldShowCode: boolean;
  isHost: boolean;
  user: User;
  currentUserTrusted: boolean;
  openNonSignedInUserDialog: () => void;
}

export default function EconomyTab({
  queue,
  flightClosed,
  flightCreatorId,
  flightMaxAllowed,
  flightQueueLocked,
  flightPassengerType,
  flightRequirePassword,
  isInQueue,
  shouldShowCode,
  isHost,
  user,
  currentUserTrusted,
  openNonSignedInUserDialog,
}: EconomyTabProps) {
  const { t } = useTranslation("FlightQueueCard");
  const { enqueueSnackbar } = useSnackbar();

  const disableIfTrustedOnlyAndCurrentUserNotTrustAndNotInQueue =
    !isInQueue &&
    flightPassengerType === FlightPassengerType.TRUSTED &&
    !currentUserTrusted;

  const [password, setPassword] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const passwordValidated = password.length >= 4;

  const shouldShowPasswordInput = !isInQueue && flightRequirePassword;

  const joinOrLeaveQueue = () => {
    if (!user) {
      openNonSignedInUserDialog();
      return;
    }

    setSubmitting(true);

    if (isInQueue) {
      QueueApi[shouldShowCode ? "tripFinished" : "leaveQueue"](flightCreatorId)
        .then(() => {
          enqueueSnackbar(t("success.general", { ns: "shared" }), {
            variant: "success",
          });
          setSubmitting(false);
        })
        .catch((error: Error) => {
          setSubmitting(false);
          enqueueSnackbar(
            t("error.general", { ns: "shared", error: error.message }),
            {
              variant: "error",
            },
          );
        });
    } else {
      const request = flightRequirePassword
        ? QueueApi.joinQueuePrivate(flightCreatorId, password)
        : QueueApi.joinQueue(flightCreatorId);
      request
        .then(() => {
          enqueueSnackbar(t("success.general", { ns: "shared" }), {
            variant: "success",
          });
          setSubmitting(false);
        })
        .catch((error: Error) => {
          setSubmitting(false);
          enqueueSnackbar(
            t("error.general", { ns: "shared", error: error.message }),
            {
              variant: "error",
            },
          );
        });
    }
  };

  const lockOrUnlockQueue = () => {
    setSubmitting(true);
    FlightApi[flightQueueLocked ? "unlock" : "lock"]()
      .then(() => {
        enqueueSnackbar(t("success.general", { ns: "shared" }), {
          variant: "success",
        });
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(
          t("error.general", { ns: "shared", error: error.message }),
          {
            variant: "error",
          },
        );
      });
  };

  const renderItem = React.useCallback(
    (index: number) => {
      if (queue === undefined) return <React.Fragment></React.Fragment>;

      const passenger = queue[index];
      return (
        <QueueListItem
          passenger={passenger}
          index={index}
          isHost={isHost}
          user={user}
          flightCreatorId={flightCreatorId}
          shouldShowCode={index < flightMaxAllowed}
        />
      );
    },
    [flightCreatorId, flightMaxAllowed, isHost, queue, user],
  );

  if (queue === undefined) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      {queue.length === 0 && (
        <Box
          width="100%"
          py={2}
          textAlign="center"
          color="text.primary"
          style={{ opacity: 0.6 }}
        >
          {t("queueEmpty")}
        </Box>
      )}
      {queue.length > 0 && (
        <Virtuoso
          style={{ width: "100%", minHeight: 64, maxHeight: 176 }}
          totalCount={queue.length}
          overscan={3}
          item={renderItem}
        />
      )}
      {!flightClosed &&
        (isHost ? (
          <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Button
              variant="red"
              onClick={lockOrUnlockQueue}
              disabled={submitting}
            >
              {flightQueueLocked ? t("unlockQueue") : t("lockQueue")}
            </Button>
            <Box mt={2} fontSize={12}>
              {t("queueHostDesc1")}
            </Box>
            <Box fontSize={12}>{t("queueHostDesc2")}</Box>
          </Box>
        ) : (
          <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={
                shouldShowPasswordInput ? "space-between" : "center"
              }
              alignItems="center"
              width="100%"
            >
              {shouldShowPasswordInput && (
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  flexShrink={1}
                  mr={2}
                >
                  <TextField
                    color="blue"
                    // @ts-ignore
                    variant="outlined"
                    label={t("flightPasswordLabel")}
                    placeholder={t("flightPasswordPlaceholder")}
                    style={{ width: "100%" }}
                    defaultValue={password}
                    value={password}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                    }}
                    error={!password}
                    helperText={t("flightPasswordHelptext")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              )}
              <Box style={{ marginTop: shouldShowPasswordInput ? 14 : 0 }}>
                <Button
                  variant={isInQueue ? "red" : "blue"}
                  onClick={joinOrLeaveQueue}
                  disabled={
                    (!isInQueue && flightQueueLocked) ||
                    disableIfTrustedOnlyAndCurrentUserNotTrustAndNotInQueue ||
                    (shouldShowPasswordInput && !passwordValidated) ||
                    submitting
                  }
                >
                  {isInQueue
                    ? shouldShowCode
                      ? t("tripFinished")
                      : t("leaveQueue")
                    : disableIfTrustedOnlyAndCurrentUserNotTrustAndNotInQueue
                    ? t("trustedOnly")
                    : flightQueueLocked
                    ? t("queueLocked")
                    : t("joinQueue")}
                </Button>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box mt={2} fontSize={12}>
                {t("queueVisitorDesc1")}
              </Box>
              <Box fontSize={12}>{t("queueVisitorDesc2")}</Box>
            </Box>
          </Box>
        ))}
    </React.Fragment>
  );
}
