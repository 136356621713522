import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Card from "../../../shared/components/Card/Card";
import {
  Flight,
  TerminalType,
  TurnipsFlightType,
  SpecialCharacter,
  Profile,
} from "../../../types/data";
import { TERMINALS } from "../../../shared/constants";
import SpecialCharacterLabel from "../../../shared/components/SpecialCharacterLabel";
import FlightReport from "./FlightReport";
import DisplayNameLabel from "../../../shared/components/DisplayNameLabel/DisplayNameLabel";
import { useUserCatalog } from "../../../hooks/useUserCatalog";
import HorizontalList from "../../../shared/components/Catalog/HorizontalList";
import IslandNameLabel from "../../../shared/components/IslandNameLabel";
import format from "date-fns/format";

export interface FlightInfoCardProps {
  flight: Flight;
  flightCreatorProfile: Profile;
}

export default function FlightInfoCard({
  flight,
  flightCreatorProfile,
}: FlightInfoCardProps) {
  const { t } = useTranslation("Flight");
  const { catalog: catalogUser, catalogued, wishListed } = useUserCatalog(
    flight.hostId,
  );

  return (
    <Card variant="blue">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box color="info.main">
          <Icon className="fas fa-plane-arrival" fontSize="large" />
        </Box>
        <Box component="h2" color="info.main" textAlign="center">
          {t(TERMINALS[flight.terminal].displayName, { ns: "shared" })}
        </Box>
      </Box>
      <Box
        mt={4}
        display="flex"
        flexDirection="row"
        alignItems="center"
        fontSize={16}
        color="text.primary"
        width="100%"
      >
        <Box
          mr={2}
          fontSize={14}
          fontWeight="fontWeightMedium"
          width={90}
          flexShrink={0}
        >
          {t("regionLabel", { ns: "shared" })}
        </Box>
        <Box fontWeight="fontWeightBold">
          {flightCreatorProfile?.region
            ? t(flightCreatorProfile?.region, { ns: "shared" })
            : t("regionDefault", { ns: "shared" })}
        </Box>
      </Box>
      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        fontSize={16}
        color="text.primary"
        width="100%"
      >
        <Box
          mr={2}
          fontSize={14}
          fontWeight="fontWeightMedium"
          width={90}
          flexShrink={0}
        >
          {t("islandNameLabel", { ns: "shared" })}
        </Box>
        <Box fontWeight="fontWeightBold">
          <IslandNameLabel uid={flight.hostId} fontWeight="fontWeightBold" />
        </Box>
      </Box>
      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        fontSize={16}
        color="text.primary"
        width="100%"
      >
        <Box
          mr={2}
          fontSize={14}
          fontWeight="fontWeightMedium"
          width={90}
          flexShrink={0}
        >
          {t("hostNameLabel", { ns: "shared" })}
        </Box>
        <Box fontWeight="fontWeightBold">
          <DisplayNameLabel uid={flight.hostId} fontWeight="fontWeightBold" />
        </Box>
      </Box>
      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        fontSize={16}
        color="text.primary"
        width="100%"
      >
        <Box
          mr={2}
          fontSize={14}
          fontWeight="fontWeightMedium"
          width={90}
          flexShrink={0}
        >
          {t("planToCloseAtLabel", { ns: "shared" })}
        </Box>
        <Box fontWeight="fontWeightBold">
          {flight.planToCloseAt
            ? format(flight.planToCloseAt, "hh:mm a (eee, yyyy-MM-dd)")
            : t("planToCloseAtUnknown", { ns: "shared" })}
        </Box>
      </Box>
      {flightCreatorProfile.dreamAddress && <Box
        mt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        fontSize={16}
        color="text.primary"
        width="100%"
      >
        <Box
          mr={2}
          fontSize={14}
          fontWeight="fontWeightMedium"
          width={90}
          flexShrink={0}
        >
          {t("dreamAddressLabel", { ns: "shared" })}
        </Box>
        <Box fontWeight="fontWeightBold">
          {flightCreatorProfile.dreamAddress?.toUpperCase()}
        </Box>
      </Box>}
      {flight.terminal === TerminalType.TURNIPS && (
        <Box
          mt={2}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          fontSize={16}
          color="text.primary"
          width="100%"
        >
          {flight.turnipsFlightType === TurnipsFlightType.BUYING && (
            <Box
              mt={2}
              mb={-3.5}
              pl={1}
              zIndex={1}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              fontSize={16}
              color="text.primary"
              width="100%"
              fontWeight="fontWeightBold"
            >
              <SpecialCharacterLabel
                id={SpecialCharacter.DAISY_MAE}
                size={48}
                withoutName={true}
              />
              <Box>{t("daisyMaeIsSelling", { price: flight.price })}</Box>
            </Box>
          )}
          {flight.turnipsFlightType === TurnipsFlightType.SELLING && (
            <Box
              mt={2}
              mb={-3.5}
              pl={1}
              zIndex={1}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              fontSize={16}
              color="text.primary"
              width="100%"
              fontWeight="fontWeightBold"
            >
              <SpecialCharacterLabel
                id={SpecialCharacter.TIMMY_AND_TOMMY}
                width={84}
                height={48}
                withoutName={true}
              />
              <Box>{t("timmyAndTommyBuying", { price: flight.price })}</Box>
            </Box>
          )}
        </Box>
      )}
      {flight.terminal === TerminalType.SPECIAL_VISITORS &&
        flight.specialCharacter && (
          <Box
            mt={2}
            mb={-3.5}
            pl={1}
            zIndex={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            fontSize={16}
            color="text.primary"
            width="100%"
            fontWeight="fontWeightBold"
          >
            <SpecialCharacterLabel id={flight.specialCharacter} size={48} />
            <Box ml={0.5}>{t("specialCharacterIsVisiting")}</Box>
          </Box>
        )}
      <Box
        mt={2}
        p={2}
        width="100%"
        bgcolor="white"
        borderRadius={8}
        fontSize={14}
      >
        <Box
          style={{
            opacity: flight.message ? 1 : 0.6,
            whiteSpace: "break-spaces",
          }}
        >
          {flight.message || t("noMessage", { ns: "shared" })}
        </Box>
      </Box>

      {catalogUser && (
        <Box width="100%" mt={2}>
          {catalogued.length > 0 && (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<Icon className="fas fa-angle-down" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box>{t("catalogued")}</Box>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <HorizontalList data={catalogued} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
          {wishListed.length > 0 && (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<Icon className="fas fa-angle-down" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Box>{t("wishList")}</Box>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <HorizontalList data={wishListed} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </Box>
      )}
      <Box
        mt={4}
        component="small"
        textAlign="left"
        fontWeight="fontWeightMedium"
      >
        1. {t("sec2.maxAllowed", { count: flight.maxAllowed })}
        <br />
        2. {t("sec2.desc1")}
        <br />
        3. {t("sec2.desc2")}
      </Box>
      <FlightReport flightCreatorId={flight.hostId} />
    </Card>
  );
}
