import React from "react";
import firebase from "../shared/firebase";

export interface PriceListMetaData {
  count: number;
  lastSubmittedAt: Date;
}

export default function usePriceListMetaData() {
  const [meta, setMeta] = React.useState<PriceListMetaData | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("random")
      .doc("stuff")
      .onSnapshot(function (doc) {
        // console.log("on: price list meta data");
        // TODO: might just display "-"
        let lastSubmittedAt = new Date(doc.get("priceLastSubmittedAt"));
        if (isNaN(lastSubmittedAt.getTime())) {
          lastSubmittedAt = new Date();
        }
        setMeta({
          count: doc.get("priceCounter") || 0,
          lastSubmittedAt: lastSubmittedAt,
        });
      });

    return () => {
      // console.log("off: price list meta data");
      unsubscribe();
    };
  }, []);

  return meta;
}
