import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";

import { Avatar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import useProfileByShortId from "../../hooks/useProfileByShortId";
import Card from "../../shared/components/Card/Card";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import Section from "../../shared/components/Section/Section";
import PatreonBadge from "../../shared/components/PatreonBadge";
import {
  getColor,
  getFontWeight,
} from "../../shared/components/DisplayNameLabel/DisplayNameLabel";
import VerificationBadge from "../../shared/components/VerificationBadge";
import HorizontalList from "../../shared/components/Catalog/HorizontalList";
import { useUserCatalog } from "../../hooks/useUserCatalog";
import { useLongId } from "../../hooks/useLongId";

export default function PassportForm() {
  const { t } = useTranslation("Passport");
  const params = useParams<{ id: string }>();

  const shortId = params ? params.id : undefined;
  const profile = useProfileByShortId(shortId);
  const uid = useLongId(shortId);
  const { catalog: catalogUser, catalogued, wishListed } = useUserCatalog(
    uid ? uid : undefined,
  );

  if (!params.id || profile === null) {
    return <Redirect to="/" />;
  }

  if (profile === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="cyan" />
      </Box>
    );
  }

  const {
    displayName,
    islandName,
    nsId,
    profilePhoto,
    region,
    message,
    patreon,
    verificationLevel,
    dreamAddress,
  } = profile;

  const profileVerified = verificationLevel !== undefined;

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
      </Section>
      <Section variant="cyan">
        <Card variant="cyan">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box style={{ color: "rgba(0, 125, 117, 0.3)" }}>
              <Icon className="fas fa-passport" fontSize="large" />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box component="h2" style={{ color: "rgba(0, 125, 117, 1)" }}>
                {islandName || t("sec2.islandNameDefault")}
              </Box>
              <Box
                ml={0.5}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <VerificationBadge verified={profileVerified} />
              </Box>
            </Box>
          </Box>
          <Avatar
            src={profilePhoto}
            style={{ width: 60, height: 60, position: "relative" }}
          >
            NS
          </Avatar>
          {patreon && (
            <Box zIndex={1} mt={-1.5} ml={4}>
              <PatreonBadge tier={patreon} />
            </Box>
          )}
          {patreon && (
            <Box
              mt={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              fontSize={16}
              color={getColor(patreon)}
              fontWeight={getFontWeight(patreon)}
            >
              {t(`patreon.${patreon}`, { ns: "shared" })}
            </Box>
          )}
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontSize={16}
            color="text.primary"
            width="100%"
          >
            <Box mr={2} fontSize={14} fontWeight="fontWeightMedium" width={90}>
              {t("sec2.regionLabel")}
            </Box>
            <Box fontWeight="fontWeightBold">
              {region ? t(region, { ns: "shared" }) : "-"}
            </Box>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontSize={16}
            color="text.primary"
            width="100%"
          >
            <Box mr={2} fontSize={14} fontWeight="fontWeightMedium" width={90}>
              {t("sec2.displayNameLabel")}
            </Box>
            <Box fontWeight="fontWeightBold">
              {displayName || t("sec2.displayNameDefault")}
            </Box>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontSize={16}
            color="text.primary"
            width="100%"
          >
            <Box mr={2} fontSize={14} fontWeight="fontWeightMedium" width={90}>
              {t("sec2.dreamAddressLabel")}
            </Box>
            <Box fontWeight="fontWeightBold">
              {dreamAddress ? dreamAddress.toUpperCase() : t("sec2.dreamAddressDefault")}
            </Box>
          </Box>
          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontSize={16}
            color="text.primary"
            width="100%"
          >
            <Box mr={2} fontSize={14} fontWeight="fontWeightMedium" width={90}>
              {t("sec2.nsIdLabel")}
            </Box>
            <Box fontWeight="fontWeightBold">
              {nsId ? nsId.toUpperCase() : t("sec2.nsIdDefault")}
            </Box>
          </Box>
          <Box
            mt={2}
            p={2}
            width="100%"
            bgcolor="white"
            borderRadius={8}
            fontSize={14}
          >
            <Box
              style={{ opacity: message ? 1 : 0.6, whiteSpace: "break-spaces" }}
            >
              {message || t("noMessage", { ns: "shared" })}
            </Box>
          </Box>

          {catalogUser && (
            <Box width="100%" mt={2}>
              {catalogued.length > 0 && (
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<Icon className="fas fa-angle-down" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box>{t("catalogued", { ns: "shared" })}</Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <HorizontalList data={catalogued} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
              {wishListed.length > 0 && (
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<Icon className="fas fa-angle-down" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Box>{t("wishList", { ns: "shared" })}</Box>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <HorizontalList data={wishListed} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </Box>
          )}
        </Card>
      </Section>
    </Box>
  );
}
