import React from "react";
import { Redirect } from "react-router-dom";

import useProfile from "../../hooks/useProfile";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import badWordFilter from "../../shared/badWordFilter";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import UserContext from "../../shared/UserContext";
import Component from "./FlightUpdateForm.base";
import Box from "@material-ui/core/Box";
import { useFlightForm } from "./useFlightForm";
import { Region } from "../../types/all";
import { useLinkAppPromotionBanner, PROMOTION_TEXT_ID } from "../../hooks/useLinkAppPromotionBanner";

export default function FlightUpdateForm() {
  useLinkAppPromotionBanner(PROMOTION_TEXT_ID.HOST_IN_APP);

  const user = React.useContext(UserContext);
  const profile = useProfile(user ? user.uid : undefined);
  const profileVerified = profile?.verificationLevel !== undefined;
  const {
    form,
    error,
    helptext,
    updateField,
    submit,
    submitting,
    submitDisabled,
    isFlightCreated,
    isFlightOpen,
  } = useFlightForm(user?.uid, profile?.id, profileVerified);

  const updateProfile = useUpdateProfile();
  const [profileError, setProfileError] = React.useState<{
    displayName?: string;
    islandName?: string;
  }>({});

  const onChangeDisplayName = React.useCallback(
    (e: any) => {
      if (!user) return;
      updateProfile({
        user,
        displayName: badWordFilter.clean(String(e.target.value)),
      })
        .then(() => {
          setProfileError((prev) => ({
            ...prev,
            displayName: undefined,
          }));
        })
        .catch((error: Error) => {
          setProfileError((prev) => ({
            ...prev,
            displayName: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeIslandName = React.useCallback(
    (e: any) => {
      if (!user) return;
      updateProfile({
        user,
        islandName: badWordFilter.clean(String(e.target.value)),
      })
        .then(() => {
          setProfileError((prev) => ({
            ...prev,
            islandName: undefined,
          }));
        })
        .catch((error: Error) => {
          setProfileError((prev) => ({
            ...prev,
            islandName: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeRegion = React.useCallback(
    (e: any) => {
      updateProfile({
        user: user!,
        region: e.target.value as Region,
      });
    },
    [updateProfile, user],
  );

  if (user === null) {
    return (
      <Redirect
        to={`/sign-in?message=Please sign in before updating a flight!`}
      />
    );
  }

  if (user === undefined || form === undefined || profile === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="blue" />
      </Box>
    );
  }

  return (
    <Component
      profileVerified={profileVerified}
      form={form}
      error={error}
      helptext={helptext}
      updateField={updateField}
      submit={submit}
      submitting={submitting}
      submitDisabled={submitDisabled}
      isFlightCreated={isFlightCreated}
      isFlightOpen={isFlightOpen}
      profile={profile}
      onChangeDisplayName={onChangeDisplayName}
      onChangeIslandName={onChangeIslandName}
      onChangeRegion={onChangeRegion}
      profileError={profileError}
    />
  );
}
