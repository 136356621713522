import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "@material-ui/core";
import Box from "@material-ui/core/Box";

import { QueueApi } from "../../../../api";
import { useFlightPrivateAccessed } from "../../../../hooks/useFlightPrivateAccessed";
import DisplayNameLabel from "../../../../shared/components/DisplayNameLabel/DisplayNameLabel";
import { formatDistanceWithoutSuffixNow } from "../../../../shared/utils";
import { Passenger } from "../../../../types/data";
import { User } from "../../../../types/all";

export default function QueueListItem({
  passenger,
  index,
  user,
  isHost,
  flightCreatorId,
  shouldShowCode,
}: {
  passenger: Passenger;
  index: number;
  user: User;
  isHost: boolean;
  flightCreatorId: string;
  shouldShowCode: boolean;
}) {
  const { t } = useTranslation("Flight");
  const { enqueueSnackbar } = useSnackbar();
  const flightPrivateAccessed = useFlightPrivateAccessed(
    flightCreatorId,
    passenger.uid,
    isHost && shouldShowCode,
  );

  const [submitting, setSubmitting] = React.useState(false);

  const [rerenderKey, setRerenderKey] = React.useState(0);
  React.useEffect(() => {
    if (!isHost || !flightPrivateAccessed) return;

    const timer = setInterval(() => {
      setRerenderKey((prev) => prev + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [isHost, flightPrivateAccessed]);

  return (
    <Box
      key={passenger.uid}
      width="100%"
      py={0.5}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={shouldShowCode ? "rgba(241, 226, 111, 0.8)" : "inherit"}
      // TODO: highlight visitors that have been given code for a long time
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box mr={0.5}>{index + 1}.</Box>
        <DisplayNameLabel
          uid={passenger.uid}
          overwrite={user && user.uid === passenger.uid ? "You" : undefined}
          enableLink
        />{" "}
        <Box ml={0.5} key={rerenderKey}>
          (
          {isHost && flightPrivateAccessed
            ? `code shown for ${formatDistanceWithoutSuffixNow(
                flightPrivateAccessed.codeShownAt,
              )}`
            : formatDistanceWithoutSuffixNow(passenger.joinedAt)}
          )
        </Box>
      </Box>
      {isHost && (
        <Box
          color="error.main"
          lineHeight={0}
          onClick={() => {
            if (submitting) return;

            setSubmitting(true);
            QueueApi.removeFromQueue(passenger.uid)
              .then(() => {
                enqueueSnackbar(t("success.general"), {
                  variant: "success",
                });
                setSubmitting(false);
              })
              .catch((error) => {
                setSubmitting(false);
                enqueueSnackbar(
                  t("error.general", { message: error.message }),
                  {
                    variant: "error",
                  },
                );
              });
          }}
          style={{
            cursor: submitting ? "not-allowed" : "pointer",
            filter: submitting ? "grayscale(1)" : undefined,
          }}
        >
          <Icon className="fas fa-times" fontSize="small" />
        </Box>
      )}
    </Box>
  );
}
