import { FlightPassengerType, RequirePasswordOption } from "./../../types/all";
import React from "react";
import { TurnipsFlightType, Terminal, SpecialCharacter } from "../../types/all";
import { useTranslation } from "react-i18next";
import {
  getDateOrUndefined,
  isWithinHours,
  getInOneHour,
} from "../../shared/utils";
import useUserFlight from "../../hooks/useUserFlight";
import { FlightApi } from "../../apis/flight";
import { useSnackbar } from "notistack";

export enum FlightFormField {
  Code = "Code",
  FlightPassengerType = "FlightPassengerType",
  MaxAllowed = "MaxAllowed",
  MaxStayTime = "MaxStayTime",
  MaxQueueSize = "MaxQueueSize",
  Message = "Message",
  PlanToCloseAt = "PlanToCloseAt",
  Price = "Price",
  RequirePassword = "RequirePassword",
  SpecialCharacter = "SpecialCharacter",
  Terminal = "Terminal",
  TurnipsFlightType = "TurnipsFlightType",
}

export interface FlightForm {
  [FlightFormField.Code]?: string;
  [FlightFormField.FlightPassengerType]?: FlightPassengerType;
  [FlightFormField.MaxAllowed]?: number;
  [FlightFormField.MaxStayTime]?: number;
  [FlightFormField.MaxQueueSize]?: number;
  [FlightFormField.Message]?: string;
  [FlightFormField.PlanToCloseAt]?: number; // timestamp or UTC string
  [FlightFormField.Price]?: number;
  [FlightFormField.RequirePassword]?: boolean;
  [FlightFormField.SpecialCharacter]?: SpecialCharacter;
  [FlightFormField.Terminal]?: Terminal;
  [FlightFormField.TurnipsFlightType]?: TurnipsFlightType;
}

export interface FlightFormError {
  [FlightFormField.Code]?: string;
  [FlightFormField.FlightPassengerType]?: string;
  [FlightFormField.MaxAllowed]?: string;
  [FlightFormField.MaxStayTime]?: string;
  [FlightFormField.MaxQueueSize]?: string;
  [FlightFormField.Message]?: string;
  [FlightFormField.PlanToCloseAt]?: string;
  [FlightFormField.Price]?: string;
  [FlightFormField.RequirePassword]?: string;
  [FlightFormField.SpecialCharacter]?: string;
  [FlightFormField.Terminal]?: string;
  [FlightFormField.TurnipsFlightType]?: string;
}

export type FlightFormHelptext = {
  [k in FlightFormField]: string;
};

export type FlightFormUpdateField = (
  fieldName: FlightFormField,
  value: string | undefined,
  currentForm: FlightForm | undefined,
  initialised?: boolean,
) => void;

export type FlightFormSubmit = () => void;

export function useFlightForm(
  flightCreatorId: string | undefined,
  flightCreatorIdShort: string | undefined,
  flightCreatorVerified: boolean,
) {
  const { t } = useTranslation("FlightUpdateForm");
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = React.useState<undefined | FlightForm>(undefined);
  const [error, setError] = React.useState<FlightFormError>({});
  const [touched, setTouched] = React.useState<undefined | boolean>(undefined);
  const [submitting, setSubmitting] = React.useState(false);

  // Note: make sure both ids exist
  const flight = useUserFlight(
    flightCreatorId && flightCreatorIdShort ? flightCreatorId : undefined,
  );

  const isFlightCreated = !!flight;

  const updateFormForField = React.useCallback(
    <T extends FlightFormField>(fieldName: T, value: FlightForm[T]) => {
      setForm((prev) => {
        if (prev === undefined) {
          return {
            [fieldName]: value,
          };
        }

        return {
          ...prev,
          [fieldName]: value,
        };
      });
    },
    [],
  );

  const updateErrorForField = React.useCallback(
    (fieldName: FlightFormField, error: string | undefined) => {
      setError((prev) => ({
        ...prev,
        [fieldName]: error ? t(`error.${error}`) : undefined,
      }));
    },
    [t],
  );

  const updateField: FlightFormUpdateField = React.useCallback(
    (
      fieldName: FlightFormField,
      value: string | undefined,
      currentForm: FlightForm | undefined,
      /**
       * This is used for resetting related fields.
       * Because there is only one place where initialisation
       * happens, we just default it to `true` so we don't
       * need to pass in extra value for the `true` case.
       * This helps us to avoid depending on a initialisation
       * flag, which could potentially cause repetitive resets.
       */
      initialised: boolean = true,
    ) => {
      const optionalFields = [
        FlightFormField.FlightPassengerType,
        FlightFormField.Message,
        FlightFormField.RequirePassword,
      ];

      if (value === undefined) {
        if (!optionalFields.includes(fieldName)) {
          updateErrorForField(fieldName, "required");

          setTouched((prev) => (prev === false ? true : prev));
          return;
        }

        updateErrorForField(fieldName, undefined);
        updateFormForField(fieldName, undefined);

        setTouched((prev) => (prev === false ? true : prev));
        return;
      }

      switch (fieldName) {
        case FlightFormField.Code: {
          if (value.length !== 5) {
            updateErrorForField(fieldName, "dodoCodeLength");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, value.toUpperCase());
          break;
        }
        case FlightFormField.FlightPassengerType: {
          if (
            !Object.values(FlightPassengerType)
              .map((i) => String(i))
              .includes(value)
          ) {
            updateErrorForField(fieldName, "flightPassengerTypeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, value as FlightPassengerType);
          break;
        }
        case FlightFormField.MaxAllowed: {
          const n = parseInt(value);

          if (Number.isNaN(n)) {
            updateErrorForField(fieldName, "maxAllowedInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          if (n < 1 || n > 4) {
            updateErrorForField(fieldName, "maxAllowedOutOfRange");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, n);
          break;
        }
        case FlightFormField.MaxStayTime: {
          const n = parseInt(value);

          if (Number.isNaN(n)) {
            updateErrorForField(fieldName, "maxStayTimeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);

          updateFormForField(fieldName, n);
          break;
        }
        case FlightFormField.MaxQueueSize: {
          const n = parseInt(value);

          if (Number.isNaN(n)) {
            updateErrorForField(fieldName, "maxQueueSizeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);

          updateFormForField(fieldName, n);
          break;
        }
        case FlightFormField.Message: {
          if (value.length > 3600) {
            updateErrorForField(fieldName, "messageLength");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, value);
          break;
        }
        case FlightFormField.PlanToCloseAt: {
          const d = getDateOrUndefined(value);

          if (!d) {
            updateErrorForField(fieldName, "planToCloseAtInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          // max open time is 9 hours from now
          if (!isWithinHours(9)(d)) {
            updateErrorForField(fieldName, "planToCloseAtOutOfRange");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, d.getTime());
          break;
        }
        case FlightFormField.Price: {
          const n = parseInt(value);

          if (Number.isNaN(n)) {
            updateErrorForField(fieldName, "priceInvalid");
            updateFormForField(fieldName, 0);
            break;
          }

          if (
            currentForm?.TurnipsFlightType === TurnipsFlightType.BUYING &&
            (n < 90 || n > 110)
          ) {
            updateErrorForField(fieldName, "priceOutOfRangeBuying");
          } else if (n < 1 || n > 660) {
            updateErrorForField(fieldName, "priceOutOfRangeSelling");
          } else {
            updateErrorForField(fieldName, undefined);
          }

          updateFormForField(fieldName, n);
          break;
        }
        case FlightFormField.RequirePassword: {
          if (
            !Object.values(RequirePasswordOption)
              .map((i) => String(i))
              .includes(value)
          ) {
            updateErrorForField(fieldName, "requirePasswordInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, value === RequirePasswordOption.YES);
          break;
        }
        case FlightFormField.SpecialCharacter: {
          if (
            !Object.values(SpecialCharacter)
              .map((i) => String(i))
              .includes(value)
          ) {
            updateErrorForField(fieldName, "specialCharacterInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, value as SpecialCharacter);
          break;
        }
        case FlightFormField.Terminal: {
          if (
            !Object.values(Terminal)
              .map((i) => String(i))
              .includes(value)
          ) {
            updateErrorForField(fieldName, "terminalInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          if (initialised) {
            if (currentForm?.Terminal !== value && value === Terminal.TURNIPS) {
              updateField(
                FlightFormField.TurnipsFlightType,
                TurnipsFlightType.BUYING,
                undefined,
              );
            }

            if (
              currentForm?.Terminal !== value &&
              value === Terminal.SPECIAL_VISITORS
            ) {
              updateField(
                FlightFormField.SpecialCharacter,
                SpecialCharacter.CELESTE,
                undefined,
              );
            }
          }

          updateFormForField(fieldName, value as Terminal);
          break;
        }
        case FlightFormField.TurnipsFlightType: {
          if (
            !Object.values(TurnipsFlightType)
              .map((i) => String(i))
              .includes(value)
          ) {
            updateErrorForField(fieldName, "turnipsFlightTypeInvalid");
            updateFormForField(fieldName, undefined);
            break;
          }

          if (initialised) {
            if (
              currentForm?.TurnipsFlightType !== undefined &&
              currentForm?.TurnipsFlightType !== value
            ) {
              updateField(FlightFormField.Price, "", undefined);
            }
          }

          updateErrorForField(fieldName, undefined);
          updateFormForField(fieldName, value as TurnipsFlightType);
          break;
        }
      }

      setTouched((prev) => (prev === false ? true : prev));
      return;
    },
    [updateErrorForField, updateFormForField],
  );

  React.useEffect(() => {
    if (flight === undefined) return;
    if (flight === null) {
      updateField(FlightFormField.Code, undefined, undefined, false);
      updateField(
        FlightFormField.FlightPassengerType,
        FlightPassengerType.ALL,
        undefined,
        false,
      );
      updateField(FlightFormField.MaxAllowed, "2", undefined, false);
      updateField(FlightFormField.MaxStayTime, "0", undefined, false);
      updateField(FlightFormField.MaxQueueSize, "0", undefined, false);
      updateField(FlightFormField.Message, undefined, undefined, false);
      updateField(
        FlightFormField.PlanToCloseAt,
        getInOneHour().toUTCString(),
        undefined,
        false,
      );
      updateField(FlightFormField.Price, undefined, undefined, false);
      updateField(
        FlightFormField.RequirePassword,
        RequirePasswordOption.NO,
        undefined,
        false,
      );
      updateField(
        FlightFormField.SpecialCharacter,
        SpecialCharacter.CELESTE,
        undefined,
        false,
      );
      updateField(FlightFormField.Terminal, Terminal.TURNIPS, undefined, false);
      updateField(
        FlightFormField.TurnipsFlightType,
        TurnipsFlightType.SELLING,
        undefined,
        false,
      );
      setTouched(false);
      return;
    }

    updateField(FlightFormField.Code, flight.code, undefined, false);
    updateField(
      FlightFormField.FlightPassengerType,
      flight.flightPassengerType,
      undefined,
      false,
    );
    updateField(
      FlightFormField.MaxAllowed,
      String(flight.maxAllowed),
      undefined,
      false,
    );
    updateField(
      FlightFormField.MaxStayTime,
      String(flight.maxStayTime || 0),
      undefined,
      false,
    );
    updateField(
      FlightFormField.MaxQueueSize,
      String(flight.maxQueueSize || 0),
      undefined,
      false,
    );
    updateField(FlightFormField.Message, flight.message, undefined, false);
    // NOTE: PlanToCloseAt has to be a date string for updateField, but it's actual value should be timestamp
    updateField(
      FlightFormField.PlanToCloseAt,
      flight.planToCloseAt !== undefined &&
        getDateOrUndefined(flight.planToCloseAt)
        ? getDateOrUndefined(flight.planToCloseAt)?.toUTCString()
        : undefined,
      undefined,
      false,
    );
    updateField(FlightFormField.Price, String(flight.price), undefined, false);
    updateField(
      FlightFormField.RequirePassword,
      !!flight.requirePassword
        ? RequirePasswordOption.YES
        : RequirePasswordOption.NO,
      undefined,
      false,
    );
    updateField(
      FlightFormField.SpecialCharacter,
      flight.specialCharacter,
      undefined,
      false,
    );
    updateField(FlightFormField.Terminal, flight.terminal, undefined, false);
    updateField(
      FlightFormField.TurnipsFlightType,
      flight.turnipsFlightType,
      undefined,
      false,
    );
    setTouched(false);
  }, [flight, updateField]);

  const validated = React.useMemo(() => {
    const errorCopy = {
      ...error,
    };

    if (form?.Terminal === Terminal.TURNIPS) {
      delete errorCopy.SpecialCharacter;
    } else if (form?.Terminal === Terminal.SPECIAL_VISITORS) {
      delete errorCopy.Price;
      delete errorCopy.TurnipsFlightType;
    } else {
      delete errorCopy.SpecialCharacter;
      delete errorCopy.Price;
      delete errorCopy.TurnipsFlightType;
    }

    return Object.values(errorCopy).reduce(
      (acc, cur) => acc && cur === undefined,
      true,
    );
  }, [form, error]);

  const helptext: FlightFormHelptext = React.useMemo(
    () => ({
      [FlightFormField.Code]: error[FlightFormField.Code] || t("helptext.code"),
      [FlightFormField.FlightPassengerType]:
        error[FlightFormField.FlightPassengerType] ||
        t("helptext.flightPassengerType"),
      [FlightFormField.MaxAllowed]:
        error[FlightFormField.MaxAllowed] || t("helptext.maxAllowed"),
      [FlightFormField.MaxStayTime]:
        error[FlightFormField.MaxStayTime] || t("helptext.maxStayTime"),
      [FlightFormField.MaxQueueSize]:
        error[FlightFormField.MaxQueueSize] || t("helptext.maxQueueSize"),
      [FlightFormField.Message]:
        error[FlightFormField.Message] || t("helptext.message"),
      [FlightFormField.PlanToCloseAt]:
        error[FlightFormField.PlanToCloseAt] || t("helptext.planToCloseAt"),
      [FlightFormField.Price]:
        error[FlightFormField.Price] || t("helptext.price"),
      [FlightFormField.RequirePassword]:
        error[FlightFormField.RequirePassword] || t("helptext.requirePassword"),
      [FlightFormField.SpecialCharacter]:
        error[FlightFormField.SpecialCharacter] ||
        t("helptext.specialCharacter"),
      [FlightFormField.Terminal]:
        error[FlightFormField.Terminal] || t("helptext.terminal"),
      [FlightFormField.TurnipsFlightType]:
        error[FlightFormField.TurnipsFlightType] ||
        t("helptext.turnipsFlightType"),
    }),
    [error, t],
  );

  const submit = React.useCallback(() => {
    if (
      !form ||
      !flightCreatorIdShort ||
      !validated ||
      !touched ||
      flight === undefined
    )
      return;

    setSubmitting(true);

    FlightApi.update(
      {
        code: form.Code!,
        flightPassengerType: form.FlightPassengerType,
        maxAllowed: form.MaxAllowed!,
        maxStayTime: form.MaxStayTime || 0,
        maxQueueSize: form.MaxQueueSize || 0,
        message: form.Message,
        planToCloseAt: new Date(form.PlanToCloseAt!),
        price: form.Price,
        requirePassword: form.RequirePassword,
        specialCharacter: form.SpecialCharacter,
        terminal: form.Terminal!,
        turnipsFlightType: form.TurnipsFlightType,
      },
      flight,
      flightCreatorIdShort,
      flightCreatorVerified,
      !isFlightCreated,
    )
      .then(() => {
        enqueueSnackbar(
          t(isFlightCreated ? "success.update" : "success.create"),
          {
            variant: "success",
          },
        );
        setSubmitting(false);
      })
      .catch((error: Error) => {
        enqueueSnackbar(
          t(isFlightCreated ? "error.update" : "error.create", {
            msg: error.message,
          }),
          {
            variant: "error",
          },
        );
        setSubmitting(false);
      });
  }, [
    form,
    flightCreatorIdShort,
    validated,
    touched,
    flight,
    flightCreatorVerified,
    isFlightCreated,
    enqueueSnackbar,
    t,
  ]);

  const submitDisabled = submitting || !touched || !validated;

  return {
    form,
    error,
    helptext,
    validated,
    touched: !!touched,
    updateField,
    submit,
    submitting,
    submitDisabled,
    isFlightCreated,
    isFlightOpen: flight ? flight.status === "open" : false,
  };
}
