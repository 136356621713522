import React from "react";
import firebase from "../shared/firebase";
import {
  CatalogItemDatabaseExtended,
  CatalogItemDatabaseState,
} from "../types/data";
import { useCatalog } from "./useCatalog";

export function useUserCatalog(uid: string | undefined) {
  const [state, setState] = React.useState<
    CatalogItemDatabaseExtended[] | undefined
  >(undefined);

  const catalog = useCatalog();

  React.useEffect(() => {
    if (uid === undefined) return;

    const ref = firebase.database().ref("catalog").child(uid);
    const callback = ref.on("value", (snapshot) => {
      if (!snapshot.val()) {
        setState([]);
        return;
      }

      setState(
        (Object.entries(snapshot.val()) as [
          string,
          CatalogItemDatabaseExtended,
        ][]).map(([key, value]) => ({
          id: key,
          state: Object.values(CatalogItemDatabaseState).includes(value.state)
            ? value.state
            : CatalogItemDatabaseState.UNKNOWN,
        })),
      );
      return;
    });

    return () => {
      ref.off("value", callback);
    };
  }, [uid]);

  const catalogued = React.useMemo(() => {
    if (!catalog || !state) return [];
    return state
      .filter(
        (item) =>
          item.state === CatalogItemDatabaseState.CATALOGUED &&
          !!catalog[item.id],
      )
      .map((item) => {
        return {
          ...catalog[item.id],
          state: CatalogItemDatabaseState.CATALOGUED,
          id: item.id,
        };
      })
      .sort((a, b) => a.name > b.name ? 1 : -1);
  }, [catalog, state]);
  const wishListed = React.useMemo(() => {
    if (!catalog || !state) return [];
    return state
      .filter(
        (item) =>
          item.state === CatalogItemDatabaseState.WISH_LISTED &&
          !!catalog[item.id],
      )
      .map((item) => {
        return {
          ...catalog[item.id],
          state: CatalogItemDatabaseState.WISH_LISTED,
          id: item.id,
        };
      })
      .sort((a, b) => a.name > b.name ? 1 : -1);
  }, [catalog, state]);
  
  return {
    catalog: state,
    catalogued,
    wishListed,
  };
}
