import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";

import badWordFilter from "../../shared/badWordFilter";

import useUpdateProfile from "../../hooks/useUpdateProfile";
import Card from "../../shared/components/Card/Card";
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import Section from "../../shared/components/Section/Section";
import Select from "../../shared/components/Select/Select";
import TextField from "../../shared/components/TextField/TextField";
import UserContext from "../../shared/UserContext";
import { Region } from "../../types/data";
import useProfile from "../../hooks/useProfile";

export default function PassportForm() {
  const { t } = useTranslation("PassportForm");
  const user = React.useContext(UserContext);
  const profile = useProfile(user ? user.uid : undefined);
  const updateProfile = useUpdateProfile();
  const [error, setError] = React.useState<{
    displayName?: string;
    islandName?: string;
    nsId?: string;
    profilePhoto?: string;
    region?: Region;
    message?: string;
    dreamAddress?: string;
  }>({});

  const onChangeDisplayName = React.useMemo(
    () => (e: any) => {
      if (!user) return;
      updateProfile({
        user,
        displayName: badWordFilter.clean(String(e.target.value)),
      })
        .then(() => {
          setError((prev) => ({
            ...prev,
            displayName: undefined,
          }));
        })
        .catch((error: Error) => {
          setError((prev) => ({
            ...prev,
            displayName: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeProfilePhoto = React.useMemo(
    () => (e: any) => {
      if (!user) return;
      updateProfile({ user, profilePhoto: String(e.target.value) })
        .then(() => {
          setError((prev) => ({
            ...prev,
            profilePhoto: undefined,
          }));
        })
        .catch((error: Error) => {
          setError((prev) => ({
            ...prev,
            profilePhoto: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeIslandName = React.useMemo(
    () => (e: any) => {
      if (!user) return;
      updateProfile({
        user,
        islandName: badWordFilter.clean(String(e.target.value)),
      })
        .then(() => {
          setError((prev) => ({
            ...prev,
            islandName: undefined,
          }));
        })
        .catch((error: Error) => {
          setError((prev) => ({
            ...prev,
            islandName: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeNsId = React.useMemo(
    () => (e: any) => {
      if (!user) return;
      updateProfile({ user, nsId: String(e.target.value) })
        .then(() => {
          setError((prev) => ({
            ...prev,
            nsId: undefined,
          }));
        })
        .catch((error: Error) => {
          setError((prev) => ({
            ...prev,
            nsId: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeDreamAddress = React.useMemo(
    () => (e: any) => {
      if (!user) return;
      updateProfile({ user, dreamAddress: String(e.target.value) })
        .then(() => {
          setError((prev) => ({
            ...prev,
            dreamAddress: undefined,
          }));
        })
        .catch((error: Error) => {
          setError((prev) => ({
            ...prev,
            dreamAddress: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  const onChangeMessage = React.useMemo(
    () => (e: any) => {
      if (!user) return;
      updateProfile({ user, message: String(e.target.value) })
        .then(() => {
          setError((prev) => ({
            ...prev,
            message: undefined,
          }));
        })
        .catch((error: Error) => {
          setError((prev) => ({
            ...prev,
            message: error.message,
          }));
        });
    },
    [updateProfile, user],
  );

  if (user === null) {
    return (
      <Redirect to="/sign-in?message=Please%20sign%20in%20before%20viewing%20your%20passport%21&returnUrl=%2Fpassport" />
    );
  }

  if (user === undefined || profile === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        my={3}
      >
        <CircularProgress variant="cyan" />
      </Box>
    );
  }

  const {
    id,
    displayName,
    islandName,
    nsId,
    profilePhoto,
    region,
    message,
    verificationLevel,
    dreamAddress,
  } = profile;

  const profileVerified = verificationLevel !== undefined;

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>
      <Section variant="brown" spacing={false} shrink>
        <Box component="h2" textAlign="center">
          {t("sec1.title")}
        </Box>
      </Section>
      <Section variant="cyan">
        <Card variant="cyan">
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box style={{ color: "rgba(0, 125, 117, 0.3)" }}>
              <Icon className="fas fa-passport" fontSize="large" />
            </Box>
            <Box component="h2" style={{ color: "rgba(0, 125, 117, 1)" }}>
              {t("sec2.title")}
            </Box>
            <Box textAlign="center">
              {t("sec2.desc1")}
              <br />
              {t("sec2.desc2")}
            </Box>
            <Box
              mt={2}
              fontSize={12}
              textAlign="center"
              color="text.primay"
              style={{ opacity: 0.8 }}
            >
              {t("sec2.email", { email: user.email })}
            </Box>
            {profileVerified && (
              <Box
                mt={2}
                fontSize={12}
                textAlign="center"
                color="text.primay"
                style={{ opacity: 0.8 }}
              >
                {t("sec2.isVerified")}
              </Box>
            )}
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="cyan"
              // @ts-ignore
              variant="outlined"
              label={t("sec2.displayNameLabel")}
              placeholder={t("sec2.displayNamePlaceholder")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={displayName}
              onChange={onChangeDisplayName}
              InputLabelProps={{ shrink: true }}
              disabled={profileVerified}
              error={!!error.displayName}
              helperText={
                error.displayName ||
                (profileVerified ? t("sec2.displayNameVerified") : undefined)
              }
            />
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="cyan"
              // @ts-ignore
              variant="outlined"
              label={t("sec2.profilePhotoLabel")}
              placeholder={t("sec2.profilePhotoPlaceholder")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={profilePhoto}
              onChange={onChangeProfilePhoto}
              InputLabelProps={{ shrink: true }}
              error={!!error.profilePhoto}
              helperText={error.profilePhoto}
            />
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="cyan"
              // @ts-ignore
              variant="outlined"
              label={t("sec2.islandNameLabel")}
              placeholder={t("sec2.islandNamePlaceholder")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={islandName}
              onChange={onChangeIslandName}
              InputLabelProps={{ shrink: true }}
              disabled={profileVerified}
              error={!!error.islandName}
              helperText={
                error.islandName ||
                (profileVerified ? t("sec2.islandNameVerified") : undefined)
              }
            />
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="cyan"
              // @ts-ignore
              variant="outlined"
              label={t("sec2.nsIdLabel")}
              placeholder={t("sec2.nsIdPlaceholder")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={nsId}
              onChange={onChangeNsId}
              InputLabelProps={{ shrink: true }}
              error={!!error.nsId}
              helperText={error.nsId}
            />
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="cyan"
              // @ts-ignore
              variant="outlined"
              label={t("sec2.dreamAddressLabel")}
              placeholder={t("sec2.dreamAddressPlaceholder")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={dreamAddress}
              onChange={onChangeDreamAddress}
              InputLabelProps={{ shrink: true }}
              error={!!error.dreamAddress}
              helperText={error.dreamAddress}
            />
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Select
              color="mint"
              // @ts-ignore
              variant="outlined"
              label={t("sec2.regionLabel")}
              placeholder={t("sec2.regionPlaceholder")}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={region}
              onChange={(e: any) => {
                updateProfile({
                  user,
                  region: e.target.value as Region,
                });
              }}
            >
              {Object.values(Region).map((value) => (
                <MenuItem value={value} key={value}>
                  {t(value, { ns: "shared" })}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              color="mint"
              // @ts-ignore
              variant="outlined"
              multiline
              rows={3}
              rowsMax={5}
              label={t("messageLabel", { ns: "shared" })}
              placeholder={t("messagePlaceholder", { ns: "shared" })}
              style={{ width: "100%", maxWidth: 300 }}
              defaultValue={message}
              onChange={onChangeMessage}
              InputLabelProps={{ shrink: true }}
              error={!!error.message}
              helperText={error.message || t("sec2.messageHelpText")}
            />
          </Box>
          <Box
            mt={4}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component={Link}
              // @ts-ignore
              to={`/passport/${id}`}
              display="flex"
              flexDirection="row"
              alignItems="center"
              color="text.primary"
              borderColor="text.primary"
              borderBottom="2px solid"
              fontWeight="700"
              fontSize={16}
              style={{ textDecoration: "none", color: "rgba(0, 125, 117, 1)" }}
            >
              {t("sec2.viewPassport")}
            </Box>
          </Box>
        </Card>
      </Section>
    </Box>
  );
}
