import React from "react";
import useProfile from "./../../hooks/useProfile";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { PatreonTier } from "./../../types/data";
import { useHistory } from "react-router-dom";
import VerificationBadge from "./VerificationBadge";
import {
  getPrefixBadge,
  getSuffixBadge,
} from "./DisplayNameLabel/DisplayNameLabel";

export interface IslandNameLabelProps {
  uid?: string;
  overwrite?: string;
  fallback?: string;
  color?: string;
  fontWeight?: string;
  enableLink?: boolean;
  showPatreon?: boolean;
}

export function getColor(tier: PatreonTier | undefined, overwrite?: string) {
  if (overwrite) {
    return overwrite;
  }
  if (tier === undefined) {
    return "#6b5c43";
  }

  switch (tier) {
    case PatreonTier.SUPPORTER:
      return "#6b5c43";
    case PatreonTier.RESIDENT:
    case PatreonTier.RESIDENT_PLUS:
    case PatreonTier.STREAMER:
      return "#4b9fd8";
    case PatreonTier.CITIZEN:
    case PatreonTier.STREAMER_PLUS:
      return "#3789c9";
    case PatreonTier.COUNCILOR:
      return "#F9B444";
    case PatreonTier.INVESTOR:
    case PatreonTier.INVESTOR_PLUS:
      return "#e95454";
  }
}

export function getFontWeight(
  tier: PatreonTier | undefined,
  overwrite?: string,
) {
  if (overwrite) {
    return overwrite;
  }

  if (tier === undefined) {
    return "fontWeightRegular";
  }

  return "fontWeightBold";
}

export default function IslandNameLabel({
  uid,
  overwrite,
  fallback,
  color,
  fontWeight,
  enableLink = false,
  showPatreon = true,
}: IslandNameLabelProps) {
  const profile = useProfile(uid);
  const { t } = useTranslation("IslandNameLabel");
  const history = useHistory();

  const verified = profile?.verificationLevel !== undefined;
  const prefixBadge = showPatreon && getPrefixBadge(profile);
  const suffixBadge = showPatreon && getSuffixBadge(profile);

  return (
    <Box
      display="inline-block"
      onClick={
        enableLink
          ? () => {
              if (profile?.id) {
                history.push(`/passport/${profile?.id}`);
              }
            }
          : undefined
      }
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        {prefixBadge}
        <Box
          mr={0.5}
          color={getColor(profile?.patreon, color)}
          fontWeight={getFontWeight(profile?.patreon, fontWeight)}
          style={{ filter: verified ? undefined : "grayscale(1)" }}
        >
          {overwrite ||
            profile?.islandName ||
            fallback ||
            t("islandNameUnknown", { ns: "shared" })}
        </Box>
        {suffixBadge}
        {verified && <VerificationBadge verified={verified} />}
      </Box>
    </Box>
  );
}
