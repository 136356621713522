import React from "react";
import { useStyles } from "./Ribbon.styles";
import Box from "@material-ui/core/Box";
import { RibbonProps } from "./Ribbon.types";

const Ribbon: React.FC<RibbonProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.container}>
      <Box component="h3" className={classes.labelContainer}>
        {props.children}
      </Box>
    </Box>
  );
};

export default Ribbon;
