import React from "react";

export function useDialog() {
  const [state, setState] = React.useState(false);

  const open = React.useCallback(() => {
    setState(true);
  }, [setState]);

  const close = React.useCallback(() => {
    setState(false);
  }, []);

  return {
    isOpen: state,
    open,
    close,
  };
}
