import React from "react";

export default function Promotion() {
  return (
    <iframe
      title="patreon"
      width="100%"
      height={144}
      frameBorder="0"
      src="/promotion.html"
    />
  );
}
