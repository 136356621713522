import React from "react";
import { Language } from "../types/data";

export const LOCAL_STORAGE_LANGUAGE_KEY = "LOCAL_STORAGE_LANGUAGE_KEY";

export default function useLanguage(): [
  Language,
  (newValue: Language) => void,
] {
  const storedLanguage = window.localStorage.getItem(
    LOCAL_STORAGE_LANGUAGE_KEY,
  );

  const updateLanguage = React.useCallback((newValue: Language) => {
    window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, newValue);
    setTimeout(() => {
      window.location.reload();
    });
  }, []);

  return [
    storedLanguage ? (storedLanguage as Language) : Language.ENGLISH,
    updateLanguage,
  ];
}
