import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";

import Box from "@material-ui/core/Box";

import { QueueApi } from "../../../../apis/queue";
import { FlightApi } from "../../../../apis/flight";
import Button from "../../../../shared/components/Button/Button";
import Loader from "../../../../shared/components/Loader";
import { User } from "../../../../types/all";
import { ProfileForm } from "../../../../types/data";
import { Passenger, PatreonTier } from "../../../../types/all";
import QueueListItem from "./QueueListItem";
import useProfile from "../../../../hooks/useProfile";

function getIsFirstClassUser(profile: ProfileForm | undefined) {
  if (!profile) return false;

  return ([
    PatreonTier.SUPPORTER,
    PatreonTier.RESIDENT,
    PatreonTier.RESIDENT_PLUS,
    PatreonTier.CITIZEN,
    PatreonTier.COUNCILOR,
    PatreonTier.INVESTOR,
    PatreonTier.INVESTOR_PLUS,
    PatreonTier.STREAMER,
    PatreonTier.STREAMER_PLUS,
  ] as string[]).includes(profile.patreon || "");
}

export interface FirstClassTabProps {
  queue: Passenger[] | undefined;
  flightClosed: boolean;
  flightCreatorId: string;
  flightMaxAllowed: number;
  flightQueueLocked: boolean;
  flightRequirePassword: boolean;
  isInQueue: boolean;
  shouldShowCode: boolean;
  isHost: boolean;
  user: User;
  openNonSignedInUserDialog: () => void;
  openFirstClassRuleDialog: () => void;
}

export default function FirstClassTab({
  queue,
  flightClosed,
  flightCreatorId,
  flightMaxAllowed,
  flightQueueLocked,
  flightRequirePassword,
  isInQueue,
  shouldShowCode,
  isHost,
  user,
  openNonSignedInUserDialog,
  openFirstClassRuleDialog,
}: FirstClassTabProps) {
  const { t } = useTranslation("FlightQueueCard");
  const { enqueueSnackbar } = useSnackbar();

  const currentUserProfile = useProfile(user ? user.uid : undefined);
  const isFirstClassUser = getIsFirstClassUser(currentUserProfile);

  const [submitting, setSubmitting] = React.useState(false);

  const joinOrLeaveQueue = () => {
    if (!user) {
      openNonSignedInUserDialog();
      return;
    }

    setSubmitting(true);

    if (isInQueue) {
      QueueApi[shouldShowCode ? "tripFinished" : "leaveQueue"](flightCreatorId)
        .then(() => {
          enqueueSnackbar(t("success.general", { ns: "shared" }), {
            variant: "success",
          });
          setSubmitting(false);
        })
        .catch((error: Error) => {
          setSubmitting(false);
          enqueueSnackbar(
            t("error.general", { ns: "shared", error: error.message }),
            {
              variant: "error",
            },
          );
        });
    } else {
      QueueApi.joinQueueFirstClass(flightCreatorId)
        .then(() => {
          enqueueSnackbar(t("success.general", { ns: "shared" }), {
            variant: "success",
          });
          setSubmitting(false);
        })
        .catch((error: Error) => {
          console.log(error.message);
          setSubmitting(false);
          enqueueSnackbar(
            t("error.general", { ns: "shared", error: error.message }),
            {
              variant: "error",
            },
          );
        });
    }
  };

  const lockOrUnlockQueue = () => {
    setSubmitting(true);
    FlightApi[flightQueueLocked ? "unlock" : "lock"]()
      .then(() => {
        enqueueSnackbar(t("success.general", { ns: "shared" }), {
          variant: "success",
        });
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        enqueueSnackbar(
          t("error.general", { ns: "shared", error: error.message }),
          {
            variant: "error",
          },
        );
      });
  };

  const renderItem = React.useCallback(
    (index: number) => {
      if (queue === undefined) return <React.Fragment></React.Fragment>;

      const passenger = queue[index];
      return (
        <QueueListItem
          passenger={passenger}
          index={index}
          isHost={isHost}
          user={user}
          flightCreatorId={flightCreatorId}
          shouldShowCode={index === 0}
        />
      );
    },
    [flightCreatorId, isHost, queue, user],
  );

  if (queue === undefined) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      {queue.length === 0 && (
        <Box
          width="100%"
          py={2}
          textAlign="center"
          color="text.primary"
          style={{ opacity: 0.6 }}
        >
          {t("queueEmpty")}
        </Box>
      )}
      {queue.length > 0 && (
        <Virtuoso
          style={{ width: "100%", minHeight: 64, maxHeight: 176 }}
          totalCount={queue.length}
          item={renderItem}
          overscan={3}
        />
      )}
      {!flightClosed &&
        (isHost ? (
          <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Button
              variant="red"
              onClick={lockOrUnlockQueue}
              disabled={submitting}
            >
              {flightQueueLocked ? t("unlockQueue") : t("lockQueue")}
            </Button>
            <Box mt={2} fontSize={12}>
              {t("queueHostDesc1FirstClass")}
            </Box>
            <Box fontSize={12}>{t("queueHostDesc2FirstClass")}</Box>
            <Box fontSize={12}>{t("queueHostDesc3FirstClass")}</Box>
          </Box>
        ) : (
          <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Button
              variant={isInQueue ? "red" : "blue"}
              onClick={joinOrLeaveQueue}
              disabled={
                (!isInQueue && flightQueueLocked) ||
                submitting ||
                (!isInQueue && !isFirstClassUser) ||
                (!isInQueue && flightRequirePassword)
              }
            >
              {isInQueue
                ? shouldShowCode
                  ? t("tripFinished")
                  : t("leaveQueue")
                : flightRequirePassword
                ? t("firstClassNotAvailableForPrivateFlight")
                : isFirstClassUser
                ? flightQueueLocked
                  ? t("queueLocked")
                  : t("joinQueueFirstClass")
                : t("notFirstClassUser")}
            </Button>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box mt={2} fontSize={12}>
                {t("queueVisitorDesc1FirstClass")}
              </Box>
              <Box fontSize={12}>
                *{" "}
                <Box
                  onClick={openFirstClassRuleDialog}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    display: "inline-flex",
                  }}
                >
                  {t("knowMoreAboutFirstClass")}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
    </React.Fragment>
  );
}
