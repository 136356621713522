import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

export interface HostRulesProps {
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
}

export default function HostRules({
  isOpen,
  close,
  onConfirm,
}: HostRulesProps) {
  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>Please read!</DialogTitle>
      <DialogContent>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary
            expandIcon={<Icon className="fas fa-angle-down" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box>Community Standards for Hosts</Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box display="flex" flexDirection="column">
              <Box>
                To build a trusting and friendly community together, please read
                and follow the standards below:
              </Box>
              <Box>
                <br />
                1. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST
                </Box>{" "}
                follow Nintendo Terms of Service. Prohibited behaviors including
                but not limited to: (1) asking for real money in any currency in
                exchange for in-game items (e.g. redirecting to another site
                where a monetary transaction is asked), and (2) hacking Switches
                for in-game items (e.g. inflated turnip sale prices).
                <br />
                <br />
                2. Your Flight{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST
                </Box>{" "}
                contain only accurate information and{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST
                </Box>{" "}
                be opened in the correct Terminal.
                <br />
                <br />
                3. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST
                </Box>{" "}
                deliver all you promised in your Flight message.
                <br />
                <br />
                4. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  SHOULD
                </Box>{" "}
                communicate with your visitors in the Chat section on your
                Flight about any changes you will be making. Changing
                information and ending flights without notice are not
                recommended.
                <br />
                <br />
                5. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  CAN
                </Box>{" "}
                ask for entry fees (in-game items only). We recommend low entry
                fees but you are free to make your own rules. (Please report to
                @TurnipsExchange on Twitter or hi@turnips.exchange if any
                visitor doesn't pay the required fee.)
                <br />
                <br />
                6. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  CAN
                </Box>{" "}
                have friends visiting your island while you are hosting, but you{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  SHOULD
                </Box>{" "}
                keep the number low so the experience for our visitors stay
                smooth.
                <br />
                <br />
                <br />
                If your flight is reported or found violating our standards,
                depending on the seriousness of the violation, you might (1)
                receive a warning via email; (2) be temporarily banned; or (3)
                permanantly banned.
                <br />
                <br />
                We reserve all rights to disable your account for any reason we
                believe violate our community guidelines or standards.
                <br />
                <br />
                You can appeal for your case if you feel your account was
                terminated or banned unfairly or falsely by DMing us on Twitter
                or sending us an email.
              </Box>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded={false}>
          <ExpansionPanelSummary
            expandIcon={<Icon className="fas fa-angle-down" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box>Additional Rules for Streamers</Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Box display="flex" flexDirection="column">
              <Box>
                Welcome to the community. We believe your streaming will
                certainly bring so much more fun beyond the game play. In order
                to create a better experience for you and all your visitors,
                please read and follow the rules below:
              </Box>
              <Box>
                <br />
                1. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST
                </Box>{" "}
                follow the Community Standards for Hosts above.
                <br />
                <br />
                2. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST NOT
                </Box>{" "}
                require any subscription to your channels in order to join
                Flight.
                <br />
                <br />
                3. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  MUST
                </Box>{" "}
                end your Flight if the situation on your island has changed.
                (E.g. if you are doing Time Traveling, you should not leave your
                Flight open and have visitors waiting while you are searching
                for a new price.)
                <br />
                <br />
                4. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  CAN
                </Box>{" "}
                ask visitors to follow you on your streaming platform.
                <br />
                <br />
                5. You{" "}
                <Box component="span" fontWeight="fontWeightBold">
                  CAN
                </Box>{" "}
                stream non-ACNH content.
                <br />
                <br />
                There are no other requirements for you to host your Flights on
                this Platform. But we are working our best to bring your hosting
                experience here to a higher level with some of our Streamer Only
                features.
                <br />
                <br />
                <Box component="span" fontWeight="fontWeightBold">
                  Featured Stream Board:
                </Box>
                <br />
                The Featured Stream Board on our home page will be showing all
                featured streamers and streaming events.
                <br />
                <ul>
                  <li>
                    Featured Streamer: we will select 1 or more streamers on the
                    platform as our Featured Streamers of the week/month
                    according to their contribution to the community. You can
                    also get a featuring slot by supporting us on Patreon.
                  </li>
                  <li>
                    Featured Event: special streaming events, e.g. for charity,
                    will have chances to be featured on the board as well.
                    Please contact us if you are planning to host one.
                  </li>
                </ul>
                <br />
                <br />
                <Box component="span" fontWeight="fontWeightBold">
                  Streamer Badge:
                </Box>
                <br />
                If you are one of our Featured Streamers or have subscribed to
                one of our streamer Patreon tiers, you will have a LIVE Stream
                Badge at the beginning of your name or island name in the
                Airport listing.
                <br />
                <br />
                <Box component="span" fontWeight="fontWeightBold">
                  Live Stream Section:
                </Box>
                <br />
                If you are one of our Featured Streamers or have subscribed to
                one of our streamer Patreon tiers, a Live Stream section will be
                shown on your Flight page. All visitors will be able to view
                your stream live directly on the Flight page.
                <br />
                <br />
                <Box component="span" fontWeight="fontWeightBold">
                  Unique Custom Link:
                </Box>
                <br />
                If you are one of our Featured Streamers or have subscribed to
                one of our streamer Patreon tiers, you will be able to set a
                unique custom link to your Flight page, e.g.
                https://turnips.exchange/flight/YOUR_STREAM_ID.
                <br />
                <br />
                Please join the discord to stay up to date on streamer
                guidelines and feel free to contact us if you have any questions
                or suggestions. This is the most effective ways to get responses
                and submit feedback.
                <br />
                <br />
                If you are interested in supporting us and becoming an Affiliate
                Streamer ($10) or Partnered Streamer ($50), please check out our
                <a
                  aria-label="wow"
                  href="http://patreon.com/turnips"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Patreon Page
                </a>{" "}
                for more details.
              </Box>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onConfirm();
            close();
          }}
          color="secondary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
