import { useTranslation } from "react-i18next";
import React from "react";

import firebase from "../shared/firebase";
import { Region, ProfileForm, PatreonTier } from "../types/data";

export default function useProfileByShortId(uid: string | undefined) {
  const [data, setData] = React.useState<ProfileForm | null | undefined>(
    undefined,
  );
  const { t } = useTranslation("shared");

  React.useEffect(() => {
    if (!uid) return;

    firebase
      .firestore()
      .collection("users")
      .where("id", "==", uid)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setData(null);
          return;
        }
        const user = snapshot.docs[0];
        setData({
          displayName:
            user.get("displayNameVerified") ||
            user.get("displayName") ||
            undefined,
          profilePhoto: user.get("profilePhoto") || undefined,
          islandName:
            user.get("islandNameVerified") ||
            user.get("islandName") ||
            undefined,
          nsId: user.get("nsId") || undefined,
          region: Object.values(Region).includes(user.get("region"))
            ? (user.get("region") as Region)
            : undefined,
          message: user.get("message") || undefined,
          patreon: Object.values(PatreonTier).includes(user.get("patreon"))
            ? (user.get("patreon") as PatreonTier)
            : undefined,
          verificationLevel: user.get("verificationLevel") || undefined,
          mixer: user.get("mixer") || undefined,
          twitch: user.get("twitch") || undefined,
          dreamAddress: user.get("dreamAddress") || undefined
        });
      });
  }, [uid, setData, t]);

  return data;
}
