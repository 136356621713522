import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";

import useFlightQueue from "../../../hooks/useFlightQueue";
import useProfileWithDefaultValue from "../../../hooks/useProfileWithDefaultValue";
import { useToggle } from "../../../hooks/useToggle";
import IslandNameLabel from "../../../shared/components/IslandNameLabel";
import { isWithinMinutes } from "../../../shared/utils";
import { FlightPassengerType } from "../../../types/all";
import { Flight, TurnipsFlightType } from "../../../types/data";

export interface DepartureListTurnipsItemProps {
  data: Flight;
  index: number;
}

export default function DepartureListTurnipsItem({
  data,
  index,
}: DepartureListTurnipsItemProps) {
  const { t } = useTranslation("DepartureListTurnipsItem");
  const { hostId, price, message } = data;
  const hostProfile = useProfileWithDefaultValue(hostId);
  const queue = useFlightQueue(hostId);
  const messageToggle = useToggle();

  return (
    <Box display="flex" flexDirection="column" width="100">
      <Box
        key={index}
        component={ListItem}
        style={{
          flexDirection: "column",
          width: "100%",
          backgroundColor: index % 2 ? undefined : "rgba(104,161,215,0.2)",
          cursor: "pointer",
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            <IslandNameLabel uid={hostId} />
          </Box>
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            {price || t("priceUnknown")}
          </Box>
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box position="relative">
              <Box>{queue === undefined ? "-" : queue.length}</Box>
              {(data.queueLocked || data.requirePassword) && (
                <Box
                  position="absolute"
                  top={6}
                  left="calc(100% + 4px)"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {data.queueLocked && (
                    <Icon
                      className="fas fa-lock"
                      style={{
                        color: "inherit",
                        marginRight: 4,
                        fontSize: 8,
                        opacity: 0.6,
                      }}
                    />
                  )}
                  {data.requirePassword && (
                    <Icon
                      className="fas fa-user-lock"
                      style={{
                        color: "inherit",
                        marginRight: 4,
                        fontSize: 8,
                        opacity: 0.6,
                      }}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            flexGrow={1}
            flexShrink={0}
            flexBasis="25%"
            textAlign="center"
            style={{ overflowY: "auto" }}
          >
            <Box
              component={Link}
              // @ts-ignore
              to={`/flight/${hostProfile.id}`}
              color="text.primary"
            >
              {t(
                data.turnipsFlightType === TurnipsFlightType.BUYING
                  ? "buy"
                  : "sell",
              )}
            </Box>
          </Box>
        </Box>
        <Box
          position="absolute"
          p={0.5}
          right={0}
          top={0}
          bottom={0}
          role="button"
          onClick={messageToggle[1]}
          color="info.main"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          fontSize={14}
        >
          <Icon
            className={`fas fa-${messageToggle[0] ? "caret-up" : "caret-down"}`}
            fontSize="inherit"
          />
        </Box>
        {data.flightPassengerType === FlightPassengerType.TRUSTED && (
          <Box
            mt={0.5}
            width="100%"
            maxHeight={12}
            style={{ overflowY: "auto" }}
            color="text.primary"
            fontSize={8}
          >
            {t("verifiedOnlyFlight", { ns: "Airport" })}
          </Box>
        )}
        {data.planToCloseAt && isWithinMinutes(30)(data.planToCloseAt) && (
          <Box
            mt={0.5}
            width="100%"
            maxHeight={12}
            style={{ overflowY: "auto" }}
            color="text.primary"
            fontSize={8}
          >
            {t("flightEndingSoon", { ns: "Airport", minutes: 30 })}
          </Box>
        )}
      </Box>

      {messageToggle[0] && (
        <Box
          py={1}
          px={2}
          width="100%"
          maxHeight={48}
          style={{ overflowY: "auto" }}
          color="text.primary"
          bgcolor="rgba(255,255,255,.6)"
          fontSize={12}
        >
          <Box style={{ opacity: message ? 1 : 0.6 }}>
            {message || t("noMessage", { ns: "shared" })}
          </Box>
        </Box>
      )}
    </Box>
  );
}
